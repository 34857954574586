import { PropsWithChildren } from 'react'

import styled from '@/styles'

import Stack, { Variants } from '@/components/Stack'

const StyledStack = styled(Stack, {
  '& *': {
    lineHeight: 1.25,
  },
})

const GAP_MAP = {
  normal: 4,
  tight: 2,
  loose: 6,
  'extra-loose': 8,
}

type Props = Pick<Variants, 'align' | 'justify' | 'inline'> &
  PropsWithChildren<{
    spacing?: keyof typeof GAP_MAP
  }>

const TextStack = ({ spacing = 'normal', ...props }: Props) => {
  return <StyledStack {...props} vertical gap={GAP_MAP[spacing]} />
}

export default TextStack
