import Action from './Action'
import Body, { Props as BodyProps } from './Body'
import Display from './Display'
import Page, { Props as PageProps } from './Page'
import TagList, { Props as TagListProps } from './TagList'
import CommentsList, { Props as CommentsListProps} from './Comments'

export type { BodyProps, PageProps, TagListProps, CommentsListProps }

export default { Action, Body, Display, Page, TagList, CommentsList }
