import { useCallback } from 'react'
import { useModal } from 'react-modal-hook'

import { GetCustomerQuery } from '@/types/graphql'

import ChangeAvatarModal from '@/modals/ChangeAvatarModal'

import { useChangeCustomerLogoMutation } from '@/graphql'

const useChangeLogoModal = (
  customer: GetCustomerQuery['customer'] | null | undefined
) => {
  const [changeCustomerLogo] = useChangeCustomerLogoMutation()

  const handleUpload = useCallback(
    (logoKey: string) =>
      changeCustomerLogo({ variables: { logoKey, customerId: customer!.id } }),
    [customer?.id]
  )

  const [showModal, hideModal] = useModal(
    () => (
      <ChangeAvatarModal
        currentAvatarUrl={customer?.logoUrl ?? null}
        hideModal={hideModal}
        onUpload={handleUpload}
      />
    ),
    [customer?.logoUrl]
  )

  return showModal
}

export default useChangeLogoModal
