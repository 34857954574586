import { useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import useModal from '@area2k/use-modal'
import { FieldContext, SubmitHelpers } from '@area2k/use-form'
import { useGetCustomerLazyQuery } from '@/graphql'
import { Maybe } from '@/types'

import { currentAdminVar } from '@/util/apollo/cache'
import { Role } from '@/routes/PrivateRoute'

import Input from '@/elements/Input'
import Button from '@/components/Button'
import Card from '@/components/Card'
import Stack from '@/components/Stack'

import Form from '@/form'
import FormElement from '@/components/FormElement'

import AccountSelectField from './AccountSelectField'
import CustomerAutocompleteField from './CustomerAutocompleteField'

import CustomerProfileStepsModal from './modals/CustomerProfileStepsModal'
import RequiredOneRateQuoteBySelectedAccount from './modals/RequiredOneRateQuoteBySelectedAccount'
import { Billing as BillingType, useOrderActions } from '../context'

export enum CustomerProfileSteps {
  CUSTOMER_ADMIN_CREATION = 1,
  BILLING_ACCOUNT_CREATION = 2,
  ADDRESS_CREATION = 3,
  CUSTOM_RATE_CREATION = 4,
}

type FormValues = {
  customer: Maybe<BillingType['customer']>
  account: Maybe<BillingType['account']>
}

const Billing = () => {
  const currentAdmin = useReactiveVar(currentAdminVar)

  const [
    fetchGetCustomer,
    { data: customerData, loading: loadingCustomerData },
  ] = useGetCustomerLazyQuery()

  const { setBilling } = useOrderActions()

  const [profileStep, setProfileStep] = useState<Maybe<CustomerProfileSteps>>(
    null
  )

  const [formValues, setFormValues] = useState<FormValues>({
    customer: null,
    account: null,
  })

  const [showExitJobEditorModal, hideExitJobEditorModal] = useModal(
    () => (
      <CustomerProfileStepsModal
        customer={formValues.customer}
        currentStep={profileStep}
        totalSteps={Object.keys(CustomerProfileSteps).length / 2}
        onConfirm={() => setProfileStep(null)}
        hideModal={hideExitJobEditorModal}
      />
    ),
    [profileStep]
  )

  const [
    showRequiredOneRateQuoteBySelectedAccountModal,
    hideRequiredOneRateQuoteBySelectedAccountModal,
  ] = useModal(
    () => (
      <RequiredOneRateQuoteBySelectedAccount
        customer={formValues.customer}
        account={formValues.account}
        onConfirm={() => setProfileStep(null)}
        hideModal={() => {
          setProfileStep(null)
          setFormValues((prevValues) => ({ ...prevValues, account: null }))
          hideRequiredOneRateQuoteBySelectedAccountModal()
        }}
      />
    ),
    [formValues]
  )

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      if (formValues.account && formValues.customer) {
        setBilling({
          account: formValues.account,
          customer: formValues.customer,
        })
        setProfileStep(null)
      } else {
        setFormError('incomplete', {
          title: 'Please complete all fields',
          message: 'All fields are required to continue.',
        })
      }
    },
    [formValues]
  )

  useEffect(() => {
    if (profileStep) showExitJobEditorModal()
  }, [profileStep])

  useEffect(() => {
    if (formValues.customer) {
      const { customer, account } = formValues

      if (customer.admins.items.length === 0)
        return setProfileStep(CustomerProfileSteps.CUSTOMER_ADMIN_CREATION)

      if (customer.accounts.length === 0)
        return setProfileStep(CustomerProfileSteps.BILLING_ACCOUNT_CREATION)

      if (customer.addresses.length === 0)
        return setProfileStep(CustomerProfileSteps.ADDRESS_CREATION)

      const customerHasOneRateQuote = customer.addresses.some(
        ({ rateQuotes }) => rateQuotes && rateQuotes.length !== 0
      )

      if (!customerHasOneRateQuote)
        return setProfileStep(CustomerProfileSteps.CUSTOM_RATE_CREATION)

      if (account) {
        const accountHasOneRateQuote =
          customer.addresses.some(
            ({ rateQuotes }) =>
              rateQuotes &&
              rateQuotes.length !== 0 &&
              rateQuotes.some(
                (rateQuote) => rateQuote.account!.id === account.id
              )
          ) || false

        if (!accountHasOneRateQuote) {
          return showRequiredOneRateQuoteBySelectedAccountModal()
        }
      }

      return setProfileStep(null)
    }
  }, [formValues])

  useEffect(() => {
    if (customerData) {
      const { customer } = customerData

      setFormValues((prevValues) => ({
        ...prevValues,
        customer: {
          __typename: customer.__typename,
          id: customer.id,
          accounts: customer.accounts,
          addresses: customer.addresses,
          admins: customer.admins,
          name: customer.name,
        },
      }))
    }
  }, [currentAdmin, customerData])

  useEffect(() => {
    if (
      currentAdmin!.__typename === 'CustomerAdmin' &&
      currentAdmin!.role === Role.CUSTOMER_ADMIN
    ) {
      fetchGetCustomer({
        variables: { customerId: currentAdmin!.customer!.id },
      })
    }
  }, [currentAdmin])

  return (
    <Card sectioned title="Customer selection">
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        {currentAdmin!.role === Role.CUSTOMER_ADMIN ? (
          <FormElement label="Customer">
            <Input
              disabled
              value={formValues.customer ? formValues.customer.name : ''}
            />
          </FormElement>
        ) : (
          <CustomerAutocompleteField
            fieldId="customer"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'customer')
            }}
          />
        )}

        <AccountSelectField
          fieldId="account"
          label="Billing account*"
          customer={formValues.customer}
          profileStep={profileStep}
          accountCallback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'account')
          }}
        />

        <Stack justify="end">
          <Button
            disabled={
              !formValues.customer || !formValues.account || loadingCustomerData
            }
            a11yLabel="Submit"
            label="Start order"
            type="submit"
          />
        </Stack>
      </Form>
    </Card>
  )
}

export default Billing
