import { useQuery, useReactiveVar } from '@apollo/client'
import { SubmitHelpers } from '@area2k/use-form'
import { useCallback, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import useLocationSearch from '@/hooks/useLocationSearch'
import useQueryString from '@/hooks/useQueryString'

import Link from '@/elements/Link'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Page from '@/components/Page'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Display } from '@/components/Typography'
import Version from '@/components/Version'

import Form from '@/form'
import FormFooter from '@/form/FormFooter'
import TextField from '@/form/TextField'

import { useResetPasswordMutation } from '@/graphql'

import { currentTenantVar } from '@/util/apollo/cache'
import { handleMutationFormError } from '@/util/error'
import ls, { DEVICE_TOKEN_KEY } from '@/util/localstorage'

type FormValues = { password: string; confirmPassword: string }

const initialValues: FormValues = { password: '', confirmPassword: '' }

const ResetPassword = () => {
  const [onSend, setOnSend] = useState<boolean>(false)
  const { to } = useLocationSearch()
  const query = useQueryString()

  const token = query.get('token')

  const deviceToken = ls.get(DEVICE_TOKEN_KEY)
  const destination = useMemo(() => (typeof to === 'string' ? to : '/'), [to])

  const currentTenant = useReactiveVar(currentTenantVar)
  const [userResetPassword] = useResetPasswordMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        if (values.password != values.confirmPassword) {
          throw {
            type: 'Error Form',
            name: 'Invalid password',
            message: 'password does not match',
          }
        }
        userResetPassword({
          variables: { token: token, password: values.password },
        })
        setOnSend(true)
      } catch (err) {
        if (err.type == 'Error Form') {
          return setFormError(err.type, {
            title: err.name,
            message: err.message,
            status: 'danger',
          })
        }
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        })
      }
    },
    [currentTenant]
  )

  if (deviceToken) {
    return <Navigate replace to={destination} />
  }

  return (
    <Page size="xs">
      <div style={{ padding: '64px 0' }}>
        <Stack vertical gap={24}>
          <div
            style={{
              padding: '0 12.5% 16px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Display>{currentTenant?.name}</Display>
          </div>
          {onSend ? (
            <Card>
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <TextStack>
                    <Body weight="bold">
                      Your password was successfully created!
                    </Body>
                  </TextStack>
                  <Stack justify="end">
                    <Link to="/login">
                      <Button type="button" a11yLabel="Sign In" />
                    </Link>
                  </Stack>
                </Stack>
              </Card.Section>
            </Card>
          ) : (
            <Card title="Set new password">
              <Card.Section>
                <Stack vertical verticalGap={25}>
                  <Form
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    style={{ width: '100%' }}
                  >
                    <TextField
                      required
                      autoComplete="current-password"
                      css={{ letterSpacing: '2px' }}
                      fieldId="password"
                      label="New Password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      type="password"
                    />
                    <TextField
                      required
                      autoComplete="current-password"
                      css={{ letterSpacing: '2px' }}
                      fieldId="confirmPassword"
                      label="Confirm new password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      type="password"
                    />
                    <FormFooter>
                      <Button type="submit" a11yLabel="Confirm" />
                    </FormFooter>
                  </Form>
                </Stack>
              </Card.Section>
            </Card>
          )}
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Version size="sm" />
          </div>
        </Stack>
      </div>
    </Page>
  )
}

export default ResetPassword
