import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import { UserListManager } from "./controller";

import { CometChatUserListItem } from "../../Users";

import { CometChatContextProvider, CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import { 
  contactWrapperStyle, 
  contactHeaderStyle, 
  contactHeaderCloseStyle, 
  contactHeaderTitleStyle,
  contactSearchStyle,
  contactSearchInputStyle,
  contactMsgStyle,
  contactMsgTxtStyle,
  contactListStyle,
  contactAlphabetStyle
} from "./style";

import searchIcon from "./resources/search-grey-icon.png";
import navigateIcon from "./resources/navigate.png";

class CometChatUserList extends React.PureComponent {

  item;
  timeout;
  friendsOnly = false;
  static contextType = CometChatContext;

  constructor(props) {

    super(props);

    this.state = {
      userlist: [],
      lang: props.lang
    }

    this.friendsOnly = props.friendsOnly;
    this.contextProviderRef = React.createRef();
    this.decoratorMessage = Translator.translate("LOADING", props.lang);
    this.userListRef = React.createRef();

    CometChat.getLoggedinUser().then(user => this.loggedInUser = user).catch(error => {
      console.error(error);
    });
  }

  componentDidMount() {

    this.item = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) ? this.getContext().item : null;

    if(this.props.hasOwnProperty("widgetsettings") 
    && this.props.widgetsettings
    && this.props.widgetsettings.hasOwnProperty("sidebar") 
    && this.props.widgetsettings.sidebar.hasOwnProperty("user_listing")) {

      switch(this.props.widgetsettings.sidebar["user_listing"]) {
        case "friends":
          this.friendsOnly = true;
        break;
        default:
        break;
      }
    }
    
    this.UserListManager = new UserListManager(this.friendsOnly);
    this.getUsers();
    this.UserListManager.attachListeners(this.userUpdated);
  }

  componentDidUpdate(prevProps) {

    //if user is blocked/unblocked, update userlist
    if (this.item 
    && Object.keys(this.item).length
    && this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER && this.item.uid === this.getContext().item.uid
    && this.item.blockedByMe !== this.getContext().item.blockedByMe) {

      let userlist = [...this.state.userlist];

      //search for user
      let userKey = userlist.findIndex(u => u.uid === this.getContext().item.uid);
      if(userKey > -1) {

        let userObject = { ...userlist[userKey] };
        let newUserObject = Object.assign({}, userObject, { blockedByMe: this.getContext().item.blockedByMe });
        
        userlist.splice(userKey, 1, newUserObject);
        this.setState({ userlist: userlist });
      }
    }

    this.item = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER) ? this.getContext().item : null;

    if (prevProps.lang !== this.props.lang) {
      this.setState({ lang: this.props.lang });
    }
  }

  componentWillUnmount() {

    this.UserListManager.removeListeners();
    this.UserListManager = null;
  }

  userUpdated = (user) => {
    
    let userlist = [...this.state.userlist];

    //search for user
    let userKey = userlist.findIndex(u => u.uid === user.uid);
    
    //if found in the list, update user object
    if(userKey > -1) {

      let userObj = {...userlist[userKey]};
      let newUserObj = {...userObj, ...user};
      userlist.splice(userKey, 1, newUserObj);

      this.setState({ userlist: userlist });
    }
  }

  handleScroll = (e) => {

    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight);
    if (bottom) this.getUsers();
  }

  handleClick = (user) => {

    if(!this.props.onItemClick)
      return;

    this.props.onItemClick(user, CometChat.ACTION_TYPE.TYPE_USER);
  }

  handleMenuClose = () => {

    if(!this.props.actionGenerated) {
      return false;
    }

    this.props.actionGenerated(enums.ACTIONS["TOGGLE_SIDEBAR"]);
  }
  
  searchUsers = (e) => {

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    let val = e.target.value;
    this.timeout = setTimeout(() => {

      this.UserListManager = new UserListManager(this.friendsOnly, val);
      this.setState({ userlist: [] }, () => this.getUsers())
    }, 500)

  }

  getUsers = () => {

    this.UserListManager.fetchNextUsers().then((userList) => {

      if(userList.length === 0) {
        this.decoratorMessage = Translator.translate("NO_USERS_FOUND", this.state.lang);
      }
      
      this.setState({ userlist: [...this.state.userlist, ...userList] });
        
    }).catch(error => {

      this.decoratorMessage = Translator.translate("ERROR", this.state.lang);

      const errorCode = (error && error.hasOwnProperty("code")) ? error.code : "ERROR";
      this.getContext().setToastMessage("error", errorCode);

    });
  }

  getContext = () => {

    if (this.props._parent.length) {
      return this.context;
    } else {
      return this.contextProviderRef.state;
    }
  }

  render() {

    let messageContainer = null;
    if(this.state.userlist.length === 0) {
      messageContainer = (
        <div css={contactMsgStyle()} className="contacts__decorator-message">
          <p css={contactMsgTxtStyle(this.props)} className="decorator-message">{this.decoratorMessage}</p>
        </div>
      );
    }

    const userList = [...this.state.userlist];
    let currentLetter = "";
    
    const users = userList.map((user, key) => {
      
      const chr = user.name[0].toUpperCase();
      let firstChar = null;
      if (chr !== currentLetter) {
        currentLetter = chr;
        firstChar = (<div css={contactAlphabetStyle()} className="contacts__list__alphabet-filter">{currentLetter}</div>);
      } else {
        firstChar = null;
      }
      
      let selectedUser = (this.getContext().type === CometChat.ACTION_TYPE.TYPE_USER && this.getContext().item.uid === user.uid) ? user : null;

      return (
        <React.Fragment key={key}>
          {firstChar}
          <CometChatUserListItem 
          theme={this.props.theme}
          user={user} 
          selectedUser={selectedUser}
          lang={this.state.lang}
          widgetsettings={this.props.widgetsettings} 
          clickHandler={this.handleClick}  />
        </React.Fragment>
      );

    });

    let closeBtn = (<div css={contactHeaderCloseStyle(navigateIcon, this.props)} className="header__close" onClick={this.handleMenuClose}></div>);
    if (this.getContext() && Object.keys(this.getContext().item).length === 0) {
      closeBtn = null;
    }

    const userListTemplate = (
      <div css={contactWrapperStyle(this.props)} className="contacts">
        <div css={contactHeaderStyle(this.props.theme)} className="contacts__header">
          {closeBtn}
          <h4 css={contactHeaderTitleStyle(this.props)} className="header__title" dir={Translator.getDirection(this.state.lang)}>{Translator.translate("USERS", this.state.lang)}</h4>
          <div></div>
        </div>
        <div css={contactSearchStyle()} className="contacts__search">
          <input
            type="text"
            autoComplete="off"
            css={contactSearchInputStyle(this.props, searchIcon)}
            className="search__input"
            placeholder={Translator.translate("SEARCH", this.state.lang)}
            onChange={this.searchUsers} />
        </div>
        {messageContainer}
        <div css={contactListStyle()} className="contacts__list" onScroll={this.handleScroll} ref={el => this.userListRef = el}>{users}</div>
      </div>
    );

    let userListWrapper = (userListTemplate);
    if (this.props._parent === "") {
      userListWrapper = (
        <CometChatContextProvider ref={el => this.contextProviderRef = el} >
          {userListTemplate}
        </CometChatContextProvider>
      );
    }

    return (userListWrapper);
  }
}

// Specifies the default values for props:
CometChatUserList.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
  friendsOnly: false,
  onItemClick: () => {},
  _parent: ""
};

CometChatUserList.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
  friendsOnly: PropTypes.bool,
  onItemClick: PropTypes.func,
  _parent: PropTypes.string
}

export default CometChatUserList;
