import { useReactiveVar } from '@apollo/client'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { useCreateTenantAdminMutation, useInviteAdminMutation } from '@/graphql'

import Alert from '@/components/Alert'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'
import Tabs, { TabDefinition } from '@/components/Tabs'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextField from '@/form/TextField'

import { Maybe } from '@/types'

import { currentAgencyVar, currentTenantVar } from '@/util/apollo/cache'

import CustomerAutocompleteField, {
  CustomerItem,
} from '../../CreateOrder/Billing/CustomerAutocompleteField'

type Props = {
  hideModal: () => void
  setCreatedItem: Dispatch<
    SetStateAction<{
      created: boolean
      data: string
    }>
  >
}

type FormValues = {
  customer?: Maybe<CustomerItem>
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
}

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'Create Tenant Admin', label: 'Tenant Admin' },
  { a11yLabel: 'Create Customer Admins', label: 'Customer Admin' },
]

const CreateAdminModal = ({ setCreatedItem, hideModal }: Props) => {
  const [error, setError] = useState({
    active: false,
    data: {},
  })
  const [tab, setTab] = useState(0)

  const currentTenant = useReactiveVar(currentTenantVar)
  const currentAgency = useReactiveVar(currentAgencyVar)

  const [initialValues, setInitialValues] = useState<FormValues>({
    customer: null,
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    password: '',
  })

  const [
    createCustomerAdmin,
    { loading: isLoadingCustomerAdmin },
  ] = useInviteAdminMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { customerAdmins() {} },
      })
    },
  })

  const [
    createTenantAdmin,
    { loading: isLoadingTenantAdmin },
  ] = useCreateTenantAdminMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentTenant!),
        fields: { admins() {} },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (tab === 0) {
          const resultTenant = await createTenantAdmin({
            variables: {
              ...values,
              tenantId: currentTenant!.id,
            },
          })

          if (resultTenant.data) {
            setCreatedItem({
              created: true,
              data: resultTenant!.data.tenantAdminInvite.tenantAdmin.user.firstName,
            })
            hideModal()
          }
        }

        const resultCustomer = await createCustomerAdmin({
          variables: {
            ...values,
            customerId: values.customer!.id,
          },
        })

        if (resultCustomer.data) {
          setCreatedItem({
            created: true,
            data:
              resultCustomer.data.customerInviteAdmin.customerAdmin.user.firstName,
          })
          hideModal()
        }
      } catch (err) {
        setError({ active: true, data: err })
      }
    },
    [tab]
  )

  return (
    <Modal size="xs" title="New Admin" onRequestClose={hideModal}>
      <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
      <Card.Section>
        {error.active && (
          <Alert
            description={error.data.message}
            icon={faExclamationTriangle}
            title="Something went wrong"
            status="warning"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {tab === 0 ? null : (
            <CustomerAutocompleteField
              fieldId="customer"
              callback={(fieldContext) => {
                if (fieldContext.value) {
                  setInitialValues((prevValues) => {
                    return {
                      ...prevValues,
                      customer: fieldContext.value!,
                    }
                  })
                }
              }}
            />
          )}
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="email"
            fieldId="email"
            label="Email"
            placeholder="Email address"
            type="email"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <TextField
            css={{ letterSpacing: '2px' }}
            required
            autoComplete="password"
            fieldId="password"
            label="Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              label="Send Invite"
              isLoading={
                tab === 0 ? isLoadingTenantAdmin : isLoadingCustomerAdmin
              }
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default CreateAdminModal
