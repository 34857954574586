import { useReactiveVar } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { Maybe } from '@/types'
import {
  CandidateStatusEnum,
  CommentableEnum,
  GetWorkerQuery,
  LanguageEnum,
  PointInput,
  SmartphoneTypeEnum,
  TaxTypeEnum,
  WorkerReference,
} from '@/types/graphql'
import {
  useAddCommentMutation,
  useAddWorkerReferenceMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerReferenceMutation,
  useUpdateApplicantMutation,
} from '@/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'
import { sortBy } from '@/util/array'
import { currentAgencyVar } from '@/util/apollo/cache'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Tabs, { TabDefinition } from '@/components/Tabs'
import Button from '@/components/Button'
import Autocomplete from '@/components/Autocomplete'
import FormElement from '@/components/FormElement'
import TagList from '@/components/TagList'
import ExperienceForm from '@/components/ExperienceForm'
import { Small } from '@/components/Typography'
import Alert from '@/components/Alert'
import EditAddress from '@/components/EditAddress'
import Option from '@/components/Option'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextAreaField from '@/form/TextAreaField'
import TextField from '@/form/TextField'
import TextSelectField from '@/form/TextSelectField'
import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import MultiOptionGroupField from '@/form/MultiOptionGroupField'

import { CandidateItem } from '../helpers'
import { LinkWrapper } from '../EditCandidateModal/styles'
import InterviewForm from './Interview'

type SkillItem = GetWorkerQuery['worker']['skills'][0]

type Props = {
  hideModal: () => void
  applicant: CandidateItem
}

type UserFormValues = {
  candidateStatus: string
  taxType: TaxTypeEnum
  firstName: string
  lastName: string
  email: string
  phoneNumber: Maybe<string>
  language: LanguageEnum
  secondaryLanguage: LanguageEnum
  addressLine1: string
  addressLine2: string
  city: Maybe<string>
  state: Maybe<string>
  zip: string
  coords: PointInput
  usCitizenOrWorkPermit: boolean
  ownsCar: boolean
}

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View users', label: 'Applicant Information' },
  { a11yLabel: 'View interviews', label: 'Interview Information' },
]

const languageOptions = [
  { label: 'English', value: LanguageEnum.ENGLISH },
  { label: 'Spanish', value: LanguageEnum.SPANISH },
]

const statusOptions = [
  { label: 'Applied', value: CandidateStatusEnum.APPLIED },
  { label: 'Schedule Interview', value: CandidateStatusEnum.INTERVIEWING },
  { label: 'Reject Worker', value: CandidateStatusEnum.REJECTED },
]

const taxOptions = [
  { label: 'W2', value: TaxTypeEnum.TAX_W2 },
  { label: '1099', value: TaxTypeEnum.TAX_1099 },
]

const selectOptions = [
  { label: 'US Citizenship or Work Permit?', value: 'availableForWorkingUS' },
  {
    label: 'Access To Reliable Transportation?',
    value: 'reliableTransportation',
  },
]

const EditApplicantModal = ({ applicant, hideModal }: Props) => {
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false)
  const [tab, setTab] = useState(0)
  const [query, setQuery] = useState('')
  const [skills, setSkills] = useState<SkillItem[]>(applicant.skills)
  const [references, setReferences] = useState<WorkerReference[]>(
    applicant.references
  )
  const [deleteQueue, setDeleteQueue] = useState<string[]>([])
  const [userFormValues, setUserFormValues] = useState<UserFormValues>({
    candidateStatus: applicant.candidateStatus,
    taxType: applicant.taxType ? applicant.taxType : TaxTypeEnum.TAX_W2,
    firstName: applicant.user.firstName,
    lastName: applicant.user.lastName,
    email: applicant.user.email,
    phoneNumber: applicant.user.phoneNumber,
    language: applicant.user.language,
    secondaryLanguage: applicant.user.secondaryLanguage,
    addressLine1: applicant.addressLine1,
    addressLine2: applicant.addressLine2,
    city: applicant.city,
    state: applicant.state,
    zip: applicant.zip,
    coords: applicant.coords,
    usCitizenOrWorkPermit: applicant.usCitizenOrWorkPermit
      ? applicant.usCitizenOrWorkPermit
      : false,
    ownsCar: applicant.ownsCar ? applicant.ownsCar : false,
    smartphoneType: applicant.user.smartphoneType || SmartphoneTypeEnum.ANDROID,
  })
  const [comment, setComment] = useState<string>(
    applicant.comments.length > 0
      ? applicant.comments[applicant.comments.length - 1].body
      : ''
  )
  const [hire, setHire] = useState<boolean>(false)
  const [error, setError] = useState({
    active: false,
    obj: {},
  })
  const [initialReferenceLength, setInitialReferenceLength] = useState<number>(
    applicant.references ? applicant.references.length : 0
  )
  const initialComment =
    applicant.comments.length > 0
      ? applicant.comments[applicant.comments.length - 1].body
      : ''

  const debouncedQuery = useDebouncedValue(query)
  const currentAgency = useReactiveVar(currentAgencyVar)

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const [addReference] = useAddWorkerReferenceMutation()
  const [removeReference] = useRemoveWorkerReferenceMutation()
  const [addComment] = useAddCommentMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })
  const [updateApplicant] = useUpdateApplicantMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })

  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skills.some((workerSkill) => workerSkill.id === skill.id)) {
            return false
          }

          return skill.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        })

      return sortBy(availableSkills, 'name')
    }

    return []
  }, [skills, data, debouncedQuery])

  const sortedWorkerSkills = useMemo(() => sortBy(skills, 'name'), [skills])

  const handleRemove = (index: number) => {
    let trueIndex = skills.findIndex(
      (skill) => skill.id === sortedWorkerSkills[index].id
    )
    let skillsCopy = [...skills]
    skillsCopy.splice(trueIndex, 1)
    setSkills([...skillsCopy])
  }

  const handleSelect = useCallback(
    (item) => {
      setSkills([...skills, item.selectedItem])
    },
    [skills]
  )

  const handleReferenceDeletion = (reference: WorkerReference) => {
    setDeleteQueue([...deleteQueue, reference.worker.id])
  }

  const handleFormValuesChange = ({ value }, id) => {
    setUserFormValues({ ...userFormValues, [id]: value })
  }

  const submitReferences = useCallback(async () => {
    try {
      references.map(async (reference) => {
        const refId = await addReference({
          variables: {
            ...reference,
            workerId: applicant.id,
          },
        })
      })
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [references])

  const deleteReferences = useCallback(async () => {
    try {
      deleteQueue.map(async (workerReferenceId) => {
        await removeReference({
          variables: { workerReferenceId },
        })
      })
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [deleteQueue])

  const handleSubmit = useCallback(async () => {
    const skillIds = skills.map(({ id }) => id)
    let finalStatus: CandidateStatusEnum = hire
      ? CandidateStatusEnum.START_HIRE
      : userFormValues.candidateStatus

    try {
      if (references.length > 0 && references.length !== initialReferenceLength)
        await submitReferences()
      if (deleteReferences.length > 0) await deleteReferences()
      if (initialComment !== comment)
        await addComment({
          variables: {
            commentableId: applicant.id,
            commentableType: CommentableEnum.WORKER,
            body: comment,
          },
        })
      await updateApplicant({
        variables: {
          ...userFormValues,
          workerId: applicant.id,
          candidateStatus: finalStatus,
          skillIds,
        },
      })
      hideModal()
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [
    comment,
    skills,
    userFormValues,
    references,
    deleteQueue,
    hire,
    initialReferenceLength,
  ])

  useEffect(() => {
    setError({ active: false, obj: {} })
  }, [tab])

  return (
    <Modal
      title="Edit Applicant"
      size="xl"
      onRequestClose={hideModal}
      disableClickout
    >
      <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
      <Card.Section>
        {error.active && (
          <Alert
            description={error.obj.message}
            icon={faExclamationTriangle}
            title="Something went wrong"
            status="danger"
          />
        )}
        {tab === 0 ? (
          <>
            <Form
              initialValues={{ ...userFormValues, comment }}
              onSubmit={handleSubmit}
            >
              <TextSelectField
                fieldId="candidateStatus"
                label="Status"
                options={statusOptions}
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'candidateStatus')
                }}
                disabled={hire}
              />
              <LinkWrapper>
                <Small>----- or ------</Small>
                <Button
                  a11yLabel={'start hire process'}
                  label={hire ? 'Cancel process' : 'Start hire process'}
                  appearance="plain"
                  type="button"
                  status={hire ? 'danger' : 'theme'}
                  css={{ alignSelf: 'flex-start' }}
                  onClick={() => {
                    setHire(!hire)
                  }}
                />
              </LinkWrapper>
              <FormColumns>
                <TextField
                  required
                  fieldId="firstName"
                  label="First Name"
                  placeholder="First Name"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'firstName')
                  }}
                />
                <TextField
                  required
                  fieldId="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'lastName')
                  }}
                />
                <TextField
                  required
                  fieldId="email"
                  label="Email"
                  placeholder="Email"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'email')
                  }}
                />
                <MaskedInputField
                  fieldId="phoneNumber"
                  incompletemessage="Must be a valid phone number"
                  label="Phone Number"
                  mask="(000) 000-0000"
                  placeholder={
                    applicant.user.phoneNumber
                      ? applicant.user.phoneNumber
                      : '(555) 555-5555'
                  }
                  type="tel"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'phoneNumber')
                  }}
                />
                <TextSelectField
                  fieldId="language"
                  label="Primary language"
                  options={languageOptions}
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'language')
                  }}
                />
                <TextSelectField
                  fieldId="secondaryLanguage"
                  label="Secondary language"
                  options={languageOptions}
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'secondaryLanguage')
                  }}
                />
                <FormElement label="Skills">
                  {sortedWorkerSkills.length > 0 && (
                    <div style={{ marginBottom: 8 }}>
                      <TagList
                        tags={sortedWorkerSkills.map((skill) => skill.name)}
                        onRemove={handleRemove}
                      />
                    </div>
                  )}
                  <Autocomplete
                    id="skillFather"
                    itemToKey={(item) => item.id}
                    items={items}
                    itemToString={(item) => (item ? item.name : '')}
                    placeholder="Search for skills..."
                    selectedItem={null}
                    onInputValueChange={({ inputValue }) =>
                      setQuery(inputValue || '')
                    }
                    onSelectedItemChange={handleSelect}
                    fixedSelectHeight="lg"
                  />
                </FormElement>
                <FormElement htmlFor="" label="Address">
                  <EditAddress
                    values={userFormValues}
                    setValues={setUserFormValues}
                  />
                </FormElement>
                <SingleOptionGroupField
                  options={taxOptions}
                  fieldId="taxType"
                  label="Tax type"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'taxType')
                  }}
                />
                <FormElement label="Important Information">
                  <Stack vertical="true">
                    <Option
                      appearance="checkbox"
                      id={`${selectOptions[0].label}`}
                      label={selectOptions[0].label}
                      checked={userFormValues.usCitizenOrWorkPermit}
                      onChange={(event) => {
                        setUserFormValues((prevState) => {
                          return {
                            ...prevState,
                            usCitizenOrWorkPermit: !prevState.usCitizenOrWorkPermit,
                          }
                        })
                      }}
                    />
                    <Option
                      appearance="checkbox"
                      id={`${selectOptions[1].label}`}
                      label={selectOptions[1].label}
                      checked={userFormValues.ownsCar}
                      onChange={(event) => {
                        setUserFormValues((prevState) => {
                          return {
                            ...prevState,
                            ownsCar: !prevState.ownsCar,
                          }
                        })
                      }}
                    />
                  </Stack>
                </FormElement>
                <TextAreaField
                  fieldId="comment"
                  label="Notes"
                  placeholder="Any notes..."
                  callback={(fieldContext) => {
                    setComment(fieldContext.value)
                  }}
                />
              </FormColumns>
            </Form>
            <ExperienceForm
              experiences={references}
              deleteExperiences={deleteQueue}
              handleAddition={setReferences}
              handleDeletion={setDeleteQueue}
            />
          </>
        ) : (
          <InterviewForm
            applicant={applicant}
            triggerSubmit={submitTrigger}
            setTrigger={setSubmitTrigger}
            hideModal={hideModal}
            setError={setError}
          />
        )}
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            isLoading={undefined}
            label="Save"
            type="submit"
            onClick={() => {
              tab === 0 ? handleSubmit() : setSubmitTrigger(true)
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default EditApplicantModal
