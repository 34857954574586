import { FC } from 'react'

import styled from '@/styles'

import Stack from '@/components/Stack'

const Wrapper = styled('div', {
  display: 'flex',
  height: 80,

  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,

  backgroundColor: 'white',
  boxShadow: '0 -4px 8px $colors$neutralA12',

  zIndex: 1000
})

Wrapper.displayName = 'stitches(JobEditor.Steps.BottomBar.Wrapper)'

const BottomBar: FC = ({ children }) => {
  return (
    <Wrapper>
      <Stack gap={16} justify='center'>
        {children}
      </Stack>
    </Wrapper>
  )
}

export default BottomBar
