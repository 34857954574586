import { useMemo } from 'react'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import useModal from '@area2k/use-modal'
import { useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import {
  faChevronDown,
  faSignOutAlt,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { GenericAction } from '@/types'

import { useAppDispatch, useAppSelector } from '@/hooks/store'
import useMediaQuery from '@/hooks/useMediaQuery'
import { selectSidebarActive, toggleSidebar } from '@/store/slices/layoutSlice'
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache'
import ROUTES from '@/routes/routes'

import Icon from '@/elements/Icon'
import Text from '@/elements/Text'

import Avatar from '@/components/Avatar'
import Stack from '@/components/Stack'

import { Role } from '@/routes/PrivateRoute'

import DropdownMenu from '../DropdownMenu'
import TextStack from '../TextStack'
import SignOutModal from './modals/SignOutModal'
import {
  Wrapper,
  DropdownWrapper,
  AvatarWrapper,
  BurgerMenu,
  Branding,
  Profile,
  NameContainer,
  ProfileField,
} from './styles'

const AppNavbar = () => {
  const navigate = useNavigate()

  const showSidebar = useAppSelector((state) => selectSidebarActive(state))

  const dispatch = useAppDispatch()

  const phoneOnly = useMediaQuery('(max-width: 559px)')

  const currentTenant = useReactiveVar(currentTenantVar)
  const currentAdmin = useReactiveVar(currentAdminVar)

  const [showSignOutModal, hideSignOutModal] = useModal(
    () => (
      <SignOutModal
        onConfirm={() => navigate(ROUTES.signOut)}
        onHide={hideSignOutModal}
      />
    ),
    []
  )

  const dropdownActions = useMemo((): GenericAction[][] => {
    const signOutAction: GenericAction = {
      a11yLabel: 'Sign out',
      icon: faSignOutAlt,
      label: 'Sign out',
      onAction: showSignOutModal,
    }

    const goToMyProfileAction: GenericAction = {
      a11yLabel: 'My profile',
      icon: faUser,
      label: 'My profile',
      onAction: () => navigate(ROUTES.myProfile),
    }

    const tenantAdminActions: GenericAction[] = [signOutAction]

    const customerAdminActions: GenericAction[] = [
      goToMyProfileAction,
      signOutAction,
    ]

    return [
      currentAdmin?.role === Role.TENANT_ADMIN
        ? tenantAdminActions
        : customerAdminActions,
    ]
  }, [currentAdmin])

  return (
    <Wrapper>
      <BurgerMenu
        onClick={() => dispatch(toggleSidebar())}
        change={showSidebar ? true : false}
      >
        <Icon size="2x" icon={showSidebar ? faTimes : faBars} />
      </BurgerMenu>
      <Branding to={ROUTES.basePath}>
        {currentTenant ? (
          currentTenant.logoUrl ? (
            <img
              style={{ width: '100%', height: 36 }}
              src={currentTenant.logoUrl}
            />
          ) : (
            <Text color="inherit" size="lg" weight="semibold">
              {currentTenant.name}
            </Text>
          )
        ) : null}
      </Branding>
      <DropdownWrapper>
        <DropdownMenu
          actions={dropdownActions}
          justify="right"
          size={phoneOnly ? 'sm' : 'md'}
          margin="rightLg"
        >
          <Profile>
            <Stack gap={20}>
              <AvatarWrapper>
                <Avatar
                  color
                  firstName={currentAdmin?.user.firstName}
                  size="sm"
                  src={currentAdmin?.avatarUrl}
                />
              </AvatarWrapper>
              <NameContainer>
                <TextStack spacing="tight">
                  <ProfileField>
                    {currentAdmin
                      ? `${currentAdmin.user.firstName} ${currentAdmin.user.lastName}`
                      : '...'}
                  </ProfileField>
                  <ProfileField light>
                    {currentAdmin ? currentAdmin.user.email : '...'}
                  </ProfileField>
                </TextStack>
              </NameContainer>
            </Stack>
            <Icon fixedWidth icon={faChevronDown} />
          </Profile>
        </DropdownMenu>
      </DropdownWrapper>
    </Wrapper>
  )
}

export default AppNavbar
