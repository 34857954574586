import React from 'react'
import { Route, Navigate, useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

import { currentAdminVar } from '@/util/apollo/cache'

export enum Role {
  CUSTOMER_ADMIN,
  TENANT_ADMIN,
}

interface Props {
  element: React.ReactElement
  requiredRoles: Role[]
  path?: string
  redirectTo?: string
}

const PrivateElement = ({
  element,
  requiredRoles,
  redirectTo = '/login',
}: Props) => {
  const location = useLocation()

  const currentAdmin = useReactiveVar(currentAdminVar)

  const userHasRequiredRoles =
    (currentAdmin && requiredRoles.includes(currentAdmin.role!)) || false

  return userHasRequiredRoles ? (
    element
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  )
}

const PrivateRoute = ({
  element,
  requiredRoles,
  redirectTo,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      element={
        <PrivateElement
          element={element}
          requiredRoles={requiredRoles}
          redirectTo={redirectTo}
        />
      }
    />
  )
}

export default PrivateRoute
