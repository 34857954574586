import Skeleton from '@/components/Skeleton'
import Stack from '@/components/Stack'

const PageSkeleton = () => {
  return (
    <Skeleton.Page>
      <Stack vertical gap={24}>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
        <Stack vertical>
          <Skeleton.Body lines={1} />
        </Stack>
      </Stack>
    </Skeleton.Page>
  )
}

export default PageSkeleton
