import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useState, useCallback, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import {
  CandidateStatusEnum,
  CommentableEnum,
  GetWorkerQuery,
  LanguageEnum,
  Maybe,
  PointInput,
  SmartphoneTypeEnum,
  WorkerReference,
} from '@/types/graphql'
import {
  useAddCommentMutation,
  useAddWorkerReferenceMutation,
  useListAgencySkillsQuery,
  useRemoveWorkerReferenceMutation,
  useUpdateCandidateMutation,
} from '@/graphql'

import Autocomplete from '@/components/Autocomplete'
import Card from '@/components/Card'
import Button from '@/components/Button'
import EditAddress from '@/components/EditAddress'
import ExperienceItem from '@/components/ExperienceForm/ExperienceItem'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Alert from '@/components/Alert'
import TagList from '@/components/TagList'
import ExperienceForm from '@/components/ExperienceForm'
import { Small } from '@/components/Typography'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextAreaField from '@/form/TextAreaField'
import TextField from '@/form/TextField'
import TextSelectField from '@/form/TextSelectField'

import { currentAgencyVar } from '@/util/apollo/cache'
import { sortBy } from '@/util/array'
import { handleMutationFormError } from '@/util/error'

import { LinkWrapper } from './styles'
import { CandidateItem } from '../helpers'

type SkillItem = GetWorkerQuery['worker']['skills'][0]

type Props = {
  experiences: WorkerReference[]
  hideModal: () => void
  candidate: CandidateItem
}

type FormValues = {
  candidateStatus: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: Maybe<string>
  language: LanguageEnum
  secondaryLanguage: LanguageEnum
  smartphoneType: SmartphoneTypeEnum
}

export type AddressValues = {
  addressLine1: Maybe<string>
  addressLine2: Maybe<string>
  city: Maybe<string>
  state: Maybe<string>
  zip: Maybe<string>
  coords: Maybe<PointInput>
}

const languageOptions = [
  { label: 'English', value: LanguageEnum.ENGLISH },
  { label: 'Spanish', value: LanguageEnum.SPANISH },
]

const statusOptions = [
  { label: 'Verified', value: CandidateStatusEnum.VERIFIED },
  { label: 'Unverified', value: CandidateStatusEnum.UNVERIFIED },
]

const smartPhoneTypeOptions = [
  { label: 'Android', value: SmartphoneTypeEnum.ANDROID },
  { label: 'IOS', value: SmartphoneTypeEnum.IOS },
  { label: 'Other', value: SmartphoneTypeEnum.OTHER },
]

const EditCandidateModal = ({ candidate, experiences, hideModal }: Props) => {
  const [formValues, setFormValues] = useState<FormValues>({
    candidateStatus: candidate.candidateStatus,
    firstName: candidate.user.firstName,
    lastName: candidate.user.lastName,
    email: candidate.user.email,
    phoneNumber: candidate.user.phoneNumber,
    language: candidate.user.language,
    secondaryLanguage: candidate.user.secondaryLanguage!,
    smartphoneType: candidate.user.smartphoneType || SmartphoneTypeEnum.ANDROID,
  })

  const [invitedToApply, setInvitedToApply] = useState<boolean>(false)
  const [comment, setComment] = useState<Maybe<string>>(
    candidate.comments.length > 0
      ? candidate.comments[candidate.comments.length - 1].body
      : ''
  )

  const [addressValues, setAddressValues] = useState<AddressValues>({
    addressLine1: candidate.addressLine1,
    addressLine2: candidate.addressLine2,
    city: candidate.city,
    state: candidate.state,
    zip: candidate.zip,
    coords: candidate.coords,
  })

  const [error, setError] = useState({
    active: false,
    obj: {},
  })
  const [query, setQuery] = useState('')
  const [skills, setSkills] = useState<SkillItem[]>(candidate.skills)
  const [references, setReferences] = useState<WorkerReference[]>(
    candidate.references
  )
  const [deleteQueue, setDeleteQueue] = useState<string[]>([])
  const [initialReferenceLength, setInitialReferenceLength] = useState<number>(
    candidate.references ? candidate.references.length : 0
  )

  const initialComment =
    candidate.comments.length > 0
      ? candidate.comments[candidate.comments.length - 1].body
      : ''

  const debouncedQuery = useDebouncedValue(query)
  const currentAgency = useReactiveVar(currentAgencyVar)

  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const [updateCandidate] = useUpdateCandidateMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })
  const [addComment] = useAddCommentMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })
  const [addReference] = useAddWorkerReferenceMutation()

  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skills.some((workerSkill) => workerSkill.id === skill.id)) {
            return false
          }

          return skill.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        })

      return sortBy(availableSkills, 'name')
    }

    return []
  }, [skills, data, debouncedQuery])

  const sortedWorkerSkills = useMemo(() => sortBy(skills, 'name'), [skills])

  const handleRemove = (index: number) => {
    let trueIndex = skills.findIndex(
      (skill) => skill.id === sortedWorkerSkills[index].id
    )
    let skillsCopy = [...skills]
    skillsCopy.splice(trueIndex, 1)
    setSkills([...skillsCopy])
  }

  const handleSelect = useCallback(
    (item) => {
      setSkills([...skills, item.selectedItem])
    },
    [skills]
  )
  const submitReferences = useCallback(async () => {
    try {
      references.map(async (reference) => {
        const refId = await addReference({
          variables: {
            ...reference,
            workerId: candidate.id,
          },
        })
      })
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [references])

  const handleFormValuesChange = ({ value }, id) => {
    setFormValues({ ...formValues, [id]: value })
  }

  const handleSubmit = useCallback(async () => {
    let finalStatus: CandidateStatusEnum = invitedToApply
      ? CandidateStatusEnum.APPLIED
      : values.candidateStatus
    try {
      if (references.length > 0 && references.length !== initialReferenceLength)
        await submitReferences()
      const worker = await updateCandidate({
        variables: {
          ...formValues,
          ...addressValues,
          workerId: candidate.id,
          candidateStatus: finalStatus,
        },
      })
      if (comment !== initialComment)
        await addComment({
          variables: {
            commentableId: candidate.id,
            commentableType: CommentableEnum.CUSTOMER_ADMIN,
            body: comment,
          },
        })
      hideModal()
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [formValues, addressValues, comment, invitedToApply, initialComment])

  return (
    <Modal title="Edit Candidate" size="xl" onRequestClose={hideModal}>
      <Card.Section>
        {error.active && (
          <Alert
            description={error.obj.message}
            icon={faExclamationTriangle}
            title="Something went wrong"
            status="danger"
          />
        )}

        <Form
          initialValues={{ ...formValues, notes: comment }}
          onSubmit={handleSubmit}
        >
          <TextSelectField
            fieldId="candidateStatus"
            label="Status"
            options={statusOptions}
            disabled={invitedToApply}
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'candidateStatus')
            }}
          />
          <LinkWrapper>
            <Small>----- or ------</Small>
            <Button
              a11yLabel={'invite to apply'}
              label={invitedToApply ? 'Cancel invitation' : 'Invite to apply'}
              appearance="plain"
              type="button"
              status={invitedToApply ? 'danger' : 'theme'}
              css={{ alignSelf: 'flex-start' }}
              onClick={() => {
                setInvitedToApply(!invitedToApply)
              }}
            />
          </LinkWrapper>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName')
              }}
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName')
              }}
            />
            <TextField
              autoFocus
              required
              fieldId="email"
              label="Email"
              placeholder="Email"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'email')
              }}
            />
            <MaskedInputField
              fieldId="phoneNumber"
              incompletemessage="Must be a valid phone number"
              label="Phone Number"
              mask="(000) 000-0000"
              placeholder={
                candidate.user.phoneNumber
                  ? candidate.user.phoneNumber
                  : '(555) 555-5555'
              }
              type="tel"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'phoneNumber')
              }}
            />
            <TextSelectField
              fieldId="language"
              label="Primary language"
              options={languageOptions}
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'language')
              }}
            />
            <TextSelectField
              fieldId="secondaryLanguage"
              label="Secondary language"
              options={languageOptions}
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'secondaryLanguage')
              }}
            />
            <TextSelectField
              fieldId="smartphoneType"
              label="Smartphone type"
              options={smartPhoneTypeOptions}
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'smartphoneType')
              }}
            />
            <FormElement label="Skills">
              <Autocomplete
                id="skillFather"
                itemToKey={(item) => item.id}
                items={items}
                itemToString={(item) => (item ? item.name : '')}
                placeholder="Search for skills..."
                selectedItem={null}
                onInputValueChange={({ inputValue }) =>
                  setQuery(inputValue || '')
                }
                onSelectedItemChange={handleSelect}
                fixedSelectHeight="lg"
              />
            </FormElement>
            <FormElement htmlFor="" label="Address">
              <EditAddress
                values={addressValues}
                setValues={setAddressValues}
              />
            </FormElement>
            {sortedWorkerSkills.length > 0 && (
              <div style={{ marginBottom: 8 }}>
                <TagList
                  tags={sortedWorkerSkills.map((skill) => skill.name)}
                  onRemove={handleRemove}
                />
              </div>
            )}
            <TextAreaField
              fieldId="notes"
              label="Notes"
              placeholder="Any notes..."
              callback={(fieldContext) => {
                setComment(fieldContext.value)
              }}
            />
          </FormColumns>
        </Form>
        <ExperienceForm
          experiences={references}
          deleteExperiences={deleteQueue}
          handleAddition={setReferences}
          handleDeletion={setDeleteQueue}
        />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            isLoading={undefined}
            label="Save"
            type="submit"
            onClick={() => handleSubmit()}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default EditCandidateModal
