import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons'

import Button from '@/components/Button'
import ButtonGroup from '@/elements/ButtonGroup'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
}

const Pagination = ({ currentPage, totalPages, onPageChange }: Props) => {
  return (
    <Stack justify="apart">
      <ButtonGroup>
        <Button
          a11yLabel="First page"
          appearance="outline"
          disabled={currentPage === 1}
          iconLeft={faAngleDoubleLeft}
          onClick={() => onPageChange(1)}
        />
        <Button
          a11yLabel="Previous page"
          appearance="outline"
          disabled={currentPage === 1}
          iconLeft={faAngleLeft}
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        />
      </ButtonGroup>
      <Body weight="medium">
        Page {currentPage} of {totalPages}
      </Body>
      <ButtonGroup>
        <Button
          a11yLabel="Next page"
          appearance="outline"
          disabled={currentPage === totalPages}
          iconLeft={faAngleRight}
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        />
        <Button
          a11yLabel="Last page"
          appearance="outline"
          disabled={currentPage === totalPages}
          iconLeft={faAngleDoubleRight}
          onClick={() => onPageChange(totalPages)}
        />
      </ButtonGroup>
    </Stack>
  )
}

export default Pagination
