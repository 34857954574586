import { format } from 'date-fns'
import { useMemo } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'

import { getShiftIntervals } from './util'

type Props = {
  job: GetJobQuery['job']
}

const ShiftsCard = ({ job: { shifts } }: Props) => {
  const shiftIntervals = useMemo(() => getShiftIntervals(shifts), [shifts])

  return (
    <Card sectioned title="Schedule">
      <Stack vertical gap={20}>
        {shiftIntervals.map((interval, index) => (
          <Stack key={index} vertical gap={2}>
            <Body>
              {format(interval[0].startAt, 'PP')} &mdash;{' '}
              {format(interval[interval.length - 1].startAt, 'PP')}
            </Body>
            <Small>
              {format(interval[0].startAt, 'p')} &mdash;{' '}
              {format(interval[interval.length - 1].endAt, 'p')}
            </Small>
          </Stack>
        ))}
      </Stack>
    </Card>
  )
}

export default ShiftsCard
