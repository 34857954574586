import { useState } from 'react'
import useModal from '@area2k/use-modal'

import { GetCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import { Body } from '@/components/Typography'
import ChangePaymentModal from './ChangePaymentModal'

type Props = {
  customer: GetCustomerQuery['customer']
}

const PaymentSection = ({ customer }: Props) => {
  const [paymentMethod, setPaymentMethod] = useState('Invoice')

  const callbacks = (s) => {
    if (s === 'invoice') {
      setPaymentMethod('Invoice')
    } else if (s === 'card') {
      setPaymentMethod('Credit Card: *********453')
    }
  }

  const [showChangeModal, hideChangeModal] = useModal(
    () => (
      <ChangePaymentModal hideModal={hideChangeModal} callback={callbacks} />
    ),
    []
  )

  return (
    <Card.Section title="Payment">
      <Body>{paymentMethod}</Body>
    </Card.Section>
  )
}

export default PaymentSection
