import { useCallback, useState } from 'react'

import { GetAccountQuery, OrderListItemFragment } from '@/types/graphql'

import Table from '@/components/Table'

import Card from '@/components/Card'
import LoadingState from '@/components/LoadingState'
import OrderTableItem from '@/components/OrderTableItem'
import Pagination from '@/components/Pagination'
import TableHeader from '@/components/TableHeader'
import QueryEmptyState from '@/components/QueryEmptyState'

import { useGetAccountOrdersQuery } from '@/graphql'

type Props = {
  account: GetAccountQuery['account']
}

const OrdersList = ({ account }: Props) => {
  const [page, setPage] = useState(1)

  const query = useGetAccountOrdersQuery({
    variables: {
      accountId: account.id,
      page,
      perPage: 10,
    },
  })

  const renderRow = useCallback(
    (item: OrderListItemFragment) => (
      <OrderTableItem item={item} to={`../../../../orders/${item.id}`} />
    ),
    []
  )

  if (!query.data) {
    return (
      <Card title="Orders">
        <LoadingState />
      </Card>
    )
  }

  const { orders } = query.data.account

  return (
    <Card title="Orders">
      <Table>
        <TableHeader fields={['ID', 'Timeframe', 'Size']} />
        <tbody>{orders.items.map(renderRow)}</tbody>
      </Table>
      {orders.items.length == 0 && (
          <QueryEmptyState
            query={query}
            title="No orders created"
            text="If there were any, they would be here."
          />
        )}
      {orders.items.length > 0 && (
        <Card.Section subdued>
          <Pagination
            currentPage={page}
            totalPages={orders.pageInfo.totalPages}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  )
}

export default OrdersList
