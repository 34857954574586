import useModal from '@area2k/use-modal'
import {
  faBan,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import { useCallback, useState } from 'react'

import { GetJobQuery, JobWorkerStatusEnum } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import TextStack from '@/components/TextStack'
import { Body, Small, Subheading } from '@/components/Typography'

import AddWorkerModal from './AddWorkerModal'

import styled from '@/styles'
import { JobStatus } from '../util'
import NewOfferModal from '../JobOfferList/NewOfferModal'
import JobOffersList from '../JobOfferList'
import Stack from '@/components/Stack'
import FieldSet from '@/components/groupBox'
import Icon from '@/elements/Icon'
import { useWorkerDismissMutation } from '@/graphql'

type JobWorkerItem = GetJobQuery['job']['jobWorkers'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const Separator = styled('div', {
  width: '95%',
  height: 1,
  backgroundColor: '$neutralLightest',
  margin: '5px 0px',
  alignSelf: 'center',
})

const DropDown = styled('button', {
  all: 'unset',
  alignItems: 'center',
  display: 'flex',
  height: 24,
  justifyContent: 'space-between',
  padding: '5px 20px 10px',

  backgroundColor: 'transparent',

  cursor: 'pointer',
  outline: 'none',

  textDecoration: 'none',

  '@phoneOnly': {
    gap: 10,
  },

  '&:hover': {
    backgroundColor: '$whiteA8',
  },
})

const WorkerList = ({ job, jobStatus }: Props) => {
  const [dropDown, setDropDown] = useState<boolean>(false)

  const [workerDismiss] = useWorkerDismissMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          jobWorkers() {},
        },
      })
    },
  })

  const dismissSubmit = async (jobWorkerId: JobWorkerItem['id']) => {
    try {
      await workerDismiss({
        variables: {
          jobWorkerId: jobWorkerId,
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const [showAddModal, hideAddModal] = useModal(
    () => <AddWorkerModal jobId={job.id} hideModal={hideAddModal} />,
    [job.id]
  )

  const [showOfferModal, hideOfferModal] = useModal(
    () => <NewOfferModal jobId={job.id} hideModal={hideOfferModal} />,
    [job.id]
  )

  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const jobWorkers = job.jobWorkers ? job.jobWorkers : []
  const workersCancel = jobWorkers.filter(
    (worker) => worker.status == JobWorkerStatusEnum.DROPPED
  )

  const workersHired = jobWorkers.filter(
    (worker) => worker.status == JobWorkerStatusEnum.HIRED
  )

  const renderQuitedWorkers = (items: JobWorkerItem[]) =>
    items.map(({ worker, dropReason }: JobWorkerItem, index) => (
      <>
        <Stack>
          <Stack gap={25} wrap>
            <Stack gap={15} inline>
              <Stack inline>
                <Avatar size="md" src={worker.avatarUrl} />
              </Stack>
              <Stack>
                <TextStack>
                  <Body weight="medium">
                    {worker.user.firstName} {worker.user.lastName}
                  </Body>
                  <Small>{worker.user.email}</Small>
                </TextStack>
              </Stack>
            </Stack>
            <FieldSet label="Reason" a11yLabel="reason" rounded>
              <TextStack>{dropReason}</TextStack>
            </FieldSet>
          </Stack>
        </Stack>
        {index == items.length && (
          <Stack justify="center">
            <Separator />
          </Stack>
        )}
      </>
    ))

  const renderItem = useCallback(
    ({ id, worker }: JobWorkerItem) => {
      return (
        <Stack vertical>
          <ResourceList.Item
            key={id}
            media={<Avatar size="sm" src={worker.avatarUrl} />}
            to={`../../../../workers/${worker.id}`}
            size="full"
            actions={
              isJobActive && [
                {
                  a11yLabel: 'Dismiss this worker',
                  dangerous: true,
                  label: 'Dismiss',
                  icon: faBan,
                  onAction: () => dismissSubmit(id),
                },
              ]
            }
          >
            <Stack>
              <TextStack>
                <Body weight="medium">
                  {worker.user.firstName} {worker.user.lastName}
                </Body>
                <Small>{worker.user.email}</Small>
              </TextStack>
            </Stack>
          </ResourceList.Item>
        </Stack>
      )
    },
    [isJobActive]
  )

  return (
    <Card
      actions={
        isJobActive
          ? [
              {
                a11yLabel: 'Invite worker to this job',
                label: 'Invite Worker',
                onAction: showOfferModal,
              },
              {
                a11yLabel: 'Hire worker to this job',
                label: 'Hire worker',
                onAction: showAddModal,
              },
            ]
          : undefined
      }
      title="Hired workers"
      sizeheight="xl"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No hired workers"
            text={
              isJobActive
                ? 'Add a worker to this job above or via invite below.'
                : 'This job was unfilled.'
            }
          />
        }
        items={workersHired}
        renderItem={renderItem}
        totalItemCount={workersHired.length}
      />
      <Stack justify="center">
        <Separator />
      </Stack>

      {workersCancel.length > 0 && (
        <>
          <Stack>
            <DropDown onClick={() => setDropDown(!dropDown)}>
              <Stack gap={5}>
                <Subheading size="md">Workers who have dropped</Subheading>
                <Icon
                  fixedWidth
                  icon={dropDown ? faChevronDown : faChevronUp}
                />
              </Stack>
            </DropDown>
          </Stack>
          {dropDown && (
            <Card.Section>{renderQuitedWorkers(workersCancel)}</Card.Section>
          )}
        </>
      )}

      <JobOffersList job={job} jobStatus={jobStatus} />
    </Card>
  )
}
export default WorkerList
