import { ApolloProvider } from '@apollo/client'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ModalProvider } from '@area2k/use-modal'
import { ModalProvider as ReactModalHookProvider } from 'react-modal-hook'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { store } from '@/store'

import client from '@/util/apollo/client'
import Config from '@/config'
import RouterDebug from '@/components/RouterDebug'
import AppRouter from '@/routes'

Sentry.init({
  dsn: Config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: Config.ENVIRONMENT,
  release: Config.BUILD_COMMIT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
})

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <Router>
          <ModalProvider>
            <ReactModalHookProvider>
              <RouterDebug />
              <AppRouter />
            </ReactModalHookProvider>
          </ModalProvider>
        </Router>
      </ReduxProvider>
    </ApolloProvider>
  )
}

export default App
