import useModal from '@area2k/use-modal'

import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import ChangeAddressModal from './ChangeAddressModal'
import Link from '@/elements/Link'
import { mapUrl } from '@/routes/Agency/Customer/AddressList'
import Icon from '@/elements/Icon'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const AddressSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(() => (
    <ChangeAddressModal worker={worker} hideModal={hideChangeModal} />
  ))

  return (
    <Card.Section
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this worker's address",
                label: 'Change',
                onAction: showChangeModal,
              },
            ]
          : undefined
      }
      title="Address"
    >
      <TextStack>
        <Link
          as="a"
          href={mapUrl(worker)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {worker.addressLine1}{' '}
          <Small color="inherit">
            <Icon icon={faExternalLinkAlt} />
          </Small>
        </Link>
        {worker.addressLine2 && (
          <Body color="light">{worker.addressLine2}</Body>
        )}
        <Body color="light">
          {worker.city}, {worker.state} {worker.zip}
        </Body>
      </TextStack>
    </Card.Section>
  )
}

export default AddressSection
