import { Dispatch, SetStateAction, useMemo } from 'react'

import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import Button from '@/components/Button'
import Stack from '@/components/Stack'
import Tag from '@/components/Tag'
import { oneHundredYearsAgo } from '..'
import { OrderStatus } from './index'

type TagControl = {
  label: string
  onRemove: () => void
}

type Props = {
  filters: OrderFilterSetInput
  onChangeFilter: <K extends keyof OrderFilterSetInput>(
    key: K,
    value: NonNullable<OrderFilterSetInput[K]>
  ) => void
  onClearAll: () => void
  onClearFilter: (key: keyof OrderFilterSetInput) => void
  orderStatusValue: OrderStatus
  setOrderStatusValue: Dispatch<SetStateAction<OrderStatus>>
}

const operatorToString = (op: CompareOperatorEnum) => {
  switch (op) {
    case CompareOperatorEnum.LT:
      return 'is less-than'
    case CompareOperatorEnum.LTEQ:
      return 'is less-than or equal to'
    case CompareOperatorEnum.EQ:
      return 'is'
    case CompareOperatorEnum.GTEQ:
      return 'is greater-than or equal to'
    case CompareOperatorEnum.GT:
      return 'is greater-than'
    case CompareOperatorEnum.NOT:
      return 'is not'
  }
}

const TagDisplay = ({
  filters,
  onClearAll,
  onChangeFilter,
  onClearFilter,
  orderStatusValue,
  setOrderStatusValue,
}: Props) => {
  const payloadStartAtAll = {
    op: CompareOperatorEnum.GT,
    value: oneHundredYearsAgo,
  }

  const onAllOptionSelected = () => {
    onClearFilter('endAt')
    onChangeFilter('startAt', payloadStartAtAll)
    return OrderStatus.ALL
  }

  const clearStatusFilter = () => setOrderStatusValue(onAllOptionSelected)

  const tagControls = useMemo<TagControl[]>(() => {
    const controls: TagControl[] = []

    const orderIsIn = 'Order is in'
    const control: TagControl = {
      [OrderStatus.UPCOMING]: {
        label: `${orderIsIn} upcoming`,
        onRemove: clearStatusFilter,
      },
      [OrderStatus.IN_PROGRESS]: {
        label: `${orderIsIn} progress`,
        onRemove: clearStatusFilter,
      },
      [OrderStatus.PAST]: {
        label: `${orderIsIn} past`,
        onRemove: clearStatusFilter,
      },
    }[orderStatusValue]

    control && controls.push(control)

    Object.keys(filters).forEach((key) => {
      switch (key) {
        case 'jobsCount':
          const value = filters.jobsCount!
          controls.push({
            label: `Job count ${operatorToString(value.op)} ${value.value}`,
            onRemove: () => onClearFilter('jobsCount'),
          })
          break
      }
    })

    return controls
  }, [filters])

  if (tagControls.length === 0) {
    return null
  }

  return (
    <Stack gap={16}>
      <div style={{ flex: 1 }}>
        <Stack>
          {tagControls.map(
            (tagControl, index) =>
              tagControl && (
                <Tag
                  key={index}
                  label={tagControl.label}
                  onRemove={tagControl.onRemove}
                />
              )
          )}
        </Stack>
      </div>
      <Button
        a11yLabel="Clear all filters"
        appearance="plain"
        label="Clear all"
        onClick={() => {
          onClearAll()
          clearStatusFilter()
        }}
      />
    </Stack>
  )
}

export default TagDisplay
