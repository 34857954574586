import { GetOrderQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'

import { Body, Small, Subheading } from '@/components/Typography'
import { centsToCurrency, zeroPad } from '@/util/number'
import {
  calculateTotalShiftsByJobs,
  calculateTotalEstimateByJobs,
} from '@/util/job'

type OrderItem = GetOrderQuery['order']
type Props = {
  order: OrderItem
}

const SummaryCard = ({ order: { jobs } }: Props) => {
  const totalShifts = calculateTotalShiftsByJobs(jobs)
  const totalEstimate = calculateTotalEstimateByJobs(jobs)

  return (
    <Card title="Summary">
      <Card.Section>
        <Stack vertical gap={12}>
          <Stack justify="apart">
            <Small>Total jobs</Small>
            <Body>{zeroPad(jobs.length, 2)}</Body>
          </Stack>
          <Stack justify="apart">
            <Small>Total schedule shifts</Small>
            <Body>{zeroPad(totalShifts, 2)}</Body>
          </Stack>
        </Stack>
      </Card.Section>
      <Card.Section>
        <Stack justify="apart">
          <Body>Total estimate</Body>
          <Subheading color="theme">
            ${centsToCurrency(totalEstimate)}
          </Subheading>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default SummaryCard
