import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarFilled } from '@fortawesome/free-solid-svg-icons'
import { useField } from '@area2k/use-form'
import { useState } from 'react'

import styled from '@/styles'

import FormElement from '@/components/FormElement'
import Stack from '@/components/Stack'
import Icon from '@/elements/Icon'
import { Body } from '@/components/Typography'

const StarWrapper = styled('div', {
  $$hoverColor: '$colors$warningDefault',
  $$selectedColor: '$colors$warningDefault',

  cursor: 'pointer',

  fontSize: '$xl',

  variants: {
    isHovered: {
      true: {
        color: '$$hoverColor',
      },
      false: {},
    },
    isSelected: {
      true: {
        color: '$$selectedColor',
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      isHovered: true,
      isSelected: true,
      css: {
        color: '$$selectedColor',
      },
    },
  ],
})

type StarProps = {
  index: number
  hoverIndex: number | null
  selectedIndex: number | null
  onHover: (index: number | null) => void
  onSelect: (index: number) => void
}

const Star = ({
  index,
  hoverIndex,
  selectedIndex,
  onHover,
  onSelect,
}: StarProps) => {
  const isSelected = !!selectedIndex && index <= selectedIndex
  const isHovered = !!hoverIndex && index <= hoverIndex

  return (
    <StarWrapper
      isHovered={isHovered}
      isSelected={isSelected}
      onClick={() => onSelect(index)}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onHover(null)}
    >
      <Icon icon={isSelected ? faStarFilled : faStarEmpty} />
    </StarWrapper>
  )
}

type Props = {
  fieldId: string
}

const RatingField = ({ fieldId }: Props) => {
  const { value, setValue } = useField<number | null>(fieldId)

  const [hoverIndex, setHoverIndex] = useState<number | null>(null)

  return (
    <FormElement>
      <Stack vertical align="center">
        <Body>Worker rating</Body>
        <Stack inline gap={16}>
          <Star
            index={1}
            hoverIndex={hoverIndex}
            selectedIndex={value}
            onHover={setHoverIndex}
            onSelect={setValue}
          />
          <Star
            index={2}
            hoverIndex={hoverIndex}
            selectedIndex={value}
            onHover={setHoverIndex}
            onSelect={setValue}
          />
          <Star
            index={3}
            hoverIndex={hoverIndex}
            selectedIndex={value}
            onHover={setHoverIndex}
            onSelect={setValue}
          />
          <Star
            index={4}
            hoverIndex={hoverIndex}
            selectedIndex={value}
            onHover={setHoverIndex}
            onSelect={setValue}
          />
          <Star
            index={5}
            hoverIndex={hoverIndex}
            selectedIndex={value}
            onHover={setHoverIndex}
            onSelect={setValue}
          />
        </Stack>
      </Stack>
    </FormElement>
  )
}

export default RatingField
