import { useCallback, useState } from 'react'
import { FieldContext } from '@area2k/use-form'
import { Maybe } from '@/types'
import { GetCustomerQuery, ListAgencySkillsQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import ItemSelectField from '@/form/ItemSelectField'

import { useCreateRateMutation, useListAgencySkillsQuery } from '@/graphql'
import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'
import TextField from '@/form/TextField'
import FormColumns from '@/form/FormColumns'
import Input from '@/elements/Input'
import FormElement from '@/components/FormElement'
import MaskedInput from '@/components/MaskedInput'
import { centsToCurrency } from '@/util/number'

type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

type SkillItem = ListAgencySkillsQuery['agency']['skillCategories'][0]['skills'][0]
type AddressItem = GetCustomerQuery['customer']['addresses'][0]
type AccountItem = GetCustomerQuery['customer']['accounts'][0]

type FormValues = {
  skill: Maybe<SkillItem>
  address: Maybe<AddressItem>
  account: Maybe<AccountItem>
  payRate: number
  billingRate: number
}

const MIN_PAY = 400

const AddCustomeRateModal = ({ customer, hideModal }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)

  const { data: agencyData } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const [createRate, { loading }] = useCreateRateMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: { addresses() {} },
      })
    },
  })

  const skillCategories = agencyData ? agencyData.agency.skillCategories : []
  const skillItems: SkillItem[] = skillCategories.flatMap(
    (category) => category.skills
  )

  const [formValues, setFormValues] = useState<FormValues>({
    skill: null,
    account: null,
    address: null,
    payRate: MIN_PAY,
    billingRate: MIN_PAY * 1.58,
  })

  const [payRateInput, setPayRateInput] = useState(
    centsToCurrency(formValues.payRate)
  )

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handlePayRateChange = useCallback((payRateInput: string) => {
    setPayRateInput(payRateInput)
    const payRate = Number(payRateInput) * 100

    setFormValues((prevValues) => ({ ...prevValues, payRate }))
  }, [])

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await createRate({
          variables: {
            agencyId: currentAgency!.id,
            skillId: formValues.skill!.id,
            accountId: formValues.account!.id,
            addressId: formValues.address!.id,
            baseCost: formValues.payRate * 1.58,
            basePay: formValues.payRate,
          },
        })

        hideModal()
      } catch (err) {
        console.log(err)
      }
    },
    [formValues]
  )

  return (
    <Modal
      size="md"
      title={'Add custom rate'}
      onRequestClose={hideModal}
      disableClickout
    >
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <ItemSelectField<SkillItem>
            required
            fieldId="skill"
            items={skillItems}
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.name}` : '')}
            label="Job Skill*"
            placeholder="Select a skill..."
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'skill')
            }}
          />

          <ItemSelectField<AccountItem>
            required
            fieldId="account"
            items={customer.accounts}
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.name}` : '')}
            label="Account*"
            placeholder="Select an account..."
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'account')
            }}
          />

          <ItemSelectField<AddressItem>
            required
            fieldId="address"
            items={customer.addresses}
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.addressLine1}` : '')}
            label="Address*"
            placeholder="Select an address..."
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'address')
            }}
          />

          <FormColumns layout="triple">
            <FormElement label="City">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.city!
                    : 'Select an address first...'
                }
              />
            </FormElement>

            <FormElement label="Zipcode">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.zip!
                    : 'Select an address first...'
                }
              />
            </FormElement>

            <FormElement label="State">
              <Input
                disabled
                value={
                  formValues.address
                    ? formValues.address.state!
                    : 'Select an address first...'
                }
              />
            </FormElement>
          </FormColumns>

          <FormColumns layout="double">
            <FormElement label="Pay rate*">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                mask={Number}
                min={MIN_PAY / 100}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={payRateInput}
                onAccept={(value) => {
                  handlePayRateChange(value)
                }}
              />
            </FormElement>
            <FormElement label="Bill rate">
              <Input
                disabled
                value={`$${centsToCurrency(
                  Math.ceil(formValues.payRate * 1.58)
                )}`}
              />
            </FormElement>
          </FormColumns>

          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              disabled={
                !formValues.skill ||
                !formValues.account ||
                !formValues.address ||
                formValues.payRate === 0
              }
              isLoading={loading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default AddCustomeRateModal
