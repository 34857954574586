import { useCallback, useState } from 'react'
import { FieldContext } from '@area2k/use-form'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Maybe } from '@/types'

import Alert from '@/components/Alert'
import Button from '@/components/Button'
import SingleColumnLayout from '@/components/SingleColumnLayout'
import Stack from '@/components/Stack'
import { Heading } from '@/components/Typography'

import Form from '@/form'

import AddressSection from './AddressSection'
import BottomBar from '../BottomBar'
import InstructionsSection from './InstructionsSection'
import Layout from '../Layout'
import UniformSection from './UniformSection'

import {
  Address,
  Step,
  Uniform,
  useJobDraftActions,
  useJobDraftState,
} from '../../context'

type Props = {
  setStep: (step: Step) => void
}

export type FormValues = {
  address: Maybe<Address>
  addressInstructions: string
  instructions: string
  uniform: Maybe<Uniform>
  uniformInstructions: string
}

const DetailsStep = ({ setStep }: Props) => {
  const {
    uniform,
    uniformInstructions,
    instructions,
    address: addressInContext,
    addressInstructions,
    skill,
  } = useJobDraftState()
  const { updateDetails, updateSchedules } = useJobDraftActions()

  const [formValues, setFormValues] = useState<FormValues>({
    uniform,
    uniformInstructions,
    instructions,
    address: addressInContext,
    addressInstructions,
  })

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handleSubmit = useCallback(
    async ({
      address,
      addressInstructions,
      instructions,
      uniform,
      uniformInstructions,
    }: FormValues) => {
      if (addressInContext) {
        if (addressInContext.id !== address!.id) {
          updateSchedules({ schedules: [] })
        }
      }

      updateDetails({
        address: address!,
        addressInstructions,
        instructions,
        uniform: uniform!,
        uniformInstructions,
        contact: null,
        contactInstructions: '',
        rate: null,
      })
      return setStep(Step.SCHEDULE)
    },
    []
  )

  return (
    <Layout>
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <SingleColumnLayout size="md">
          <Stack vertical gap={24}>
            <Heading>Add some instructions</Heading>
            <UniformSection
              handleUniformChange={handleFormValuesChange}
            />
            <InstructionsSection
              handleInstructionsChange={handleFormValuesChange}
            />

            {addressInContext && (
              <Stack>
                <Alert
                  title="WARNING"
                  description="if you change your selected address, you will have to redo the step 3 from here"
                  icon={faExclamationTriangle}
                  status="warning"
                  css={{ width: '100%' }}
                />
              </Stack>
            )}

            <AddressSection
              hasInstructions={formValues.addressInstructions !== ''}
              skill={skill!}
              handleAddressChange={handleFormValuesChange}
            />
            <BottomBar>
              <Button
                a11yLabel="Go back to previous step"
                appearance="outline"
                label="Back"
                type="button"
                onClick={() => setStep(Step.SKILL)}
              />
              <Button
                disabled={!formValues.uniform || !formValues.address}
                a11yLabel="Submit form"
                label="Continue"
                type="submit"
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  )
}

export default DetailsStep
