import { useCallback, useState } from 'react'

import { FieldContext, SubmitHelpers } from '@area2k/use-form'

import {
  useChangeStatusUserMutation,
  useChangeTenantAdminAvatarMutation,
  useGetUploadUrlQuery,
  useUpdateUserProfileMutation,
} from '@/graphql'

import { Maybe } from '@/types'
import { ListTenantAdminQuery } from '@/types/graphql'

import { handleMutationFormError } from '@/util/error'

import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Small } from '@/components/Typography'

import Form from '@/form'
import TextField from '@/form/TextField'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import OptionField from '@/form/OptionField'

import ChangeAvatarCard from '../ChangeAvatarCard'

export type Props = {
  admin: ListTenantAdminQuery['agency']['tenant']['admins'][0]
  hideModal: () => void
}

type FormValues = {
  active: Props['admin']['user']['active']
  firstName: Props['admin']['user']['firstName']
  lastName: Props['admin']['user']['lastName']
  password: Maybe<string>
  phoneNumber: Props['admin']['user']['phoneNumber']
}

const AdminUpdateProfileModal = ({ admin, hideModal }: Props) => {
  const initialValues: FormValues = {
    active: admin.user.active,
    firstName: admin.user.firstName,
    lastName: admin.user.lastName,
    password: null,
    phoneNumber: admin.user.phoneNumber,
  }

  const [formValues, setFormValues] = useState<FormValues>(initialValues)

  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [tenatAdminChangeAvatar] = useChangeTenantAdminAvatarMutation()

  const [updateTenantAdminProfile] = useUpdateUserProfileMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(admin),
        fields: { user() {} },
      })
    },
  })

  const [changeStatusUserMutation] = useChangeStatusUserMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(admin),
        fields: { user() {} },
      })
    },
  })

  const { refetch: getUploadUrl } = useGetUploadUrlQuery({ skip: true })

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      setIsLoading(true)

      try {
        if (file) {
          const { data } = await getUploadUrl({ filename: file.name })
          const { key, url } = data.uploadUrl

          await fetch(url, { method: 'PUT', body: file })

          await tenatAdminChangeAvatar({
            variables: { avatarKey: key, tenantAdminId: admin!.id },
          })
        }

        if (
          formValues.active === initialValues.active &&
          formValues.firstName === initialValues.firstName &&
          formValues.lastName === initialValues.lastName &&
          formValues.password === initialValues.password &&
          formValues.phoneNumber === initialValues.phoneNumber
        )
          return

        if (formValues.active !== initialValues.active) {
          await changeStatusUserMutation({
            variables: { userId: admin!.user.id, active: formValues.active },
          })
        }

        await updateTenantAdminProfile({
          variables: {
            userId: admin.user.id,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            password: formValues.password,
            phoneNumber: formValues.phoneNumber,
          },
        })
      } catch (err) {
        console.log(err)
        handleMutationFormError(err, { setFormError })
      } finally {
        setIsLoading(false)
        hideModal()
      }
    },
    [file, formValues]
  )

  return (
    <Modal title="Update profile" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns>
            <FormElement>
              <ChangeAvatarCard
                currentAvatarUrl={admin?.avatarUrl ?? null}
                item={admin}
                useFile={file}
                useSetFile={setFile}
              />
            </FormElement>
            <FormElement>
              <TextField
                autoFocus
                required
                fieldId="firstName"
                label="First Name"
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'firstName')
                }}
              />
              <TextField
                required
                fieldId="lastName"
                label="Last Name"
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'lastName')
                }}
              />
              <MaskedInputField
                fieldId="phoneNumber"
                incompletemessage="Must be a valid phone number"
                label="Phone Number"
                mask="(000) 000-0000"
                placeholder={admin.user.phoneNumber}
                type="tel"
                callback={(fieldContext) => {
                  handleFormValuesChange(fieldContext, 'phoneNumber')
                }}
              />
              <FormElement>
                {isOpen ? (
                  <>
                    <Button
                      a11yLabel="Hide input"
                      appearance="plain"
                      label="Hide new password input"
                      type="button"
                      onClick={() => {
                        setIsOpen(false)
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          password: null,
                        }))
                      }}
                    />
                    <TextField
                      css={{ letterSpacing: '2px' }}
                      autoComplete="password"
                      fieldId="password"
                      label="New password"
                      placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                      type="password"
                      callback={(fieldContext) => {
                        handleFormValuesChange(fieldContext, 'password')
                      }}
                    />
                    <Small>
                      The password must be have at least 8 characters long
                    </Small>
                  </>
                ) : (
                  <Button
                    a11yLabel="Click to Reset user password"
                    appearance="plain"
                    label="Reset user password (optional)"
                    type="button"
                    onClick={() => {
                      setIsOpen(true)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        password: null,
                      }))
                    }}
                  />
                )}
              </FormElement>
              <FormElement>
                <OptionField
                  appearance="switch"
                  fieldId="active"
                  label="Is eneabled"
                  callback={(fieldContext) => {
                    handleFormValuesChange(fieldContext, 'active')
                  }}
                />
              </FormElement>
            </FormElement>
          </FormColumns>
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              disabled={
                isOpen
                  ? (formValues.password && formValues.password === '') ||
                    formValues.password === null ||
                    formValues.password.length < 8
                  : false
              }
              label="Save"
              loadingLabel="Saving"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default AdminUpdateProfileModal
