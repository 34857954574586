import useModal from '@area2k/use-modal'

import Card from '@/components/Card'
import CommentsList from '@/components/CommentsList'
import AddCommentsModal from '@/modals/AddCommentModal'

import { GetWorkerQuery } from '@/types/graphql'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const Comments = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showAddModal, hideAddModal] = useModal(
    () => <AddCommentsModal worker={worker} hideModal={hideAddModal} />,
    [worker]
  )

  return (
    <Card
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Add a comment about the worker',
                label: 'Add',
                onAction: showAddModal,
              },
            ]
          : undefined
      }
      title="Comments"
    >
      <CommentsList worker={worker} />
    </Card>
  )
}

export default Comments
