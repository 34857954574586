import React from 'react'

import styled from '@/styles'

import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'

import { SkillCategory, Skill } from '../../context'

const DEFAULT_IMAGE_SIZE = 90

const Wrapper = styled('div', {
  padding: '0.5rem',
  height: DEFAULT_IMAGE_SIZE + 65,
})

const Image = styled('img', {
  height: DEFAULT_IMAGE_SIZE,
  width: DEFAULT_IMAGE_SIZE,
})

const Text = styled(TextStack, {
  height: '100%',
  width: DEFAULT_IMAGE_SIZE,
  marginTop: '0.5rem',
  textAlign: 'center',
})

type Props = {
  item: SkillCategory | Skill
}

const SkillItem = ({ item }: Props) => (
  <Wrapper>
    <Stack
      justify="apart"
      align="center"
      vertical
      gap={0}
      css={{
        width: DEFAULT_IMAGE_SIZE,
        height: '100%',
      }}
    >
      <Image
        src={
          item.imageUrl ??
          `http://placehold.it/${DEFAULT_IMAGE_SIZE}x${DEFAULT_IMAGE_SIZE}`
        }
      />

      <Text align="center" justify="center">
        {item.name}
      </Text>
    </Stack>
  </Wrapper>
)

export default SkillItem
