import TextAreaField from '@/form/TextAreaField'
import { FieldContext } from '@area2k/use-form'
import { FormValues } from '.'

type Props = {
  handleInstructionsChange: <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => void
}

const InstructionsSection = ({ handleInstructionsChange }: Props) => {
  return (
    <div style={{ width: '100% ' }}>
      <TextAreaField
        fieldId="instructions"
        label="Add instructions & additional details"
        placeholder="More information makes a better employee selection..."
        callback={(fieldContext) => {
          handleInstructionsChange(fieldContext, 'instructions')
        }}
      />
    </div>
  )
}

export default InstructionsSection
