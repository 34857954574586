import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'

import WorkerAutocompleteField, {
  WorkerItem,
} from '../../WorkerAutocompleteField'

import { handleMutationFormError } from '@/util/error'

import { JobOfferItemFragmentDoc, useCreateJobOfferMutation } from '@/graphql'

export type Props = {
  jobId: string
  hideModal: () => void
}

type FormValues = {
  worker: WorkerItem | null
}

const NewOfferModal = ({ jobId, hideModal }: Props) => {
  const initialValues: FormValues = {
    worker: null,
  }

  const [createJobOffer, { loading: isLoading }] = useCreateJobOfferMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Job:${jobId}`,
        fields: {
          jobOffers(existingRefs = [], { readField }) {
            if (!data) return existingRefs
            const { jobOffer } = data.jobOfferCreate

            if (
              existingRefs.some((ref) => readField('id', ref) === jobOffer.id)
            ) {
              return existingRefs
            }

            const newOfferRef = cache.writeFragment({
              fragment: JobOfferItemFragmentDoc,
              data: jobOffer,
            })

            return [...existingRefs, newOfferRef]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await createJobOffer({
          variables: { jobId, workerId: values.worker!.id },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            ALREADY_HIRED: () => ({
              title: 'Worker already hired',
              message: 'This worker has already been hired for this job.',
            }),
            ALREADY_OFFERED: () => ({
              title: 'Offer already sent',
              message: 'This worker has already been offered this job.',
            }),
          },
        })
      }
    },
    [jobId]
  )

  return (
    <Modal size="sm" title="Offer job to worker" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <WorkerAutocompleteField fieldId="worker" label="Select worker" />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Offer"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default NewOfferModal
