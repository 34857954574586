import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import qs from 'query-string'

import { useGetCurrentActorQuery } from '@/graphql'

import LoadingState from '@/components/LoadingState'

import { currentAdminVar } from '@/util/apollo/cache'
import ls, { DEVICE_TOKEN_KEY } from '@/util/localstorage'

const LoginRedirect = ({ didFailAuth }: { didFailAuth: boolean }) => {
  const location = useLocation()

  ls.clearAuth()

  return (
    <Navigate
      replace
      state={{ didFailAuth, didReroute: true }}
      to={{
        pathname: '/login',
        search: `?${qs.stringify({ to: location.pathname })}`,
      }}
    />
  )
}

const Authenticated = () => {
  const deviceToken = ls.get(DEVICE_TOKEN_KEY)
  const query = useGetCurrentActorQuery({ skip: !deviceToken })

  useEffect(() => {
    if (query.data) {
      switch (query.data.currentActor.__typename) {
        case 'TenantAdmin':
          const { currentActor: currentTenantAdminActor } = query.data
          currentAdminVar(currentTenantAdminActor)
          break

        case 'CustomerAdmin':
          const { currentActor: currentCustomerAdminActor } = query.data
          currentAdminVar(currentCustomerAdminActor)
          break

        default:
          currentAdminVar(null)
      }
    }

    return () => {
      currentAdminVar(null)
    }
  }, [query.data])

  if (!deviceToken || query.error) {
    return <LoginRedirect didFailAuth={!!query.error} />
  }

  if (query.loading) {
    return <LoadingState text="Loading your account information..." />
  }

  return <Outlet />
}

export default Authenticated
