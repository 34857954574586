import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useGetTenantByKeyQuery } from '@/graphql'

import LoadingState from '@/components/LoadingState'

import { currentTenantVar } from '@/util/apollo/cache'

const subdomain = window.location.hostname.split('.')[0]

const Root = () => {
  const query = useGetTenantByKeyQuery({ variables: { key: subdomain }})

  useEffect(() => {
    if (query.data) {
      currentTenantVar(query.data.tenantByKey)
    }

    return () => { currentTenantVar(null) }
  }, [query.data])

  if (query.loading) {
    return <LoadingState text='Loading the application...' />
  }

  if (query.error || query.data && !query.data.tenantByKey) {
    return <Navigate replace to='/invalid-tenant' />
  }

  return <Outlet />
}

export default Root
