import { useMemo } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import useModal from '@area2k/use-modal'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { useReactiveVar } from '@apollo/client'

import { Role } from '@/routes/PrivateRoute'
import ROUTES from '@/routes/routes'
import { currentAdminVar } from '@/util/apollo/cache'

import { useGetWorkerQuery } from '@/graphql'
import { CandidateStatusEnum, WorkerStatusEnum } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Page from '@/components/Page'

import PageSkeleton from './PageSkeleton'

import useChangeAvatarModal from './useChangeAvatarModal'
import WorkerCompleted from './WorkerCompleted'
import WorkerInProgress from './WorkerInProgress'
import UpdatePasswordToWorkerModal, {
  WorkerItem,
} from './modals/UpdatePasswordToWorkerModal'

const Worker = () => {
  const { workerId } = useParams()

  const filterDate = useMemo(() => new Date().toISOString(), [])
  const query = useGetWorkerQuery({
    variables: { filterDate, workerId },
  })

  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN

  const showAvatarModal = useChangeAvatarModal(query.data?.worker)
  const [showPasswordModal, hidePasswordModal] = useModal(
    (worker: WorkerItem) => (
      <UpdatePasswordToWorkerModal
        hideModal={hidePasswordModal}
        worker={worker}
      />
    ),
    [query.data]
  )

  if (!query.data) {
    return <PageSkeleton />
  }

  const worker = query.data.worker

  return (
    <Page
      media={
        <Avatar
          firstName={worker.user.firstName}
          size="md"
          src={worker.avatarUrl}
        />
      }
      primaryAction={
        !currentAdminIsCustomerAdmin
          ? {
              a11yLabel: 'Change avatar of worker',
              label: 'Change Avatar',
              icon: faCamera,
              onAction: showAvatarModal,
            }
          : undefined
      }
      title={`${worker.user.firstName} ${worker.user.lastName}`}
      secondaryActions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Change Password',
                label: 'Change password',
                onAction: () => showPasswordModal(worker),
              },
            ]
          : undefined
      }
    >
      {worker.candidateStatus === CandidateStatusEnum.START_HIRE &&
      worker.status !== WorkerStatusEnum.HIRED ? (
        currentAdminIsCustomerAdmin ? (
          <Navigate to={`${ROUTES.basePath}`} />
        ) : (
          <WorkerInProgress worker={worker} />
        )
      ) : (
        <WorkerCompleted
          worker={worker}
          currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
        />
      )}
    </Page>
  )
}

export default Worker
