import { SubmitHelpers } from '@area2k/use-form'
import { useCallback, useState } from 'react'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { GetCustomerQuery } from '@/types/graphql'
import { useInviteAdminMutation } from '@/graphql'

import Alert from '@/components/Alert'
import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextField from '@/form/TextField'

export type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

type FormValues = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

const InviteAdminModal = ({ customer, hideModal }: Props) => {
  const [error, setError] = useState({
    active: false,
    data: {},
  })

  const initialValues: FormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  }

  const [inviteAdmin, { loading: isLoading }] = useInviteAdminMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          admins() {},
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await inviteAdmin({
          variables: {
            customerId: customer.id,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            password: null,
          },
        })
        hideModal()
      } catch (err) {
        setError({ active: true, data: err })
      }
    },
    []
  )

  return (
    <Modal
      size="sm"
      title="Invite a new administrator"
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error.active && (
          <Alert
            description={error.data.message}
            icon={faExclamationTriangle}
            title="Something went wrong"
            status="warning"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
            />
          </FormColumns>
          <TextField
            required
            autoComplete="email"
            fieldId="email"
            label="New administrator email"
            placeholder="Email address"
            type="email"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Send Invite"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default InviteAdminModal
