import { useCallback } from 'react'
import useModal from '@area2k/use-modal'
import { faExternalLinkAlt, faPen } from '@fortawesome/free-solid-svg-icons'
import qs from 'query-string'

import { GetCustomerQuery } from '@/types/graphql'

import ActiveCard from '@/components/ActiveCard'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Small } from '@/components/Typography'

import Icon from '@/elements/Icon'
import Link from '@/elements/Link'

import CreateAddressModal from './CreateAddressModal'
import UpdateAddressModal from './UpdateAddressModal'

type Item = GetCustomerQuery['customer']['addresses'][0]

type Props = {
  customer: GetCustomerQuery['customer']
}

export const mapUrl = (item: Item) => {
  const params = {
    query: `${item.addressLine1},${item.city},${item.state}`,
  }
  return `https://www.google.com/maps/search/?api=1&${qs.stringify(params)}`
}

const AddressList = ({ customer }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAddressModal customer={customer} hideModal={hideCreateModal} />
    ),
    [customer]
  )

  const [showUpdateModal, hideUpdateModal] = useModal<Item>(
    (address) => (
      <UpdateAddressModal address={address} hideModal={hideUpdateModal} />
    ),
    [customer]
  )

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        to={'.'}
        actions={[
          {
            a11yLabel: 'Edit address',
            icon: faPen,
            onAction: () => showUpdateModal(item),
          },
        ]}
      >
        <Stack>
          <TextStack>
            <Link
              as="a"
              href={mapUrl(item)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.addressLine1}{' '}
              <Small color="inherit">
                <Icon icon={faExternalLinkAlt} />
              </Small>
            </Link>
            {item.addressLine2 && <Small>{item.addressLine2}</Small>}
            <Small>
              {item.city}, {item.state} {item.zip}
            </Small>
          </TextStack>
        </Stack>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <ActiveCard array={customer.accounts}>
      <Card
        actions={[
          {
            a11yLabel: 'Create a new address',
            label: 'Create',
            onAction: showCreateModal,
          },
        ]}
        title="Addresses"
      >
        <ResourceList
          emptyState={
            <EmptyState
              title="No customer addresses"
              text="If there were any, they would be here"
            />
          }
          items={customer.addresses}
          renderItem={renderItem}
        />
      </Card>
    </ActiveCard>
  )
}

export default AddressList
