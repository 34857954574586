import { StitchesVariants } from '@stitches/react'
import { ComponentPropsWithoutRef } from 'react'

import styled from '@/styles'

import Cover from '@/elements/Cover'
import Input from '@/elements/Input'

const FocusCover = styled(Cover, {
  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$lg',

  focusPseudoElement: {
    element: 'after',
    activator: 'textarea:focus + &',
    borderWidth: 1
  }
})

const Wrapper = styled('div', {
  display: 'flex',

  [`> ${Input}`]: {
    paddingBottom: 8,
    paddingTop: 8,
    resize: 'none',

    background: 'none',
    borderColor: 'transparent',

    lineHeight: 1.5,

    zIndex: 2
  }
})

type Props = ComponentPropsWithoutRef<'textarea'> & StitchesVariants<typeof Input>

const TextArea = (props: Props) => {
  return (
    <Wrapper>
      <Input
        as='textarea'
        rows={4}
        {...props}
      />
      <FocusCover />
    </Wrapper>
  )
}

export default TextArea
