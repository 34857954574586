import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import Form from '@/form'
import TextAreaField from '@/form/TextAreaField'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

import { handleMutationFormError } from '@/util/error'

type Props = {
  hideModal: () => void
  onConfirm: (values: FormValues) => void
}

type FormValues = {
  reason: string
}

const PostSubmitModal = ({ hideModal, onConfirm }: Props) => {
  const initialValues: FormValues = { reason: '' }

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        onConfirm(values)
      } catch (err) {
        handleMutationFormError(err, { setFormError })
      }
    },
    []
  )

  return (
    <Modal size="sm" title="Cancel Job" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical verticalGap={13}>
          <Body>Are you sure you want to cancel the job?</Body>
          <Form
            style={{ width: '100%' }}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <TextAreaField
              autoFocus
              required
              fieldId="reason"
              placeholder="I want to cancel the job because..."
            />
            <Stack justify="end">
              <Button a11yLabel="Cancel" label="Cancel Job" type="submit" />
            </Stack>
          </Form>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default PostSubmitModal
