import Page from '@/components/Page'

import ShiftWeekList from './ShiftWeekList'

const Dashboard = () => {
  return (
    <Page
      primaryAction={{
        a11yLabel: 'Go to create order',
        label: 'Place new order',
        to: 'orders/create',
      }}
      size="xl"
      title="Dashboard"
    >
      <ShiftWeekList />
    </Page>
  )
}

export default Dashboard
