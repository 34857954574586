import { useEffect, useState } from 'react'
import { FieldContext } from '@area2k/use-form'
import { Maybe } from '@/types'

import Button from '@/components/Button'
import Stack from '@/components/Stack'
import LoadingState from '@/components/LoadingState'

import ItemSelectField from '@/form/ItemSelectField'
import TextAreaField from '@/form/TextAreaField'

import { useOrderState } from '../../../../context'
import { Address, Skill } from '../../../context'
import { FormValues } from '..'

type Props = {
  hasInstructions?: boolean
  skill: Skill
  handleAddressChange: <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => void
}

const AddressSection = ({
  hasInstructions = false,
  skill,
  handleAddressChange,
}: Props) => {
  const { billing } = useOrderState()

  const [isOpen, setIsOpen] = useState(hasInstructions)
  const [addresses, setAddresses] = useState<Maybe<Address[]>>(null)

  useEffect(() => {
    const filteredAddressesBySkill = billing!.customer.addresses.filter(
      (address) => {
        const addressHasTheSkill = address.rateQuotes!.find(
          (rateQuote) => rateQuote.skill!.id === skill.id
        )

        return addressHasTheSkill ? true : false
      }
    )

    setAddresses(filteredAddressesBySkill)
  }, [])

  return (
    <Stack vertical gap={0}>
      <div style={{ width: '100%' }}>
        {addresses === null ? (
          <Stack justify="center" align="center">
            <LoadingState overlayColor="white" text="Loading addresses" />
          </Stack>
        ) : (
          <ItemSelectField<Address>
            required
            fieldId="address"
            items={addresses}
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? `${item.addressLine1}` : '')}
            label="Where is the job?*"
            placeholder="Select an address..."
            callback={(fieldContext) =>
              handleAddressChange(fieldContext, 'address')
            }
          />
        )}
      </div>
      {isOpen ? (
        <div style={{ width: '100%' }}>
          <TextAreaField
            fieldId="addressInstructions"
            label="Address instructions"
            placeholder="More information makes a better employee selection..."
            callback={(fieldContext) =>
              handleAddressChange(fieldContext, 'addressInstructions')
            }
          />
        </div>
      ) : (
        <Button
          a11yLabel="Click to add optional address instructions"
          appearance="plain"
          label="Add optional address instructions"
          type="button"
          onClick={() => setIsOpen(true)}
        />
      )}
    </Stack>
  )
}

export default AddressSection
