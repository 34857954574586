import { CreateOrderMutation } from '@/types/graphql'

import Action from '@/components/Action'
import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  order: CreateOrderMutation['orderCreate']['order']
  hideModal: () => void
}

const PostSubmitModal = ({ order, hideModal }: Props) => (
  <Modal
    size="xs"
    title={`Order #${order.id} placed!`}
    onRequestClose={hideModal}
  >
    <Card.Section>
      <Stack vertical gap={24}>
        <Body>Your order #{order.id} was successfully placed.</Body>
        <Stack justify="end">
          <Button
            a11yLabel="Start a new order"
            appearance="outline"
            label="Place another"
            onClick={hideModal}
          />
          <Action.Button
            action={{
              a11yLabel: 'Navigate to order page',
              label: 'View order',
              to: `/orders/${order.id}`,
            }}
            appearance="primary"
          />
        </Stack>
      </Stack>
    </Card.Section>
  </Modal>
)

export default PostSubmitModal
