import { faDollarSign, faTimes } from '@fortawesome/free-solid-svg-icons'
import { startOfToday } from 'date-fns'
import { ChangeEvent, useCallback, useState } from 'react'
import { RangeWithKey } from 'react-date-range'

import styled from '@/styles'

import Input from '@/elements/Input'

import FormElement from '@/components/FormElement'
import IconicButton from '@/components/IconicButton'
import MaskedInput from '@/components/MaskedInput'
import Option from '@/components/Option'
import Stack from '@/components/Stack'

import FormColumns from '@/form/FormColumns'

import DateRangePicker from './DateRangePicker'

import { Schedule, ScheduleType } from '../../../context'
import { centsToCurrency } from '@/util/number'
import { rangeToDisplayRange } from '../util'
import Alert from '@/components/Alert'

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  marginBottom: 24,
  paddingBottom: 24,
  width: '100%',

  borderBottom: '1px solid $colors$neutralLighter',
})

type Props = {
  index: number
  minPay: number
  schedule: Schedule
  onChange: (index: number, schedule: Schedule) => void
  onRemove: (index: number) => void
}

type DatePickerChanges = {
  selection: RangeWithKey
}

const ScheduleSection = ({
  index,
  minPay,
  schedule,
  onChange,
  onRemove,
}: Props) => {
  const [payRateInput, setPayRateInput] = useState(
    centsToCurrency(schedule.payRate)
  )

  const handleRangeChange = useCallback(
    ({ selection }: DatePickerChanges) => {
      if (selection) {
        onChange(index, {
          ...schedule,
          dateRange: selection,
          displayRanges: rangeToDisplayRange(selection, schedule.type),
        })
      }
    },
    [index, schedule]
  )

  const handleTypeChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const type = ev.currentTarget.value as ScheduleType

      onChange(index, {
        ...schedule,
        displayRanges: rangeToDisplayRange(schedule.dateRange, type),
        type,
      })
    },
    [index, schedule]
  )

  const handleQuantityChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(index, { ...schedule, quantity: Number(ev.currentTarget.value) })
    },
    [index, schedule]
  )

  const handleStartTimeChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(index, { ...schedule, startTime: ev.currentTarget.value })
    },
    [index, schedule]
  )

  const handleEndTimeChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(index, { ...schedule, endTime: ev.currentTarget.value })
    },
    [index, schedule]
  )

  const handlePayRateChange = useCallback(
    (payRateInput: string) => {
      setPayRateInput(payRateInput)
      const payRate = Number(payRateInput) * 100

      if (payRate > minPay) {
        onChange(index, { ...schedule, payRate })
      } else {
        onChange(index, { ...schedule, payRate: minPay })
      }
    },
    [index, schedule, minPay]
  )

  const handleRemove = useCallback(() => {
    onRemove(index)
  }, [index])

  return (
    <Wrapper>
      <Stack justify="apart">
        <Stack gap={16}>
          <Option
            appearance="bullseye"
            checked={schedule.type === ScheduleType.ALL_DAYS}
            id={`schedule.${index}.${ScheduleType.ALL_DAYS}`}
            label="All days"
            name={`schedule.${index}.type`}
            type="radio"
            value={ScheduleType.ALL_DAYS}
            onChange={handleTypeChange}
          />
          <Option
            appearance="bullseye"
            checked={schedule.type === ScheduleType.WEEKDAYS_ONLY}
            id={`schedule.${index}.${ScheduleType.WEEKDAYS_ONLY}`}
            label="Weekdays only"
            name={`schedule.${index}.type`}
            type="radio"
            value={ScheduleType.WEEKDAYS_ONLY}
            onChange={handleTypeChange}
          />
          <Option
            appearance="bullseye"
            checked={schedule.type === ScheduleType.WEEKENDS_ONLY}
            id={`schedule.${index}.${ScheduleType.WEEKENDS_ONLY}`}
            label="Weekends only"
            name={`schedule.${index}.type`}
            type="radio"
            value={ScheduleType.WEEKENDS_ONLY}
            onChange={handleTypeChange}
          />
        </Stack>
        {index !== 0 && (
          <IconicButton
            a11yLabel="Remove this schedule"
            appearance="clear"
            icon={faTimes}
            size="sm"
            onClick={handleRemove}
          />
        )}
      </Stack>
      <DateRangePicker
        minDate={startOfToday()}
        value={schedule.displayRanges}
        onChange={handleRangeChange}
      />
      {Number(payRateInput) * 100 < minPay && (
        <FormElement>
          <Alert
            icon={faDollarSign}
            status="warning"
            title="Pay rate is below minimum"
            description={`Custom pay rate must be above $${centsToCurrency(
              minPay
            )} / hr`}
          />
        </FormElement>
      )}
      <FormColumns layout="triple">
        <FormElement label="Employees">
          <Input
            type="number"
            value={schedule.quantity}
            onChange={handleQuantityChange}
            min={1}
          />
        </FormElement>
        <FormElement label="Pay rate">
          <MaskedInput
            normalizeZeros
            padFractionalZeros
            mask={Number}
            min={minPay / 100}
            radix="."
            scale={2}
            signed={false}
            thousandsSeparator=","
            value={payRateInput}
            onAccept={(value) => {
              handlePayRateChange(value)
            }}
          />
        </FormElement>
        <FormElement label="Bill rate">
          <Input
            disabled
            value={`$${centsToCurrency(Math.ceil(schedule.payRate * 1.58))}`}
          />
        </FormElement>
      </FormColumns>
      <FormColumns>
        <FormElement label="Start time">
          <Input
            type="time"
            value={schedule.startTime}
            onChange={handleStartTimeChange}
          />
        </FormElement>
        <FormElement label="End time">
          <Input
            type="time"
            value={schedule.endTime}
            onChange={handleEndTimeChange}
          />
        </FormElement>
      </FormColumns>
    </Wrapper>
  )
}

export default ScheduleSection
