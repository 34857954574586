import { useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  faShoppingBasket,
  faTachometerAlt,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons'
import {
  faAddressBook,
  faBuilding,
  faCommentAlt,
  faUser,
} from '@fortawesome/free-regular-svg-icons'

import { Role } from '@/routes/PrivateRoute'
import ROUTES from '@/routes/routes'

import NavigationMenu from '@/components/NavigationMenu'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'
import { currentAgencyVar, currentAdminVar } from '@/util/apollo/cache'
import Skeleton from '@/components/Skeleton'
import Icon from '@/elements/Icon'

import { AgencyWrapper, AgencyAvatar } from './styles'
import PageSkeleton from './PageSkeleton'

const Sidebar = () => {
  const currentAgency = useReactiveVar(currentAgencyVar)
  const currentAdmin = useReactiveVar(currentAdminVar)

  const tenantAdminNavigation = useMemo(
    () => [
      {
        items: [
          {
            icon: faTachometerAlt,
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
          },
        ],
      },
      {
        title: 'Scheduling',
        items: [
          { icon: faShoppingBasket, a11yLabel: 'Orders', to: ROUTES.orders },
          { icon: faBuilding, a11yLabel: 'Customers', to: ROUTES.customers },
          { icon: faAddressBook, a11yLabel: 'Contacts', to: ROUTES.contacts },
        ],
      },
      {
        title: 'Workforce',
        items: [
          { icon: faUserFriends, a11yLabel: 'Workers', to: ROUTES.workers },
          { icon: faCommentAlt, a11yLabel: 'Chat', to: ROUTES.chat },
        ],
      },
    ],
    [currentAdmin]
  )

  const customerAdminNavigation = useMemo(
    () => [
      {
        items: [
          {
            icon: faTachometerAlt,
            a11yLabel: 'Dashboard',
            to: ROUTES.basePath,
            end: true,
          },
        ],
      },
      {
        title: 'Scheduling',
        items: [
          { icon: faShoppingBasket, a11yLabel: 'Orders', to: ROUTES.orders },
        ],
      },
      {
        title: 'Workforce',
        items: [
          { icon: faUserFriends, a11yLabel: 'My team', to: ROUTES.myTeam },
          {
            icon: faBuilding,
            a11yLabel: 'My company',
            to: ROUTES.myCompany,
          },
        ],
      },
      {
        title: 'Settings',
        items: [
          { icon: faUser, a11yLabel: 'My Profile', to: ROUTES.myProfile },
        ],
      },
    ],
    [currentAdmin]
  )

  return (
    <Stack vertical>
      <AgencyWrapper>
        <Stack gap={16}>
          <div>
            <AgencyAvatar>
              <Icon fixedWidth icon={faBuilding} />
            </AgencyAvatar>
          </div>
          {currentAgency ? (
            <Stack vertical gap={0}>
              <Body weight="semibold">{currentAgency.name}</Body>
              <Small>Washington, DC</Small>
            </Stack>
          ) : (
            <Skeleton.Body lines={2} />
          )}
        </Stack>
      </AgencyWrapper>
      {currentAdmin ? (
        <NavigationMenu
          groups={
            currentAdmin.role === Role.TENANT_ADMIN
              ? tenantAdminNavigation
              : customerAdminNavigation
          }
        />
      ) : (
        <PageSkeleton />
      )}
    </Stack>
  )
}

export default Sidebar
