import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import { useGetUploadUrlQuery } from '@/graphql'

type Props = {
  currentAvatarUrl: string | null
  hideModal: () => void
  onUpload: (key: string) => Promise<any>
}

const ChangeAvatarModal = ({
  currentAvatarUrl,
  hideModal,
  onUpload,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const { refetch: getUploadUrl } = useGetUploadUrlQuery({ skip: true })

  const handleUpload = useCallback(async () => {
    if (!file) return
    setIsLoading(true)

    try {
      const result = await getUploadUrl({ filename: file.name })
      const { key, url } = result.data.uploadUrl

      await fetch(url, { method: 'PUT', body: file })
      await onUpload(key)

      hideModal()
    } finally {
      setIsLoading(false)
    }
  }, [file, onUpload])

  const handleFileSelect = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setFile(ev.currentTarget.files ? ev.currentTarget.files[0] ?? null : null)
  }, [])

  const previewSrc = useMemo(() => (file ? URL.createObjectURL(file) : null), [
    file,
  ])

  return (
    <Modal size="xs" title="Change Avatar" onRequestClose={hideModal}>
      <Card.Section>
        <div style={{ display: 'flex', gap: 16 }}>
          <div style={{ flex: 1 }}>
            <FormElement label="Upload file">
              <input
                accept="image/*"
                capture="user"
                type="file"
                onChange={handleFileSelect}
              />
            </FormElement>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormElement label="Preview">
              <Avatar size="xl" src={previewSrc ?? currentAvatarUrl} />
            </FormElement>
          </div>
        </div>
        <Stack justify="end">
          <Button
            a11yLabel="Upload file and submit form"
            disabled={!file}
            isLoading={isLoading}
            label="Upload"
            loadingLabel="Uploading..."
            onClick={handleUpload}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ChangeAvatarModal
