import { useMemo } from 'react'
import useModal from '@area2k/use-modal'

import { GetCustomerQuery } from '@/types/graphql'

import ActiveCard from '@/components/ActiveCard'
import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import TableHeader from '@/components/TableHeader'
import Table from '@/components/Table'

import { TableCell, TableRow } from '@/elements/Table'

import { centsToCurrency } from '@/util/number'

import AddCustomeRateModal from './modals/AddCustomRateModal'

type Props = {
  customer: GetCustomerQuery['customer']
  currentAdminIsCustomerAdmin: boolean
}

const CustomPayRateList = ({
  customer,
  currentAdminIsCustomerAdmin,
}: Props) => {
  const [showCreateModal, hideAddRateModal] = useModal(
    () => (
      <AddCustomeRateModal customer={customer} hideModal={hideAddRateModal} />
    ),
    [customer]
  )

  const haveRateQuotes = customer.addresses.some(
    (adrress) => adrress.rateQuotes && adrress.rateQuotes.length !== 0
  )

  const headerFields = useMemo(
    () => ['', 'Job Skill', 'Pay Rate', 'Billing Rate', 'Address', 'Account'],
    []
  )

  return (
    <ActiveCard array={customer.addresses}>
      <Card
        title="Custom rates"
        actions={
          currentAdminIsCustomerAdmin
            ? undefined
            : [
                {
                  a11yLabel: 'Create a new custome pay rate',
                  label: 'Create',
                  onAction: showCreateModal,
                },
              ]
        }
      >
        <Table>
          <TableHeader fields={headerFields} />
          <tbody>
            {haveRateQuotes &&
              customer.addresses.map((address) => {
                return address.rateQuotes!.map((rateQuote) => (
                  <TableRow>
                    <TableCell>
                      <Avatar src={rateQuote.skill!.imageUrl} size="xs" />
                    </TableCell>
                    <TableCell>{rateQuote.skill!.name}</TableCell>
                    <TableCell>$ {centsToCurrency(rateQuote.pay)}</TableCell>
                    <TableCell>$ {centsToCurrency(rateQuote.cost)}</TableCell>
                    <TableCell>{address.addressLine1}</TableCell>
                    <TableCell>{rateQuote.account!.name}</TableCell>
                  </TableRow>
                ))
              })}
          </tbody>
        </Table>
        {!haveRateQuotes && (
          <EmptyState
            title="No custom rates available"
            text="If there were any, they would be here."
          />
        )}
      </Card>
    </ActiveCard>
  )
}

export default CustomPayRateList
