import { useCallback } from 'react'
import { useField } from '@area2k/use-form'

import Stack from '@/components/Stack'
import SkillItem from './SkillItem'
import { Heading } from '@/components/Typography'

import SingleSelectableField from '@/form/SingleSelectableField'

import { Skill } from '../../context'

type Props = {
  fieldId: string
  skillCategoryFieldId: string
  callback?: any
}

const SkillSelectField = ({
  skillCategoryFieldId,
  fieldId,
  callback,
}: Props) => {
  const { setValue } = useField<Skill | null>(fieldId)

  const { value: skillCategory } = useField<any | null>(
    skillCategoryFieldId,
    () => {
      setValue(null)
    }
  )

  const renderItem = useCallback((item: Skill) => <SkillItem item={item} />, [])

  if (!skillCategory) {
    return null
  }

  return (
    <Stack vertical align="center" gap={24}>
      <Heading>Select job skill</Heading>
      <Stack wrap align="center" justify="center" gap={16}>
        <SingleSelectableField<any>
          required
          fieldId={fieldId}
          options={skillCategory.skills}
          itemToKey={(item) => item.id}
          renderItem={renderItem}
          callback={callback}
        />
      </Stack>
    </Stack>
  )
}

export default SkillSelectField
