import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../index'

interface LayoutState {
  sidebarActive: boolean
  profileDropdownActive: boolean
}

const initialState: LayoutState = {
  sidebarActive: false,
  profileDropdownActive: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarVisibility: (state, action: PayloadAction<boolean>) => {
      state.sidebarActive = action.payload
    },
    toggleSidebar: (state) => {
      state.sidebarActive = !state.sidebarActive
    },
    setProfileDropdownVisibility: (state, action: PayloadAction<boolean>) => {
      state.profileDropdownActive = action.payload
    },
    toggleProfileDropdown: (state) => {
      state.profileDropdownActive = !state.profileDropdownActive
    },
  },
})

export const {
  toggleSidebar,
  setSidebarVisibility,
  setProfileDropdownVisibility,
  toggleProfileDropdown,
} = layoutSlice.actions

export const selectSidebarActive = (state: RootState) =>
  state.layout.sidebarActive

export const selectProfileDropdownActive = (state: RootState) =>
  state.layout.profileDropdownActive

export default layoutSlice.reducer