import { To } from 'history'

import { OrderListItemFragment } from '@/types/graphql'

import Link from '@/elements/Link'
import { TableCell, TableRow } from '@/elements/Table'

import { formatISO } from '@/util/date'

type Props = {
  item: OrderListItemFragment
  to: To
}

const OrderTableItem = ({ item, to }: Props) => {
  return (
    <TableRow>
      <TableCell>
        <Link to={to}>#{item.id}</Link>
      </TableCell>
      <TableCell>
        {formatISO(item.startAt, 'PP')} &mdash; {formatISO(item.endAt, 'PP')}
      </TableCell>
      <TableCell>
        {item.jobsCount} {item.jobsCount === 1 ? 'job' : 'jobs'}
      </TableCell>
    </TableRow>
  )
}

export default OrderTableItem
