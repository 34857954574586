import { Point } from '@/types/graphql'

const toRadians = (n: number) => (n * Math.PI) / 180

export const EARTH_RADIUS = 6371 * 1000

export const haversine = (p1: Point, p2: Point) => {
  const x1 = p2.latitude - p1.latitude
  const dLat = toRadians(x1)

  const x2 = p2.longitude - p1.longitude
  const dLon = toRadians(x2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(p1.latitude)) *
      Math.cos(toRadians(p2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return c * EARTH_RADIUS
}

export const METERS_TO_MILES = 1609

export const metersToMiles = (meters: number) => meters / METERS_TO_MILES
