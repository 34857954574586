import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import { GetAccountQuery } from '@/types/graphql'
import { useUpdateAccountMutation } from '@/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'

import { handleMutationFormError } from '@/util/error'
import ContactSelectField from './ContactSelectField'

export type Props = {
  account: GetAccountQuery['account']
  hideModal: () => void
}

type FormValues = {
  contact: GetAccountQuery['account']['defaultContact']
}

const UpdateContactModal = ({ account, hideModal }: Props) => {
  const initialValues: FormValues = {
    contact: account.defaultContact,
  }

  const [updateJob] = useUpdateAccountMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await updateJob({
          variables: {
            defaultContactId: values.contact.id,
            accountId: account.id,
          },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        })
      }
    },
    [account]
  )

  return (
    <Modal
      size="sm"
      title="Update contact information"
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <ContactSelectField
            fieldId="contact"
            label="Contact"
            account={account}
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={false}
              label="Update"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateContactModal
