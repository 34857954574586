import styled from '@/styles'

const Layout = styled('div', {
  padding: '112px 32px',
  overflowY: 'auto'
})

Layout.displayName = 'stitches(JobEditor.Layout)'

export default Layout
