import { ChangeEvent, useCallback, useMemo } from 'react'

import styled from '@/styles'

import Avatar from '@/components/Avatar'

import { faPen } from '@fortawesome/free-solid-svg-icons'

import { ListTenantAdminQuery } from '@/types/graphql'
import { Maybe } from '@/types'

type AdmintItem = ListTenantAdminQuery['agency']['tenant']['admins'][0]

const AvatarContainer = styled('div', {
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
})

const AvatarLabel = styled('label', {
  aspectRatio: 1,
  cursor: 'pointer',
})

const InputFile = styled('input', {
  visibility: 'hidden',
  width: 0,
  height: 0,
})

type Props = {
  currentAvatarUrl: string | null
  item?: Maybe<AdmintItem>
  useFile
  useSetFile
}

const ChangeAvatarCard = ({
  currentAvatarUrl,
  item,
  useFile: file,
  useSetFile: setFile,
}: Props) => {
  const handleFileSelect = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setFile(ev.currentTarget.files ? ev.currentTarget.files[0] ?? null : null)
  }, [])

  const previewSrc = useMemo(() => (file ? URL.createObjectURL(file) : null), [
    file,
  ])

  return (
    <AvatarContainer>
      <AvatarLabel htmlFor="Preview">
        <Avatar
          firstName={item?.user.firstName}
          size="profilePic"
          src={previewSrc ?? currentAvatarUrl}
          icon={faPen}
          showIconicAction
        />
      </AvatarLabel>
      <InputFile
        id="Preview"
        accept="image/*"
        capture="user"
        type="file"
        onChange={handleFileSelect}
      />
    </AvatarContainer>
  )
}

export default ChangeAvatarCard
