import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { TabDefinition } from '@/components/Tabs'
import Button from '@/components/Button'
import Form from '@/form'
import WorkerAutocompleteField, {
  WorkerItem,
} from '../../Job/WorkerAutocompleteField'
import { useCallback } from 'react'
import {
  useCreateCustomerWorkerRelationshipMutation,
  WorkerRelationshipItemFragmentDoc,
} from '@/graphql'
import { handleMutationFormError } from '@/util/error'
import { SubmitHelpers } from '@area2k/use-form'
import { GetCustomerQuery, RelationshipKindEnum } from '@/types/graphql'

const JOBS_TABS: TabDefinition[] = [
  { a11yLabel: 'View favorite workers', label: 'favorite' },
  { a11yLabel: 'View blocked workers', label: 'blocked' },
]

export type Props = {
  hideModal: () => void
  currentTab: number
  customer: GetCustomerQuery['customer']
}

type FormValues = {
  worker: WorkerItem | null
}

const kinds: RelationshipKindEnum[] = [
  RelationshipKindEnum.FAVORITED,
  RelationshipKindEnum.BLOCKED,
]

const AddWorkerToList = ({ customer, currentTab, hideModal }: Props) => {
  const initialValues: FormValues = {
    worker: null,
  }

  const [createRelationship] = useCreateCustomerWorkerRelationshipMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Customer:${customer.id}`,
        fields: {
          workerRelationships(existingRefs = [], { readField }) {
            if (!data) return existingRefs
            const { workerRelationship } = data.customerRelateWorker

            if (
              existingRefs.some(
                (ref) => readField('id', ref) === workerRelationship.id
              )
            ) {
              return existingRefs
            }

            const newRelationRef = cache.writeFragment({
              fragment: WorkerRelationshipItemFragmentDoc,
              data: workerRelationship,
            })

            return [...existingRefs, newRelationRef]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const { data } = await createRelationship({
          variables: {
            customerId: customer.id,
            workerId: values.worker!.id,
            kind: kinds[currentTab],
          },
        })
        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            ALREADY_HIRED: () => ({
              title: 'Worker already hired',
              message: 'This worker has already been hired for this job.',
            }),
            JOB_UNAVAILABLE: () => ({
              title: 'Job not available',
              message: 'This job is no longer available to hire workers.',
            }),
          },
        })
      }
    },
    []
  )

  return (
    <Modal
      size="sm"
      title={`Add worker to ${JOBS_TABS[currentTab].label} `}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <WorkerAutocompleteField fieldId="worker" label="Select worker" />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={undefined}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default AddWorkerToList
