import { keyframes } from '@/styles'

export const ShimmerAnimation = keyframes({
  '0%': { opacity: 0.45 },
  '100%': { opacity: 0.9 }
})

export const VisibilityRevealAnimation = keyframes({
  to: {
    pointerEvents: 'initial',
    visibility: 'visible'
  }
})

export const DisplayRevealAnimation = keyframes({
  to: {
    display: 'initial',
    pointerEvents: 'initial'
  }
})
