import { useCallback, useMemo, useReducer, useState } from 'react'

import Page from '@/components/Page'

import Billing from './Billing'
import JobEditor from './JobEditor'
import Review from './Review'

import {
  OrderActions,
  OrderActionsContext,
  OrderActionType,
  OrderStateContext,
  stateReducer,
} from './context'

const CreateOrder = () => {
  const [state, dispatch] = useReducer(stateReducer, {
    billing: null,
    jobs: [],
  })

  const [isEditorOpen, setIsEditorOpen] = useState(true)

  const openEditor = useCallback(() => setIsEditorOpen(true), [])
  const closeEditor = useCallback(() => setIsEditorOpen(false), [])

  const actions = useMemo<OrderActions>(
    () => ({
      addJob: (job) => {
        dispatch({ type: OrderActionType.ADD_JOB, job })
        closeEditor()
      },
      clearJobs: () => dispatch({ type: OrderActionType.CLEAR_JOBS }),
      removeJob: (index) =>
        dispatch({ type: OrderActionType.REMOVE_JOB, index }),
      replaceJob: (index, job) =>
        dispatch({ type: OrderActionType.REPLACE_JOB, index, job }),
      setBilling: (billing) =>
        dispatch({ type: OrderActionType.SET_BILLING, billing }),
    }),
    []
  )

  return (
    <OrderActionsContext.Provider value={actions}>
      <OrderStateContext.Provider value={state}>
        {state.billing ? (
          <>
            <Review onOpenEditor={openEditor} />
            {isEditorOpen && <JobEditor onClose={closeEditor} />}
          </>
        ) : (
          <Page title="New order" size="md">
            <Billing />
          </Page>
        )}
      </OrderStateContext.Provider>
    </OrderActionsContext.Provider>
  )
}

export default CreateOrder
