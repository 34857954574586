import { useMemo, useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import useModal from '@area2k/use-modal'
import { useCallback } from 'react'

import { CustomerFilterSetInput, ListCustomersQuery } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Page from '@/components/Page'
import QueryEmptyState from '@/components/QueryEmptyState'
import ResourceList from '@/components/ResourceList'
import { Body } from '@/components/Typography'
import Paginator from '@/components/Paginator/Paginator'

import CreateCustomerModal from './CreateCustomerModal'
import CustomerFilters from './CustomerFilters'

import { useListCustomersQuery } from '@/graphql'

import { currentAgencyVar } from '@/util/apollo/cache'

type Item = ListCustomersQuery['agency']['customers']['items'][0]

const initialFilters: CustomerFilterSetInput = {}

const Customers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<CustomerFilterSetInput>(initialFilters)

  const finalFilters = useMemo<CustomerFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const query = useListCustomersQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const [showCreateModal, hideCreateModal] = useModal(() => (
    <CreateCustomerModal hideModal={hideCreateModal} />
  ))

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        media={<Avatar firstName={item?.name} size="sm" src={item.logoUrl} />}
        to={item.id}
      >
        <Body>{item.name}</Body>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a customer',
        label: 'New customer',
        onAction: showCreateModal,
      }}
      size="md"
      title="Customers"
    >
      <Card>
        <Card.Section>
          <CustomerFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <ResourceList
          emptyState={
            <QueryEmptyState
              query={query}
              title="No customers"
              text="No customers were found, create one above."
            />
          }
          isLoading={query.loading}
          items={query.data ? query.data.agency.customers.items : []}
          renderItem={renderItem}
          resourceName={{ singular: 'customer', plural: 'customers' }}
        />
      </Card>
      {query.data && query.data.agency.customers.items.length !== 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={query.data.agency.customers.pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Customers
