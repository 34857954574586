import { useState } from 'react'
import { useModal } from 'react-modal-hook'

import { GetWorkerQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body } from '@/components/Typography'

import AddressSection from './AddressSection'
import SkillsSection from './SkillsSection'
import UpdateProfileModal from './UpdateProfileModal'
import useChangeAvatarModal from '../useChangeAvatarModal'

import TaxSection from './TaxSection'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const ProfileCard = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const showAvatarModal = useChangeAvatarModal(worker)

  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateProfileModal worker={worker} hideModal={hideUpdateModal} />,
    [worker]
  )

  return (
    <Card
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Edit this worker's profile",
                label: 'Edit',
                onAction: showUpdateModal,
              },
            ]
          : undefined
      }
      title="Profile"
    >
      <Card.Section>
        <Stack gap={20}>
          <div style={{ cursor: 'pointer' }} onClick={showAvatarModal}>
            <Avatar
              a11yLabel={`Image of ${worker.user.firstName} ${worker.user.lastName}`}
              firstName={worker.user.firstName}
              src={worker.avatarUrl}
              size="lg"
            />
          </div>
          <TextStack>
            <Body>
              {worker.user.firstName} {worker.user.lastName}
            </Body>
            <Body color="light">{worker.user.email}</Body>
            <Body color="light">{worker.user.phoneNumber}</Body>
          </TextStack>
        </Stack>
      </Card.Section>
      <SkillsSection
        worker={worker}
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
      />
      <AddressSection
        worker={worker}
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
      />
      <TaxSection
        worker={worker}
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
      />
    </Card>
  )
}

export default ProfileCard
