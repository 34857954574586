import Pagination from 'react-js-pagination'
import Stack from '@/components/Stack'
import './styles.css'

type Props = {
  currentPage: number
  itemsPerPage: number
  handlePageChange: (number) => void
  handleNumberItemsChange: (number) => void
  itemsLength: number
  pageRangeDisplayed?: number
}

const Paginator: React.FC<Props> = ({
  currentPage,
  itemsPerPage,
  handlePageChange,
  handleNumberItemsChange,
  itemsLength,
  pageRangeDisplayed = 5,
}) => {
  return (
    <Stack justify="apart" style={{ marginTop: 32 }}>
      <Stack>
        <span>Items per page</span>
        <select
          value={itemsPerPage.toString()}
          onChange={handleNumberItemsChange}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </Stack>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={itemsLength}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      />
    </Stack>
  )
}

export default Paginator
