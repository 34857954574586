import { GetJobQuery } from '@/types/graphql'

import useModal from '@area2k/use-modal'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import UpdateContactModal from './UpdateContactModal'

type Props = {
  job: GetJobQuery['job']
}

const ContactSection = ({ job }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => <UpdateContactModal job={job} hideModal={hideCreateModal} />,
    [job]
  )

  const { contact, contactInstructions } = job
  return (
    <Card.Section
      actions={[
        {
          a11yLabel: 'Change job contact',
          label: 'Change',
          onAction: showCreateModal,
        },
      ]}
      title="Job contact"
    >
      <Stack gap={20}>
        <div>
          <Avatar
            firstName={contact.user.firstName}
            size="md"
            src={contact.avatarUrl}
          />
        </div>
        <TextStack>
          <Body>
            {contact.user.firstName} {contact.user.lastName}
          </Body>
          <Small>{contact.user.email}</Small>
          {contactInstructions && <Small>"{contactInstructions}"</Small>}
        </TextStack>
      </Stack>
    </Card.Section>
  )
}

export default ContactSection
