import { StitchesVariants } from '@stitches/react'
import { ComponentPropsWithoutRef } from 'react'

import styled from '@/styles'

import Cover from '@/elements/Cover'
import Input from '@/elements/Input'

const FocusCover = styled(Cover, {
  backgroundColor: 'white',
  border: '1px solid $colors$neutralLight',
  borderRadius: '$lg',

  focusPseudoElement: {
    element: 'after',
    activator: 'input:focus + &',
    borderWidth: 1
  }
})

const Wrapper = styled('div', {
  display: 'flex',

  [`> ${Input}`]: {
    background: 'none',
    borderColor: 'transparent',

    zIndex: 2
  }
})

type Props = ComponentPropsWithoutRef<'input'> & StitchesVariants<typeof Input>

const TextInput = (props: Props) => {
  return (
    <Wrapper>
      <Input {...props} />
      <FocusCover />
    </Wrapper>
  )
}

export default TextInput
