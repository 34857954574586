import { useReactiveVar } from '@apollo/client'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { useParams } from 'react-router'
import useModal from '@area2k/use-modal'
import { useGetAdminCustomerQuery } from '@/graphql'

import { faCamera, faKey } from '@fortawesome/free-solid-svg-icons'

import styled from '@/styles'

import { currentAdminVar } from '@/util/apollo/cache'
import ROUTES from '@/routes/routes'
import { Role } from '@/routes/PrivateRoute'

import Link from '@/elements/Link'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import UserChangePasswordModal from '@/modals/UserChangePasswordModal'
import UpdatePasswordToCustomerAdminModal, {
  CustomerAdminItem,
} from './modals/UpdatePasswordToCustomerAdminModal'

import EditAdminInfoModal from './EditAdminInfoModal'
import useChangeAvatarModal from './useChangeAvatarModal'
import PageSkeleton from './PageSkeleton'

const Grid = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto auto',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '24px',
})

const CustomerAdmin = () => {
  const { adminId: adminIdByParam } = useParams()

  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN
  const query = useGetAdminCustomerQuery({
    variables: {
      customerAdminId: adminIdByParam ? adminIdByParam : currentAdmin!.id,
    },
  })

  const showAvatarModal = useChangeAvatarModal(query.data?.customerAdmin)

  const [showEditModal, hideEditModal] = useModal<CustomerAdminItem>(
    (customerAdmin: CustomerAdminItem) => (
      <EditAdminInfoModal
        customerAdmin={customerAdmin}
        hideModal={hideEditModal}
      />
    ),
    [query.data]
  )

  // as tenant admin
  const [
    showUpdatePasswordToCustomerAdminModal,
    hideUpdatePasswordToCustomerAdminModal,
  ] = useModal<CustomerAdminItem>(
    (customerAdmin: CustomerAdminItem) => (
      <UpdatePasswordToCustomerAdminModal
        hideModal={hideUpdatePasswordToCustomerAdminModal}
        customerAdmin={customerAdmin}
      />
    ),
    [query.data]
  )

  // as customer admin
  const [
    showChangeUserCurrentPasswordModal,
    hideChangeUserCurrentPasswordModal,
  ] = useModal(
    () => (
      <UserChangePasswordModal hideModal={hideChangeUserCurrentPasswordModal} />
    ),
    []
  )

  if (!query.data) {
    return (
      <PageSkeleton currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin} />
    )
  }

  const user = query.data.customerAdmin.user
  const customerAdmin = query.data.customerAdmin
  const customer = query.data.customerAdmin.customer

  const permittedEdit = currentAdmin!.id === customerAdmin.id

  return (
    <Page
      media={
        <Avatar
          firstName={query.data.customerAdmin.user.firstName}
          size="md"
          src={query.data.customerAdmin.avatarUrl}
        />
      }
      title={`${user.firstName} ${user.lastName}`}
      primaryAction={
        !currentAdminIsCustomerAdmin
          ? {
              a11yLabel: 'Open chat with this admin',
              icon: faCommentAlt,
              label: 'Send message',
              to: `../../../../chat/${query.data.customerAdmin.chatUid}`,
            }
          : permittedEdit
          ? {
              a11yLabel: 'Change password',
              label: 'Change password',
              icon: faKey,
              onAction: showChangeUserCurrentPasswordModal,
            }
          : undefined
      }
      secondaryActions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Show more actions',
                label: 'More',
                actions: [
                  [
                    {
                      a11yLabel: 'Change admin avatar',
                      label: 'Change avatar',
                      icon: faCamera,
                      onAction: showAvatarModal,
                    },
                    {
                      a11yLabel: 'Change admin password',
                      label: 'Change password',
                      icon: faKey,
                      onAction: () =>
                        showUpdatePasswordToCustomerAdminModal(customerAdmin),
                    },
                  ],
                ],
              },
            ]
          : permittedEdit
          ? [
              {
                a11yLabel: 'Change avatar',
                label: 'Change avatar',
                icon: faCamera,
                onAction: showAvatarModal,
              },
            ]
          : undefined
      }
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <Card
              title="Information"
              sectioned
              actions={
                !currentAdminIsCustomerAdmin || permittedEdit
                  ? [
                      {
                        a11yLabel: 'Edit admin info',
                        label: 'Edit',
                        onAction: () => showEditModal(customerAdmin),
                      },
                    ]
                  : undefined
              }
            >
              <Grid>
                <TextStack spacing="loose">
                  <Small>First Name</Small>
                  <Body>{user.firstName}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Last Name</Small>
                  <Body>{user.lastName}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Email</Small>
                  <Body>{user.email}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Phone</Small>
                  <Body>{user.phoneNumber}</Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Department</Small>
                  <Body
                    color={customerAdmin.department ? 'default' : 'lightest'}
                  >
                    {customerAdmin.department
                      ? customerAdmin.department
                      : 'Not provided'}
                  </Body>
                </TextStack>
                <TextStack spacing="loose">
                  <Small>Title</Small>
                  <Body color={customerAdmin.title ? 'default' : 'lightest'}>
                    {customerAdmin.title ? customerAdmin.title : 'Not provided'}
                  </Body>
                </TextStack>
              </Grid>
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Card
            sectioned
            title={currentAdminIsCustomerAdmin ? 'Company' : 'Customer'}
          >
            <Stack gap={20}>
              <div>
                <Avatar
                  firstName={customer.name}
                  size="sm"
                  src={customer.logoUrl}
                />
              </div>
              <Link
                to={
                  currentAdminIsCustomerAdmin
                    ? `../../${ROUTES.myCompany}`
                    : '../..'
                }
              >
                <Body color="inherit" weight="medium">
                  {customer.name}
                </Body>
              </Link>
            </Stack>
          </Card>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default CustomerAdmin
