import { CometChat } from '@cometchat-pro/chat'

import debug from '@/util/debug'

import Config from '@/config'

const APP_SETTINGS = new CometChat.AppSettingsBuilder().setRegion(Config.COMETCHAT_REGION).build()

export default {
  init: async () => {
    try {
      const success = await CometChat.init(Config.COMETCHAT_APP_ID, APP_SETTINGS)
      debug.log('[CometChat] Chat service initialized.')

      return success
    } catch (err) {
      debug.error('[CometChat] Initialization failed!', err)
      return false
    }
  },

  login: async (authToken: string) => {
    const user = await CometChat.login(authToken)
    debug.log('[CometChat] Logged in user:', user)

    return user
  },

  logout: async () => {
    if (CometChat.getLoggedinUser()) {
      await CometChat.logout()
      debug.log('[CometChat] Logged out')
    }
  }
}
