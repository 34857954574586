import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'
import Form from '@/form'
import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import { useCallback } from 'react'

type Props = {
  hideModal: () => void
  callback: (s) => void
}

const paymentOption = [
  { label: 'Invoice', value: 'invoice' },
  { label: 'Credit card', value: 'card' },
]

type FormValues = {
  paymentMethod: string
}

const initialValues: FormValues = {
  paymentMethod: 'invoice',
}

const ChangePaymentModal = ({ hideModal, callback }: Props) => {
  const handleSubmit = useCallback(async (values: FormValues) => {
    callback(values.paymentMethod === 'invoice' ? 'invoice' : 'card')
    hideModal()
  }, [])
  return (
    <Modal size="sm" title="Change Payment method" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <SingleOptionGroupField
            options={paymentOption}
            fieldId="paymentMethod"
            label="Choose method"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={undefined}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default ChangePaymentModal
