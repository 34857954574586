import { useEffect } from 'react'

import { GetAccountQuery, ListCustomerAdminsQuery } from '@/types/graphql'

import ItemSelectField from '@/form/ItemSelectField'

import { useListCustomerAdminsLazyQuery } from '@/graphql'

export type ContactItem = ListCustomerAdminsQuery['customer']['admins']['items'][0]

type Props = {
  fieldId: string
  label: string
  account: GetAccountQuery['account']
}

const ContactSelectField = ({ fieldId, label, account: { id } }: Props) => {
  const [fetch, { data, loading }] = useListCustomerAdminsLazyQuery()
  useEffect(() => {
    fetch({ variables: { customerId: id } })
  }, [])

  return (
    <ItemSelectField<ContactItem>
      required
      disabled={loading}
      fieldId={fieldId}
      items={data ? data.customer.admins.items : []}
      itemToKey={(item) => item.id}
      itemToString={(item) =>
        item ? `${item.user.firstName} ${item.user.lastName}` : ''
      }
      label={label}
    />
  )
}

export default ContactSelectField
