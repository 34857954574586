import { GetJobQuery } from '@/types/graphql'
import useModal from '@area2k/use-modal'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'
import UpdateUniformModal from './UpdateUniformModal'

type Props = {
  job: GetJobQuery['job']
}

const UniformSection = ({ job }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => <UpdateUniformModal job={job} hideModal={hideCreateModal} />,
    [job]
  )

  const { uniform, uniformInstructions } = job

  return (
    <Card.Section
      actions={[
        {
          a11yLabel: 'Change job dress code',
          label: 'Change',
          onAction: showCreateModal
        }
      ]}
      title='Dress code'
    >
      <Stack vertical gap={12}>
        <Stack gap={20}>
          <div>
            <Avatar src={uniform.imageUrl} />
          </div>
          <Body>{uniform.name}</Body>
        </Stack>
        {uniformInstructions && <Small>"{uniformInstructions}"</Small>}
      </Stack>
    </Card.Section>
  )
}

export default UniformSection
