import React, { useMemo, useCallback, useState, useEffect } from 'react'
import {
  CellProps,
  HeaderProps,
  Hooks,
  useRowSelect,
  useTable,
} from 'react-table'
import { FieldContext } from '@area2k/use-form'
import { useGetWorkerRelationshipsByCustomerQuery } from '@/graphql'
import { RelationshipKindEnum } from '@/types/graphql'
import { Maybe } from '@/types'

import useMediaQuery from '@/hooks/useMediaQuery'

import { WorkerItem } from '@/routes/Agency/Job/WorkerAutocompleteField'

import Layout from '../Layout'
import Form from '@/form'
import Page from '@/components/Page'
import { Title } from '@/components/Typography'
import WorkerFinder from './WorkerFinder'
import Tag from '@/components/Tag'
import EmptyState from '@/components/EmptyState'
import Table from '@/components/Table'
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table'
import BottomBar from '../BottomBar'
import Button from '@/components/Button'
import Option from '@/components/Option'
import Avatar from '@/components/Avatar'
import Stack from '@/components/Stack'
import LoadingState from '@/components/LoadingState'

import { Step, useJobDraftActions, useJobDraftState } from '../../context'
import { useOrderActions, useOrderState } from '../../../context'

type Props = {
  setStep: (step: Step) => void
}

const SelectWorkersStep = ({ setStep }: Props) => {
  const isPhoneOnly = useMediaQuery('(max-width: 559px)')

  const { billing } = useOrderState()
  const { addJob } = useOrderActions()
  const {
    data: workerRelationshipsData,
    loading,
  } = useGetWorkerRelationshipsByCustomerQuery({
    variables: { customerId: billing!.customer.id },
  })

  const jobState = useJobDraftState()
  const { resetState } = useJobDraftActions()

  const [favWorkers, setFavWorkers] = useState<WorkerItem[]>([])

  const selectionHooks = (hooks: Hooks<any>) => {
    hooks.allColumns.push((columns) => [
      {
        id: '_selector',
        Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
          <Option
            id="id"
            label="All"
            appearance="checkbox"
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }: CellProps<any>) => (
          <Option
            id={row.id}
            label=""
            appearance="checkbox"
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      ...columns,
    ])
  }

  const hooks = [useRowSelect, selectionHooks]
  const columns = useMemo(
    () => [
      {
        id: 'avatarUrl',
        accessor: (worker: WorkerItem) => worker.avatarUrl,
        Cell: ({ cell: { value } }) => <Avatar size="sm" src={value} />,
      },
      {
        Header: 'First name',
        accessor: (worker: WorkerItem) => worker.user.firstName,
      },
      {
        Header: 'Last name',
        accessor: (worker: WorkerItem) => worker.user.lastName,
      },
      {
        Header: 'Phone number',
        accessor: (worker: WorkerItem) => worker.user.phoneNumber,
      },
      { Header: 'Email', accessor: (worker: WorkerItem) => worker.user.email },
    ],
    []
  )

  const data = useMemo(() => favWorkers, [favWorkers])

  const instance = useTable({ columns, data }, ...hooks)

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = instance

  const handleWorkerAutocompleteField = (
    fieldContext: FieldContext<Maybe<WorkerItem>>
  ) => {
    if (fieldContext.value) {
      const workerExists = favWorkers.some(
        (worker) => worker.id === fieldContext.value!.id
      )

      if (!workerExists)
        setFavWorkers((prevValues) => [...prevValues, fieldContext.value!])

      fieldContext.setValue(null)
    }
  }

  const handleSubmit = useCallback(() => {
    const selectedWorkers = selectedFlatRows.map(
      (selectedFlatRow) => selectedFlatRow.original
    )

    addJob({ ...jobState, selectedWorkers })
    resetState()
  }, [selectedFlatRows])

  useEffect(() => {
    if (workerRelationshipsData) {
      const { workerRelationships } = workerRelationshipsData.customer

      const favWorkers = workerRelationships
        .filter((item) => item.kind === RelationshipKindEnum.FAVORITED)
        .map((item) => item.worker)
      setFavWorkers(favWorkers)
    }
  }, [workerRelationshipsData])

  return (
    <Layout>
      <Form initialValues={{}} onSubmit={async () => {}}>
        <Page size="md">
          <Stack vertical gap={16}>
            <Title>Who should see your job post position first?</Title>

            <Stack wrap={isPhoneOnly} justify="apart">
              <WorkerFinder
                fieldId="worker"
                label="Find worker"
                callback={handleWorkerAutocompleteField}
              />

              <Tag
                label={`${selectedFlatRows.length} people selected`}
                disabled={selectedFlatRows.length === 0}
                onRemove={() => toggleAllRowsSelected(false)}
              />
            </Stack>

            {loading && (
              <Stack justify="center" align="center">
                <LoadingState
                  overlayColor="white"
                  text="Loading favorites workers"
                />
              </Stack>
            )}

            {!loading && favWorkers.length === 0 && (
              <Stack justify="center" align="center">
                <EmptyState
                  title="There are no favorite workers"
                  text="Try to find the worker you want to add to the list"
                />
              </Stack>
            )}
          </Stack>

          {!loading && favWorkers.length !== 0 && (
            <Table {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableHeading {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </TableHeading>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)

                  return (
                    <TableRow
                      {...row.getRowProps()}
                      clickable
                      selected={row.isSelected}
                    >
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </tbody>
            </Table>
          )}
        </Page>

        <BottomBar>
          <Button
            a11yLabel="Go back to previous step"
            appearance="outline"
            label="Back"
            type="button"
            onClick={() => setStep(Step.PUBLISHING)}
          />

          <Button
            type="submit"
            a11yLabel="Submit form"
            disabled={selectedFlatRows.length === 0}
            label="Finish job"
            onClick={handleSubmit}
          />
        </BottomBar>
      </Form>
    </Layout>
  )
}

export default SelectWorkersStep
