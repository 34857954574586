import { GetWorkerQuery, WorkerInterviewStatusEnum } from '@/types/graphql'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'
import { useWorkerInterviewQuery } from '@/graphql'
import Button from '@/components/Button'
import Badge from '@/components/Badge'

type WorkerItem = GetWorkerQuery['worker']

const interviewStatusLabels = {
  [WorkerInterviewStatusEnum.PASSED]: 'Passed',
  [WorkerInterviewStatusEnum.NOT_PASSED]: 'Not Passed',
}

type Props = {
  worker: WorkerItem
  hideModal: () => void
}

const InterviewAnswersModal = ({ worker, hideModal }: Props) => {
  const query = useWorkerInterviewQuery({
    variables: { workerId: worker.id },
  })

  const interview = query.data?.workerInterview

  return (
    <Modal disableClickout title="Interview" size="xl" onRequestClose={hideModal}>
      <Card.Section>
        <Stack style={{ padding: '0px 20px 15px', }} gap={10}>
          <Body weight="medium">Status</Body>
          <Badge
            label={interviewStatusLabels.PASSED}
            size="md"
            status="theme"
          />
        </Stack>
        <Card.Section>
          <Stack wrap justify="apart" gap={20}>
            {interview?.workerInterviewAnswers.map((answer, index) => (
              <Stack
                key={index}
                vertical
                verticalGap={8}
                style={{ width: '45%' }}
              >
                <Body weight="medium">
                  {`${index + 1}.- ${answer.interviewQuestion.question}`}
                </Body>
                <Small sizes="md" weight="semibold" color="lighter">
                  {answer.answer}
                </Small>
              </Stack>
            ))}
          </Stack>
          <Stack justify="end">
            <Button
              a11yLabel="Close Interview"
              label="OK"
              type="button"
              onClick={hideModal}
            />
          </Stack>
        </Card.Section>
      </Card.Section>
    </Modal>
  )
}

export default InterviewAnswersModal
