import { GetOrderQuery } from '@/types/graphql'
import { useReactiveVar } from '@apollo/client'

import { currentAdminVar } from '@/util/apollo/cache'
import { Role } from '@/routes/PrivateRoute'
import Link from '@/elements/Link'

import ROUTES from '@/routes/routes'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

type Props = {
  order: GetOrderQuery['order']
}

const CustomerCard = ({ order: { account } }: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN

  return (
    <Card sectioned title="Customer">
      <Stack gap={16}>
        <div>
          <Avatar
            firstName={account.customer.name}
            size="sm"
            src={account.customer.logoUrl}
          />
        </div>
        <TextStack>
          <Link
            to={
              currentAdminIsCustomerAdmin
                ? `../../${ROUTES.myCompany}`
                : `../../customers/${account.customer.id}`
            }
          >
            <Body color="inherit" weight="medium">
              {account.customer.name}
            </Body>
          </Link>
          <Link
            appearance="subtle"
            to={
              currentAdminIsCustomerAdmin
                ? `../../${ROUTES.myCompany}/accounts/${account.id}`
                : `../../customers/${account.customer.id}/accounts/${account.id}`
            }
          >
            <Small>{account.name}</Small>
          </Link>
        </TextStack>
      </Stack>
    </Card>
  )
}

export default CustomerCard
