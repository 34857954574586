import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'
import { useUpdateUserProfileMutation } from '@/graphql'
import { GetAdminCustomerQuery, GetWorkerQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

export type WorkerItem = GetWorkerQuery['worker']

type Props = {
  hideModal: () => void
  worker: WorkerItem
}

type FormValues = {
  newPassword: string
}

const UpdatePasswordToWorkerModal = ({ hideModal, worker }: Props) => {
  const initialValue: FormValues = {
    newPassword: '',
  }

  const [updatePassword, { loading }] = useUpdateUserProfileMutation()

  const handleSubmit = useCallback(
    async (
      { newPassword }: FormValues,
      { clearForm, setFormError }: SubmitHelpers
    ) => {
      try {
        if (newPassword.length < 8) {
          return setFormError('invalidNewPassword', {
            message: 'The new password must be have at least 8 characters long',
          })
        }

        clearForm()
        const result = await updatePassword({
          variables: {
            userId: worker.user.id,
            firstName: worker.user.firstName,
            lastName: worker.user.lastName,
            phoneNumber: worker.user.phoneNumber,
            password: newPassword,
          },
        })

        if (result.data) return hideModal()
      } catch (err) {
        console.log(err)
      }
    },
    []
  )

  return (
    <Modal size="sm" title="Change Password" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValue} onSubmit={handleSubmit}>
          <TextField
            css={{ letterSpacing: '2px' }}
            required
            fieldId="newPassword"
            label="New Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={loading}
              label="Save"
              loadingLabel="Changing..."
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdatePasswordToWorkerModal
