import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import useMediaQuery from '@/hooks/useMediaQuery'
import { useAppDispatch } from '@/hooks/store'
import { setSidebarVisibility } from '@/store/slices/layoutSlice'

import AppFrame from '@/components/AppFrame'
import AppFooter from '@/components/AppFooter'
import AppNavbar from '@/components/AppNavbar'
import LoadingState from '@/components/LoadingState'

import Sidebar from '@/routes/Agency/Sidebar'

import {
  useGetAgencyByKeyQuery,
  useAuthenticateChatServiceMutation,
} from '@/graphql'

import { currentAgencyVar, currentTenantVar } from '@/util/apollo/cache'
import comet from '@/util/comet'
import debug from '@/util/debug'

const Agency = () => {
  const dispatch = useAppDispatch()

  const currentTenant = useReactiveVar(currentTenantVar)
  const currentAgency = useReactiveVar(currentAgencyVar)

  const isSmallDesktop = useMediaQuery('(min-width: 900px)')

  const query = useGetAgencyByKeyQuery({
    variables: { tenantId: currentTenant!.id, key: 'gravy-dmv' },
  })
  const [authenticateChatService] = useAuthenticateChatServiceMutation()

  useEffect(() => {
    if (query.data) {
      currentAgencyVar(query.data.agencyByKey)
    }

    return () => {
      currentAgencyVar(null)
    }
  }, [query.data])

  useEffect(() => {
    if (currentAgency) {
      authenticateChatService({ variables: { agencyId: currentAgency.id } })
        .then((result) => {
          debug.log(
            '[Agency] Got chat service auth token:',
            result.data!.chatServiceAuthenticateAgency
          )

          comet
            .login(result.data!.chatServiceAuthenticateAgency)
            .catch((err) => {
              debug.error('[Agency] Unable to login chat user')
              debug.error(err)
            })
        })
        .catch((err) => {
          debug.error(
            '[Agency] Unable to get chat service auth token for agency:',
            currentAgency.key
          )
          debug.error(err)
        })
    }

    return () => {
      if (currentAgency) {
        comet.logout().catch((err) => {
          debug.error('[Agency] Unable to log out chat service user')
          debug.error(err)
        })
      }
    }
  }, [currentAgency])

  useEffect(() => {
    isSmallDesktop && dispatch(setSidebarVisibility(true))
  }, [isSmallDesktop])

  return (
    <AppFrame
      Footer={<AppFooter />}
      Navbar={<AppNavbar />}
      Sidebar={<Sidebar />}
      isSmallDesktop={isSmallDesktop}
    >
      {currentAgency ? (
        <Outlet />
      ) : (
        <LoadingState text="Loading your agency's data..." />
      )}
    </AppFrame>
  )
}

export default Agency
