import useModal from '@area2k/use-modal'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import qs from 'query-string'

import { GetJobQuery } from '@/types/graphql'

import Icon from '@/elements/Icon'
import Link from '@/elements/Link'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Small } from '@/components/Typography'

import UpdateAddressModal from './UpdateAddressModal'

type Props = {
  job: GetJobQuery['job']
}

const AddressSection = ({ job }: Props) => {
  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateAddressModal job={job} hideModal={hideUpdateModal} />,
    [job]
  )

  const { address, addressInstructions } = job
  const mapsParams = {
    query: `${address.addressLine1},${address.city},${address.state}`,
  }
  const mapsUrl = `https://www.google.com/maps/search/?api=1&${qs.stringify(
    mapsParams
  )}`

  return (
    <Card.Section
      actions={[
        {
          a11yLabel: 'Change job address instructions',
          label: 'Change',
          onAction: showUpdateModal,
        },
      ]}
      title="Address"
    >
      <Stack vertical>
        <TextStack>
          <Link as="a" href={mapsUrl} target="_blank" rel="noopener noreferrer">
            {address.addressLine1}{' '}
            <Small color="inherit">
              <Icon icon={faExternalLinkAlt} />
            </Small>
          </Link>
          {address.addressLine2 && <Small>{address.addressLine2}</Small>}
          <Small>
            {address.city}, {address.state} {address.zip}
          </Small>
        </TextStack>
        {addressInstructions && <Small>"{addressInstructions}"</Small>}
      </Stack>
    </Card.Section>
  )
}

export default AddressSection
