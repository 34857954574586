import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useModal } from 'react-modal-hook'

import { WorkerFilterSetInput } from '@/types/graphql'
import useMediaQuery from '@/hooks/useMediaQuery'

import ButtonGroup from '@/elements/ButtonGroup'

import Button from '@/components/Button'
import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'
import IconicButton from '@/components/IconicButton'

import WorkerFiltersModal from './WorkerFiltersModal'

type Props = {
  filters: WorkerFilterSetInput
  query: string
  onQueryChange: (query: string) => void
  onChangeFilter: <K extends keyof WorkerFilterSetInput>(
    key: K,
    value: NonNullable<WorkerFilterSetInput[K]>
  ) => void
  onClearFilter: (key: keyof WorkerFilterSetInput) => void
  onClearAll: () => void
}

const CandidateFilters = ({
  filters,
  query,
  onQueryChange,
  onChangeFilter,
  onClearFilter,
  onClearAll,
}) => {
  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <WorkerFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
      />
    ),
    [filters]
  )
  const phoneOnly = useMediaQuery('(max-width: 559px)')

  return (
    <Stack vertical gap={16}>
      <Stack gap={16}>
        <div style={{ flex: 1 }}>
          <TextInput
            placeholder={`Search for workers...`}
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>

        {!phoneOnly ? (
          <ButtonGroup>
            <Button
              a11yLabel="View all workers filters"
              appearance="outline"
              label="Filters"
              onClick={showFiltersModal}
            />
          </ButtonGroup>
        ) : (
          <IconicButton
            a11yLabel="viewFilters"
            appearance="clear"
            icon={faFilter}
            size="sm"
            onClick={showFiltersModal}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default CandidateFilters
