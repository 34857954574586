import { format } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { useModal } from 'react-modal-hook'

import { GetJobQuery, TimesheetStatusEnum } from '@/types/graphql'

import Table from '@/components/Table'
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table'

import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Stack from '@/components/Stack'
import Tabs, { TabDefinition } from '@/components/Tabs'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import ApproveTimesheetModal from './ApproveTimesheetModal'

import { getCannonicalTime, JobStatus } from '../util'

const PENDING_TAB = 0
const TABS: TabDefinition[] = [
  { a11yLabel: 'View pending timesheets for this job', label: 'Pending' },
  { a11yLabel: 'View approved timesheets for this job', label: 'Approved' },
]

type TimesheetItem = GetJobQuery['job']['timesheets'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const TimesheetList = ({ job, jobStatus }: Props) => {
  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const [
    currentTimesheet,
    setCurrentTimesheet,
  ] = useState<TimesheetItem | null>(null)

  const [showApproveModal, hideApproveModal] = useModal(
    () => (
      <ApproveTimesheetModal
        jobCoords={job.address.coords}
        timesheet={currentTimesheet!}
        hideModal={hideApproveModal}
      />
    ),
    [currentTimesheet]
  )

  const handleView = useCallback((timesheet: TimesheetItem) => {
    setCurrentTimesheet(timesheet)

    if (timesheet.status !== TimesheetStatusEnum.APPROVED) {
      showApproveModal()
    } else {
      // show view only modal
    }
  }, [])

  const renderItem = useCallback(
    (timesheet: TimesheetItem) => {
      const cannonicalCheckin = getCannonicalTime(
        timesheet.shift.startAt,
        timesheet.checkinAt,
        timesheet.reportedCheckinAt
      )

      const cannonicalCheckout = getCannonicalTime(
        timesheet.shift.startAt,
        timesheet.checkoutAt,
        timesheet.reportedCheckoutAt
      )

      return (
        <TableRow key={timesheet.id}>
          <TableCell>
            <Stack gap={20}>
              <div>
                <Avatar
                  firstName={timesheet.jobWorker.worker.user.firstName}
                  size="sm"
                  src={timesheet.jobWorker.worker.avatarUrl}
                />
              </div>
              <TextStack>
                <Body weight="medium">
                  {timesheet.jobWorker.worker.user.firstName}{' '}
                  {timesheet.jobWorker.worker.user.lastName}
                </Body>
                <Small>{timesheet.jobWorker.worker.user.email}</Small>
              </TextStack>
            </Stack>
          </TableCell>
          <TableCell>
            {cannonicalCheckin && cannonicalCheckout ? (
              <TextStack>
                <Body>
                  {format(cannonicalCheckin, 'p')} &mdash;{' '}
                  {format(cannonicalCheckout, 'p')}
                </Body>
                <Small>{format(cannonicalCheckin, 'PP')}</Small>
              </TextStack>
            ) : (
              <Small>Incomplete</Small>
            )}
          </TableCell>
          <TableCell style={{ textAlign: 'right' }}>
            <Button
              a11yLabel="View timesheet"
              appearance="plain"
              label="View"
              onClick={() => handleView(timesheet)}
            />
          </TableCell>
        </TableRow>
      )
    },
    [isJobActive]
  )

  const [tab, setTab] = useState(0)

  const [approvedTimesheets, pendingTimesheets] = useMemo(() => {
    const approved: TimesheetItem[] = []
    const pending: TimesheetItem[] = []

    job.timesheets.forEach((timesheet) => {
      if (timesheet.status === TimesheetStatusEnum.APPROVED) {
        approved.push(timesheet)
      } else {
        pending.push(timesheet)
      }
    })

    return [approved, pending]
  }, [job.timesheets])

  const currentTimesheets =
    tab === PENDING_TAB ? pendingTimesheets : approvedTimesheets

  return (
    <Card title="Timesheets">
      <Tabs fit selected={tab} tabs={TABS} onSelect={setTab} />
      {currentTimesheets.length > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading style={{ width: '50%' }}>Worker</TableHeading>
              <TableHeading style={{ width: '40%' }}>Hours</TableHeading>
              <TableHeading style={{ width: '10%' }} />
            </TableRow>
          </TableHead>
          <tbody>{currentTimesheets.map(renderItem)}</tbody>
        </Table>
      ) : (
        <EmptyState title="No timesheets" text="No timesheets were found." />
      )}
    </Card>
  )
}
export default TimesheetList
