import { useCallback, useState } from 'react'

import { GetWorkerQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import EditAddress from '@/components/EditAddress'
import Stack from '@/components/Stack'

import { useChangeWorkerAddressMutation } from '@/graphql'

export type Props = {
  worker: GetWorkerQuery['worker']
  hideModal: () => void
}

type FormValues = {
  addressLine1: GetWorkerQuery['worker']['addressLine1']
  addressLine2: GetWorkerQuery['worker']['addressLine2']
  city: GetWorkerQuery['worker']['city']
  state: GetWorkerQuery['worker']['state']
  zip: GetWorkerQuery['worker']['zip']
  coords: GetWorkerQuery['worker']['coords']
}

const ChangeAddressModal = ({ worker, hideModal }: Props) => {
  const [values, setValues] = useState<FormValues>({
    addressLine1: worker.addressLine1,
    addressLine2: worker.addressLine2,
    city: worker.city,
    state: worker.state,
    zip: worker.zip,
    coords: worker.coords,
  })

  const [
    changeWorkerAddress,
    { loading: isLoading },
  ] = useChangeWorkerAddressMutation()

  const handleSubmit = useCallback(async () => {
    try {
      await changeWorkerAddress({
        variables: { workerId: worker.id, ...values },
      })
      hideModal()
    } catch (error) {
      console.error(error)
    }
  }, [worker.id, values])

  return (
    <Modal size="sm" title="Change worker address" onRequestClose={hideModal}>
      <Card.Section>
        <EditAddress values={values} setValues={setValues} />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit form"
            isLoading={isLoading}
            label="Save"
            type="button"
            onClick={() => handleSubmit()}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ChangeAddressModal
