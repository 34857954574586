import { useSelect, UseSelectProps } from 'downshift'
import { useCallback, useState } from 'react'

import styled from '@/styles'

import Input from '@/elements/Input'

import Item from './Item'
import Menu from './Menu'

const Wrapper = styled('div', {
  focusPseudoElement: {
    element: 'after',
    activator: '&.focused',
  },
})

Wrapper.displayName = 'stitches(ItemSelect.Wrapper)'

export type RenderItemProps<T> = {
  index: number
  item: T
  isHighlighted: boolean
  isSelected: boolean
}

export type Props<T> = {
  disabled?: boolean
  id: string
  items: UseSelectProps<T>['items']
  selectedItem: T | null
  itemToKey: (item: T) => string | number
  itemToString: NonNullable<UseSelectProps<T>['itemToString']>
  onSelectedItemChange: UseSelectProps<T>['onSelectedItemChange']
  placeholder?: string
  renderItem?: (props: RenderItemProps<T>) => JSX.Element
  required?: boolean
  stateReducer?: UseSelectProps<T>['stateReducer']
  fixedSelectHeight?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'auto'
    | {
        [x: string]: 'sm' | 'md' | 'lg' | 'auto' | undefined
        initial?: 'sm' | 'md' | 'lg' | 'auto' | undefined
      }
    | undefined
}

const emptyStateReducer = (_state, { changes }) => changes

const ItemSelect = <T extends object>({
  id,
  items,
  selectedItem: providedSelectedItem,
  itemToKey,
  itemToString,
  placeholder = '',
  onSelectedItemChange,
  renderItem: providedRenderItem,
  stateReducer = emptyStateReducer,
  fixedSelectHeight = 'lg',
  ...inputProps
}: Props<T>) => {
  const {
    highlightedIndex,
    isOpen,
    selectedItem,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
  } = useSelect({
    id,
    items,
    selectedItem: providedSelectedItem,
    itemToString,
    stateReducer,
    onSelectedItemChange,
  })

  const [isInputFocused, setIsInputFocused] = useState(false)

  const defaultRenderItem = useCallback(
    ({ index, item, ...rest }: RenderItemProps<T>) => (
      <Item {...rest}>{itemToString(item)}</Item>
    ),
    [itemToString]
  )

  const renderItem = providedRenderItem || defaultRenderItem

  return (
    <div>
      <Wrapper className={isInputFocused ? 'focused' : undefined}>
        <Input
          {...getToggleButtonProps({
            onBlur: () => {
              setIsInputFocused(false)
            },
            onFocus: () => {
              setIsInputFocused(true)
            },
          })}
          {...inputProps}
          type="button"
          value={
            !selectedItem
              ? placeholder
              : items.length > 0 && itemToString(selectedItem)
          }
        />
      </Wrapper>
      <Menu
        {...getMenuProps()}
        as="ul"
        size="parent"
        status={isOpen && items.length > 0 ? 'open' : 'closed'}
        height={fixedSelectHeight}
      >
        {items.map((item, index) => (
          <li key={itemToKey(item)} {...getItemProps({ item, index })}>
            {renderItem({
              item,
              index,
              isHighlighted: highlightedIndex === index,
              isSelected:
                !!selectedItem && itemToKey(item) === itemToKey(selectedItem),
            })}
          </li>
        ))}
      </Menu>
    </div>
  )
}

export default ItemSelect
