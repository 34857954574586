import { GetWorkerQuery } from '@/types/graphql'

import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import Comments from '../CommentsCard/Coments'
import InterviewCard from './InterviewCard'
import JobsList from '../JobList'
import ProfileCard from '../ProfileCard'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const WorkerCompleted = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  return (
    <PrimarySecondaryLayout>
      <PrimarySecondaryLayout.Primary>
        <Stack vertical gap={24}>
          <JobsList
            worker={worker}
            currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          />
        </Stack>
      </PrimarySecondaryLayout.Primary>
      <PrimarySecondaryLayout.Secondary>
        <Stack vertical gap={24}>
          <ProfileCard
            worker={worker}
            currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          />
          <Comments
            worker={worker}
            currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
          />
          <InterviewCard worker={worker} />
        </Stack>
      </PrimarySecondaryLayout.Secondary>
    </PrimarySecondaryLayout>
  )
}

export default WorkerCompleted
