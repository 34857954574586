import { useState } from 'react'
import { useParams } from 'react-router'
import { useReactiveVar } from '@apollo/client'
import { faCamera } from '@fortawesome/free-solid-svg-icons'

import { useGetCustomerQuery } from '@/graphql'

import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import { currentAdminVar } from '@/util/apollo/cache'
import { Role } from '@/routes/PrivateRoute'

import AccountList from './AccountList'
import AddressList from './AddressList'
import AdminList from './AdminList'
import CustomRates from './CustomRates'
import OrdersList from './OrdersList'
import PageSkeleton from './PageSkeleton'
import SettingsCard from './SettingsCard'
import useChangeLogoModal from './useChangeLogoModal'
import WorkerList from './WorkerList'

const Customer = () => {
  const { customerId: customerIdByParam } = useParams()

  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN
  const customerId = customerIdByParam
    ? customerIdByParam
    : currentAdminIsCustomerAdmin
    ? currentAdmin!.customer!.id
    : ''
  const query = useGetCustomerQuery({ variables: { customerId } })

  const showLogoModal = useChangeLogoModal(query.data?.customer)

  if (!query.data) {
    return <PageSkeleton />
  }

  const customer = query.data.customer

  return (
    <Page
      media={
        <Avatar firstName={customer.name} size="md" src={customer.logoUrl} />
      }
      primaryAction={{
        a11yLabel: 'Change customer logo',
        label: 'Change logo',
        icon: faCamera,
        onAction: showLogoModal,
      }}
      title={customer.name}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <AdminList
              customer={customer}
              currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
            />
            <AccountList
              customer={customer}
              currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
            />
            <AddressList customer={customer} />
            <CustomRates
              customer={customer}
              currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
            />
            <WorkerList customer={customer} />
            <OrdersList customer={customer} />
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <SettingsCard
              customer={customer}
              currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
            />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Customer
