import { useCallback, useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'

import {
  GetWorkerJobsQuery,
  GetWorkerQuery,
  JobFilterSetInput,
  JobStatusEnum,
  TimelineStatusEnum,
} from '@/types/graphql'
import { useGetWorkerJobsQuery } from '@/graphql'
import ROUTES from '@/routes/routes'

import Card from '@/components/Card'
import QueryEmptyState from '@/components/QueryEmptyState'
import Tabs, { TabDefinition } from '@/components/Tabs'
import Avatar from '@/components/Avatar'
import Table from '@/components/Table'
import TableHeader from '@/components/TableHeader'
import { TableCell, TableRow } from '@/elements/Table'
import Stack from '@/components/Stack'
import Link from '@/elements/Link'
import Badge from '@/components/Badge'
import Button from '@/components/Button'
import TextInput from '@/components/TextInput'
import Dropdown from '@/components/Dropdown'
import JobStatusFilter from './JobStatusFilter'
import useFilters from '@/hooks/useFilters'
import LoadingState from '@/components/LoadingState'
import { currentAdminVar } from '@/util/apollo/cache'
import { useReactiveVar } from '@apollo/client'

const JOBS_TABS: TabDefinition[] = [
  { a11yLabel: 'View upcoming jobs for this worker', label: 'Upcoming' },
  { a11yLabel: 'View past jobs for this worker', label: 'Past' },
]

const initialFilters: JobFilterSetInput = {}

type JobItem = GetWorkerJobsQuery['allJobsWorker'][0]['jobs']['items'][0]

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const JobsList = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [tab, setTab] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState('ALL')
  const currentAdmin = useReactiveVar(currentAdminVar)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<JobFilterSetInput>(initialFilters)

  const finalFilters = useMemo<JobFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const query = useGetWorkerJobsQuery({
    variables: {
      filters: finalFilters,
      workerId: worker.id,
    },
  })

  const headerFields = useMemo(
    () => [
      ['Skill', 'Customer', 'Timeframe', 'Status'],
      ['Skill', 'Customer', 'Timeframe', 'Status'],
    ],
    []
  )

  const JobTypeBadge = ({ status }: { status: JobItem['status'] }) => {
    switch (status) {
      case JobStatusEnum.COMPLETED:
        return <Badge label="Completed" status="theme" />
      case JobStatusEnum.UPCOMING:
        return <Badge label="Upcoming" status="success" />
      case JobStatusEnum.IN_PROGRESS:
        return <Badge label="In progress" status="warning" />
      case JobStatusEnum.CANCELLED:
        return <Badge label="Cancelled" status="danger" />
      default:
        return <></>
    }
  }

  const TableBody = useCallback(
    ({ jobs }: { jobs: JobItem[] }) => (
      <tbody>
        {jobs.map((item: JobItem) => {
          const permittedNavigate =
            currentAdminIsCustomerAdmin &&
            currentAdmin!.customer!.id === item.customer.id

          return (
            <TableRow key={item.id}>
              <TableCell>
                <Stack gap={12}>
                  <Avatar size="xs" src={item.skill.imageUrl} />
                  <Link
                    to={
                      !currentAdminIsCustomerAdmin
                        ? `../../orders/${item.order.id}/jobs/${item.id}`
                        : permittedNavigate
                        ? `../../orders/${item.order.id}/jobs/${item.id}`
                        : ''
                    }
                  >
                    {item.skill.name}
                  </Link>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack gap={12}>
                  <div>
                    <Avatar
                      firstName={item.customer.name}
                      size="xs"
                      src={item.customer.logoUrl}
                    />
                  </div>
                  <Link
                    to={
                      !currentAdminIsCustomerAdmin
                        ? `../../customers/${item.customer.id}`
                        : permittedNavigate
                        ? `../../${ROUTES.myCompany}`
                        : ''
                    }
                  >
                    {item.customer.name}
                  </Link>
                </Stack>
              </TableCell>
              <TableCell>
                {format(new Date(item.firstShiftStartAt), 'PP')} &mdash;{' '}
                {format(new Date(item.lastShiftEndAt), 'PP')}
              </TableCell>
              <TableCell align="center">
                <JobTypeBadge status={item.status} />
              </TableCell>
            </TableRow>
          )
        })}
        {query.loading && (
          <TableRow>
            <TableCell colSpan={headerFields.length + 2} align="center">
              <LoadingState overlayColor="white" />
            </TableCell>
          </TableRow>
        )}
      </tbody>
    ),
    [currentAdmin, query]
  )

  const jobs = query.data
    ? query.data.allJobsWorker.flatMap((object) => object.jobs.items)
    : []

  useEffect(() => {
    tab === 0
      ? setFilter('timelineStatus', TimelineStatusEnum.UPCOMING)
      : setFilter('timelineStatus', TimelineStatusEnum.PAST)
    setSelectedStatus('ALL')
  }, [tab])

  return (
    <Card title="Jobs">
      <Tabs fit selected={tab} tabs={JOBS_TABS} onSelect={setTab} />
      <Card.Section>
        <Stack gap={16}>
          <div style={{ flex: 1 }}>
            <TextInput
              placeholder="Search for jobs..."
              value={searchQuery}
              onChange={(ev) => {
                setQuery(ev.currentTarget.value)
              }}
            />
          </div>
          <Dropdown
            Content={() => (
              <div style={{ padding: 16 }}>
                <JobStatusFilter
                  idPrefix="dropdown"
                  onChangeFilter={setFilter}
                  onClearFilter={clearFilter}
                  jobStatusValue={selectedStatus}
                  setJobStatusValue={setSelectedStatus}
                />
              </div>
            )}
            justify="right"
            size="sm"
          >
            <Button
              hasPopover
              a11yLabel="View job status filters"
              appearance="outline"
              label="Job status"
            />
          </Dropdown>
        </Stack>
      </Card.Section>
      <Card>
        <Table>
          <TableHeader fields={headerFields[tab]} />
          <TableBody jobs={jobs} />
        </Table>
        {!query.loading && jobs.length === 0 && (
          <QueryEmptyState
            query={query}
            title="No Jobs"
            text="If there were any, they would be here."
          />
        )}
      </Card>
    </Card>
  )
}

export default JobsList
