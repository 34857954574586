export const zeroPad = (n: number, targetLength: number) => {
  const nString = String(n)

  return `${'0'.repeat(Math.max(targetLength - nString.length, 0))}${nString}`
}

export const centsToCurrency = (n: number) => {
  const total = (n / 100).toFixed(2)
  const [dollars, cents] = total.split('.')

  return `${Number(dollars).toLocaleString()}.${cents}`
}
