import { useState, useEffect, SetStateAction, Dispatch } from 'react'
import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import { AllFiltersProps } from '@/filters'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import NumericCompareFilter from '@/filters/NumericCompareFilter'

import OrderStatusFilter from './OrderStatusFilter'
import { OrderStatus } from './index'

type Props = AllFiltersProps<OrderFilterSetInput> & {
  hideModal: () => void
  orderStatusValue: OrderStatus
  setOrderStatusValue: Dispatch<SetStateAction<OrderStatus>>
}

const OrderFiltersModal = ({
  filters,
  hideModal,
  onChangeFilter,
  onClearAll,
  onClearFilter,
  orderStatusValue,
  setOrderStatusValue,
}: Props) => {
  const [able, setAble] = useState(false)
  const [filterParams, setFilterParams] = useState(filters)

  const [
    refSelectedOrderValue,
    setRefSelectedOrderValue,
  ] = useState<OrderStatus>(orderStatusValue)

  const handleOnChangeFilter = <K extends keyof OrderFilterSetInput>(
    key: K,
    value: NonNullable<OrderFilterSetInput[K]>
  ) => setFilterParams((prevValue) => ({ ...prevValue, [key]: value }))

  const handleOnClearFilter = (key: keyof OrderFilterSetInput) => {
    const updatedFilterParams = { ...filterParams }
    delete updatedFilterParams[key]
    setFilterParams(updatedFilterParams)
  }

  useEffect(() => {
    if (able && Object.keys(filterParams).length !== 0) {
      onClearAll()

      Object.keys(filterParams).map((key: any) => {
        onChangeFilter(key, filterParams[key])
      })
    }
  }, [able])

  return (
    <Modal
      size="sm"
      title="Change Filters"
      onRequestClose={hideModal}
      disableClickout
    >
      <Card.Section title="Order status">
        <OrderStatusFilter
          filters={filters}
          idPrefix="modal"
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
          orderStatusValue={refSelectedOrderValue}
          setOrderStatusValue={setRefSelectedOrderValue}
        />
      </Card.Section>
      <Card.Section title="Jobs count">
        <NumericCompareFilter
          filterKey="jobsCount"
          filters={filters}
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
        />
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Clear all order filters"
            appearance="outline"
            label="Clear all"
            onClick={() => {
              onClearAll()
              hideModal()
              setOrderStatusValue(OrderStatus.ALL)
            }}
          />
          <Button
            a11yLabel="Close modal"
            label="Done"
            onClick={() => {
              setAble(true)
              hideModal()
              setOrderStatusValue(refSelectedOrderValue)
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default OrderFiltersModal