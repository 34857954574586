import { useCallback, useState } from 'react'

import { GetCustomerQuery, OrderListItemFragment } from '@/types/graphql'

import Table from '@/components/Table'

import Card from '@/components/Card'
import LoadingState from '@/components/LoadingState'
import OrderTableItem from '@/components/OrderTableItem'
import Pagination from '@/components/Pagination'
import TableHeader from '@/components/TableHeader'
import QueryEmptyState from '@/components/QueryEmptyState'

import { useGetCustomerOrdersQuery } from '@/graphql'

type Props = {
  customer: GetCustomerQuery['customer']
}

const OrdersList = ({ customer }: Props) => {
  const [page, setPage] = useState(1)

  const query = useGetCustomerOrdersQuery({
    variables: {
      customerId: customer.id,
      page,
      perPage: 10,
    },
  })

  const renderRow = useCallback(
    (item: OrderListItemFragment) => (
      <OrderTableItem item={item} to={`../../orders/${item.id}`} />
    ),
    []
  )

  if (!query.data) {
    return (
      <Card title="Orders">
        <LoadingState />
      </Card>
    )
  }

  const { orders } = query.data.customer

  return (
    <Card title="Orders">
      <Table>
        <TableHeader fields={['ID', 'Timeframe', 'Size']} />
        <tbody>{orders.items.map(renderRow)}</tbody>
      </Table>
      {orders.items.length == 0 && (
        <QueryEmptyState
          query={query}
          title="No orders created"
          text="If there were any, they would be here."
        />
      )}
      {orders.items.length > 0 && (
        <Card.Section subdued>
          <Pagination
            currentPage={page}
            totalPages={orders.pageInfo.totalPages}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  )
}

export default OrdersList
