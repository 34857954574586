import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'
import { useEffect } from 'react'

import { ListAgencyUniformsQuery, GetJobQuery } from '@/types/graphql'

import ItemSelectField from '@/form/ItemSelectField'

import { useListAgencyUniformsLazyQuery } from '@/graphql'

export type UniformItem = ListAgencyUniformsQuery['agency']['uniforms'][0]

type Props = {
  fieldId: string
  label: string
}

const UniformSelectField = ({ fieldId, label }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)

  const [fetch, { data, loading }] = useListAgencyUniformsLazyQuery()

  useEffect(() => {
    fetch({ variables: { agencyId: currentAgency!.id } })
  }, [])

  return (
    <ItemSelectField<UniformItem>
      required
      disabled={loading}
      fieldId={fieldId}
      items={data ? data.agency.uniforms : []}
      itemToKey={(item) => item.id}
      itemToString={(item) => (item ? item.name : '')}
      label={label}
    />
  )
}

export default UniformSelectField
