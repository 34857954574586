import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'
import Link from '@/elements/Link'
import { Maybe } from '@/types'
import { useLocation } from 'react-router'
import { Billing as BillingType } from '../../../context'

type Props = {
  hideModal: () => void
  onConfirm: () => void
  customer: Maybe<BillingType['customer']>
  account: Maybe<BillingType['account']>
}

const RequiredOneRateQuoteBySelectedAccount = ({
  hideModal,
  onConfirm,
  customer,
  account,
}: Props) => {
  const { pathname: currentPathname } = useLocation()

  return (
    <Modal size="xs" title="Custom rates" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            There are no custom rates associated with the <b>{account?.name}</b>{' '}
            account, create one in the customer profile.
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
            <Link to={`${currentPathname}/../../customers/${customer!.id}`}>
              <Button
                a11yLabel="Go to customer detail"
                appearance="primary"
                label="Go to create"
                onClick={onConfirm}
              />
            </Link>
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default RequiredOneRateQuoteBySelectedAccount
