import { PropsWithChildren, ReactNode } from 'react'

import { GenericAction, GenericActionOrList } from '@/types'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import styled from '@/styles'

import Action from '@/components/Action'
import Breadcrumbs, {
  Props as BreadcrumbsProps,
} from '@/components/Breadcrumbs'
import SingleColumnLayout, {
  Props as SingleColumnLayoutProps,
} from '@/components/SingleColumnLayout'
import Stack from '@/components/Stack'
import { Subheading, Title } from '@/components/Typography'
import useMediaQuery from '@/hooks/useMediaQuery'
import IconicButton from '@/components/IconicButton'
import DropdownMenu from './DropdownMenu'

const Header = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  padding: '32px 0 0',
})

const listOptions = { justify: 'right' } as const

export type Props = SingleColumnLayoutProps & {
  breadcrumbs?: BreadcrumbsProps['crumbs']
  media?: ReactNode
  primaryAction?: GenericAction
  secondaryActions?: GenericActionOrList[]
  subtitle?: ReactNode
  title?: ReactNode
}

const Page = ({
  breadcrumbs,
  secondaryActions,
  children,
  media = null,
  primaryAction,
  size = 'lg',
  subtitle,
  title,
  ...variants
}: PropsWithChildren<Props>) => {
  const hasHeader =
    subtitle ||
    title ||
    primaryAction ||
    media ||
    (secondaryActions && secondaryActions.length > 0) ||
    (breadcrumbs && breadcrumbs.length > 0)

  const phoneOnly = useMediaQuery('(max-width: 559px)')

  const pushElements = (primaryArray, secondaryArray) => {
    let primaryArrayClone = [...primaryArray]
    for (let index = 0; index < secondaryArray.length; index++) {
      const action = secondaryArray[index]
      primaryArrayClone = [...primaryArrayClone, [{ ...action }]]
    }
    return primaryArrayClone
  }

  const generateDropdownOptions = (primaryAction, secondaryActions) => {
    let actions: GenericAction[][] = []

    if (primaryAction) actions = [...actions, [{ ...primaryAction }]]

    if (secondaryActions) {
      if (secondaryActions.actions && secondaryActions.actions[0].length > 0) {
        actions = [...pushElements(actions, secondaryActions.actions[0])]
      } else {
        actions = [...actions, [{ ...secondaryActions }]]
      }
    }
    return actions
  }

  return (
    <SingleColumnLayout size={size} {...variants}>
      {hasHeader && (
        <Header>
          <Stack gap={24}>
            {media && <div>{media}</div>}
            <Stack vertical gap={2}>
              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumbs crumbs={breadcrumbs} />
              )}
              {typeof title === 'string' ? (
                <Title>{title}</Title>
              ) : title === undefined ? null : (
                title
              )}
              {typeof subtitle === 'string' ? (
                <Subheading color="light">{subtitle}</Subheading>
              ) : subtitle === undefined ? null : (
                subtitle
              )}
            </Stack>
          </Stack>
          {!phoneOnly ? (
            <Stack gap={8} justify="end">
              {secondaryActions && (
                <>
                  {secondaryActions.map((action, index) => (
                    <Action.Button
                      key={index}
                      action={action}
                      appearance={!!action.onAction ? 'secondary' : 'clear'}
                      listOptions={listOptions}
                    />
                  ))}
                </>
              )}
              {primaryAction && (
                <Action.Button action={primaryAction} appearance="primary" />
              )}
            </Stack>
          ) : (
            <Stack justify="end">
              {(primaryAction || secondaryActions) && (
                <DropdownMenu
                  actions={generateDropdownOptions(
                    primaryAction,
                    secondaryActions && secondaryActions[0]
                  )}
                  justify="right"
                  size="md"
                >
                  <IconicButton
                    a11yLabel="actions"
                    icon={faChevronDown}
                    size="lg"
                  />
                </DropdownMenu>
              )}
            </Stack>
          )}
        </Header>
      )}
      <div style={{ margin: '32px 0' }}>{children}</div>
    </SingleColumnLayout>
  )
}

Page.Header = Header
Header.displayName = 'stitches(Page.Header)'

export default Page