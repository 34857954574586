import { useCallback, useState } from 'react'
import useModal from '@area2k/use-modal'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { GetWorkerQuery, WorkerReference } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import MoreInfoModal from './MoreInfoModal'

type Item = GetWorkerQuery['worker']['references'][0]

type Props = {
  worker: GetWorkerQuery['worker']
}

const ExperiencesList = ({ worker }: Props) => {
  const [selectedReferenceItem, setSelectedReferenceItem] = useState<WorkerReference[]>()
  
  const [showMoreInfoModal, hideMoreInfoModal] = useModal(
    () => (
      <MoreInfoModal
        reference={selectedReferenceItem}
        hideModal={hideMoreInfoModal}
      />
    ),
    [selectedReferenceItem]
  )

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item
        key={item.id}
        to={'.'}
        actions={[
          {
            a11yLabel: 'More information reference',
            icon: faSearch,
            onAction: () => {
              setSelectedReferenceItem(item)
              showMoreInfoModal()
            },
          },
        ]}
      >
        <Stack>
          <TextStack>
            <Body>{item.employerName}</Body>
            <Small>{item.name}</Small>
            <Small>{item.city}</Small>
            <Small color="theme">{item.duration}</Small>
          </TextStack>
        </Stack>
      </ResourceList.Item>
    ),
    []
  )
  return (
    <Card
      actions={[
        {
          a11yLabel: 'Add a new experience',
          label: 'Add',
          onAction: () => {},
        },
      ]}
      title="Experiences"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No previous experiences added "
            text={`You can add some worker experience by pressing the "Add" button`}
          />
        }
        items={worker.references}
        renderItem={renderItem}
      />
    </Card>
  )
}

export default ExperiencesList
