import useModal from '@area2k/use-modal'
import { GetAccountQuery } from '@/types/graphql'
import { useReactiveVar } from '@apollo/client'

import { Role } from '@/routes/PrivateRoute'
import { currentAdminVar } from '@/util/apollo/cache'
import ROUTES from '@/routes/routes'

import Link from '@/elements/Link'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body } from '@/components/Typography'
import UpdateContactModal from './UpdateContactModal'

type Props = {
  account: GetAccountQuery['account']
}

const ProfileCard = ({ account }: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN

  const [showCreateModal, hideCreateModal] = useModal(
    () => <UpdateContactModal account={account} hideModal={hideCreateModal} />,
    [account]
  )

  return (
    <Card
      title="Default Contact"
      actions={[
        {
          a11yLabel: 'Change Contact',
          label: 'Change',
          onAction: showCreateModal,
        },
      ]}
    >
      <Card.Section>
        <Stack gap={20}>
          <div>
            <Avatar
              firstName={account.defaultContact.user.firstName}
              size="md"
              src={account.defaultContact?.avatarUrl}
            />
          </div>
          <TextStack>
            <Link
              to={
                currentAdminIsCustomerAdmin
                  ? `../../../${ROUTES.myTeam}/${account.defaultContact.id}`
                  : `../../admin/${account.defaultContact.id}`
              }
            >
              <Body color="inherit" weight="medium">
                {account.defaultContact.user.firstName}{' '}
                {account.defaultContact.user.lastName}
              </Body>
            </Link>
            <Body color="light">{account.defaultContact?.user.email}</Body>
            <Body color="light">
              {account.defaultContact?.user.phoneNumber}
            </Body>
          </TextStack>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default ProfileCard
