const routes = {
  basePath: '/',

  orders: 'orders',
  ordersCreate: 'create',
  orderDetail: ':orderId',
  orderJobDetail: ':orderId/jobs/:jobId',

  customers: 'customers',
  customerDetail: ':customerId',
  customerAccountDetail: ':customerId/accounts/:accountId',
  customerAdminDetail: ':customerId/admin/:adminId',

  contacts: 'contacts',

  workers: 'workers',
  workerDetail: ':workerId',

  chat: 'chat',
  chatDetail: ':chatUid',

  // Customer Admin
  myTeam: 'my-team',
  myTeamAdminDetail: ':adminId',
  myCompany: 'my-company',
  myCompanyAccountDetail: 'accounts/:accountId',
  myProfile: 'my-profile',

  login: 'login',
  signOut: 'sign-out',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
}

export default routes
