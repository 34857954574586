import { StitchesVariants } from '@stitches/react'

import styled from '@/styles'

import Tab from './Tab'
import { TabDefinition } from './types'

import TextSelectField from '@/form/TextSelectField'
import Form from '@/form'

const Wrapper = styled('div', {
  padding: '0 8px',

  borderBottom: '1px solid $colors$neutralLightest',
})

const SelectWrapper = styled('div', {
  display: 'none',
  '@phoneOnly': {
    width: '100%',
    display: 'flex',
    padding: '12px 12px 0px',
  },
})

const TabList = styled('ul', {
  display: 'flex',
  margin: 0,
  padding: 0,
  listStyle: 'none',

  '@phoneOnly': {
    display: 'none',
  },

  variants: {
    fit: {
      true: {
        [`& > ${Tab.Wrapper}`]: {
          flex: 1,
        },
      },
      false: {},
    },
  },
})

type Variants = StitchesVariants<typeof TabList>

export type Props = Variants & {
  selected: number
  tabs: TabDefinition[]
  onSelect: (tab: number) => void
}

const Tabs = ({ selected, tabs, onSelect, ...variants }: Props) => {
  const initialValues = {
    worker: 0,
  }
  return (
    <Wrapper>
      <TabList {...variants} role="tablist">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            index={index}
            isActive={selected === index}
            tab={tab}
            onSelect={onSelect}
          />
        ))}
      </TabList>
      <SelectWrapper>
        <div style={{ width: '100%' }}>
          <Form initialValues={initialValues} onSubmit={async () => {}}>
            <TextSelectField
              fieldId="worker"
              label=" "
              options={tabs.map((tab, index) => {
                return { label: tab.label, value: index.toString() }
              })}
              callback={({ value }) => {
                onSelect(parseInt(value))
              }}
            />
          </Form>
        </div>
      </SelectWrapper>
    </Wrapper>
  )
}

Tabs.Wrapper = Wrapper
Wrapper.displayName = 'stitches(Tabs.Wrapper)'

Tabs.TabList = TabList
TabList.displayName = 'stithces(Tabs.TabList)'

Tabs.Tab = Tab

export type { TabDefinition }

export default Tabs
