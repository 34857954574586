import { Link as ReactRouterLink } from 'react-router-dom'

import styled from '@/styles'

const Link = styled(ReactRouterLink, {
  $$color: '$colors$themeDark',
  $$hoverBgColor: '$colors$themeA4',

  color: '$$color',
  outline: 'none',

  fontSize: '$md',
  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '$$hoverBgColor',
    color: '$$color',

    textDecoration: 'underline',
  },

  focusPseudoElement: {
    element: 'after',
    baseRadius: '$md',
    borderWidth: 0,
    ringColor: '$colors$themeLight',
  },

  variants: {
    appearance: {
      hidden: {
        $$color: '$colors$textDefault',
        $$hoverBgColor: 'transparent',
      },
      subtle: {
        $$color: '$colors$textSubtle',
        $$hoverBgColor: 'transparent',
      },
      hint: {
        $$color: '$colors$textHint',
        $$hoverBgColor: 'transparent',
      },
    },
  },
})

Link.displayName = 'stitches(Link)'

export default Link
