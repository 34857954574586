import Card from '@/components/Card'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Skeleton from '@/components/Skeleton'

const PageSkeleton = () => {
  return (
    <Skeleton.Page>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card sectioned title="Orders">
            <Skeleton.Body lines={10} />
          </Card>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Card sectioned title="Default contact">
            <Skeleton.Body />
          </Card>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  )
}

export default PageSkeleton
