import { useCallback } from 'react'
import useModal from '@area2k/use-modal'

import { GetCustomerQuery } from '@/types/graphql'

import ActiveCard from '@/components/ActiveCard'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import { Body } from '@/components/Typography'

import CreateAccountModal from './CreateAccountModal'

type Item = GetCustomerQuery['customer']['accounts'][0]

type Props = {
  customer: GetCustomerQuery['customer']
  currentAdminIsCustomerAdmin: boolean
}

const AccountList = ({ customer, currentAdminIsCustomerAdmin }: Props) => {
  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAccountModal customer={customer} hideModal={hideCreateModal} />
    ),
    [customer]
  )

  const renderItem = useCallback(
    (item: Item) => (
      <ResourceList.Item key={item.id} to={`accounts/${item.id}`}>
        <Body>{item.name}</Body>
      </ResourceList.Item>
    ),
    []
  )

  return (
    <ActiveCard array={customer.admins.items}>
      <Card
        actions={[
          {
            a11yLabel: 'Create a new account',
            label: 'New',
            onAction: showCreateModal,
          },
        ]}
        title="Accounts"
      >
        <ResourceList
          emptyState={
            <EmptyState
              title="No accounts"
              text="If there were any, they would be here."
            />
          }
          items={customer.accounts}
          renderItem={renderItem}
        />
      </Card>
    </ActiveCard>
  )
}

export default AccountList
