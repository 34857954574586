import { useCallback } from 'react'
import { useUserChangePasswordMutation } from '@/graphql'
import { SubmitHelpers } from '@area2k/use-form'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextField from '@/form/TextField'

type Props = {
  hideModal: () => void
}

type FormValues = {
  currentPassword: string
  newPassword: string
}

const UserChangePasswordModal = ({ hideModal }: Props) => {
  const initialValue: FormValues = {
    currentPassword: '',
    newPassword: '',
  }

  const [changePassword, { loading }] = useUserChangePasswordMutation()

  const handleSubmit = useCallback(
    async (
      { currentPassword, newPassword }: FormValues,
      { clearForm, setFormError }: SubmitHelpers
    ) => {
      try {
        if (currentPassword.length < 8) {
          return setFormError('invalidCurrentPassword', {
            message:
              'The current password must be have at least 8 characters long',
          })
        }

        if (newPassword.length < 8) {
          return setFormError('invalidNewPassword', {
            message: 'The new password must be have at least 8 characters long',
          })
        }

        clearForm()
        const result = await changePassword({
          variables: { currentPassword, newPassword },
        })

        if (result.data) return hideModal()
      } catch (err) {
        console.log(err)
      }
    },
    []
  )

  return (
    <Modal size="sm" title="Change Password" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValue} onSubmit={handleSubmit}>
          <TextField
            css={{ letterSpacing: '2px' }}
            autoFocus
            required
            fieldId="currentPassword"
            label="Current Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <TextField
            css={{ letterSpacing: '2px' }}
            required
            fieldId="newPassword"
            label="New Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={loading}
              label="Save"
              loadingLabel="Changing..."
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UserChangePasswordModal
