import { useEffect } from 'react'
import { CancelOrderMutation } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  hideModal: () => void
  onConfirm: () => void
  isLoading: boolean
  data?: CancelOrderMutation | null
}

const CancelOrderModal = ({
  hideModal,
  onConfirm,
  isLoading,
  data = null,
}: Props) => {
  useEffect(() => {
    if (data) return hideModal()
  }, [data])

  return (
    <Modal size="sm" title="Cancel Order" onRequestClose={hideModal}>
      <Card.Section>
        <Body style={{ marginBottom: 13 }}>
          Are you sure you want to cancel the order?
        </Body>
        <Stack justify="end">
          <Button
            a11yLabel="Cancel"
            label="Cancel Order"
            onClick={onConfirm}
            isLoading={isLoading}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default CancelOrderModal
