import { useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { FieldCallback } from '@area2k/use-form'

import { ListWorkersForAutocompleteQuery } from '@/types/graphql'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import AutocompleteField from '@/form/AutocompleteField'

import { currentAgencyVar } from '@/util/apollo/cache'

import { useListWorkersForAutocompleteLazyQuery } from '@/graphql'

export type WorkerItem = ListWorkersForAutocompleteQuery['agency']['workers']['items'][0]

type Props = {
  fieldId: string
  label: string
  openMenuOnInputFocus?: boolean
  callback?: FieldCallback<WorkerItem | null>
}

const WorkerAutocompleteField = ({
  fieldId,
  label,
  openMenuOnInputFocus = true,
  callback,
}: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)
  const [fetch, { data }] = useListWorkersForAutocompleteLazyQuery()

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)

  useEffect(() => {
    fetch({
      variables: {
        agencyId: currentAgency!.id,
        filters:
          debouncedQuery.length > 0
            ? { query: { value: debouncedQuery } }
            : null,
      },
    })
  }, [debouncedQuery])

  return (
    <AutocompleteField<WorkerItem>
      autoFocus
      required
      disabled={false}
      fieldId={fieldId}
      items={data ? data.agency.workers.items : []}
      itemToKey={(item) => item.id}
      itemToString={(item) =>
        item ? `${item.user.firstName} ${item.user.lastName}` : ''
      }
      label={label}
      placeholder="Select worker..."
      onInputValueChange={({ inputValue }) => setQuery(inputValue ?? '')}
      callback={callback}
      openMenuOnInputFocus={openMenuOnInputFocus}
    />
  )
}

export default WorkerAutocompleteField
