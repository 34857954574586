import { RefAttributes } from 'react'
import styled from '@/styles'

const Card = styled('div', {
  height: '100%',
  width: '100%',

  variants: {
    active: {
      true: {},
      false: {
        pointerEvents: 'none',
        opacity: 0.4,
      },
    },
  },
})

type Props = {
    array: Array<any>
    children: RefAttributes<HTMLDivElement>
}

const ActiveCard = ({ array, children }: Props) => {
  const notEmptyArr = function (
    arr: Array<any>,
    trueValue: string = 'true',
    falseValue: string = 'false'
  ): any {
    return !!arr.length ? trueValue : falseValue
  }

  return <Card active={notEmptyArr(array)}>{children}</Card>
}

export default ActiveCard
