import { differenceInMinutes, isAfter, isBefore, parseISO } from 'date-fns'

import { GetJobQuery, UpdateJobMutationVariables } from '@/types/graphql'

import { closestDateToISO } from '@/util/date'

export type ShiftRange = {
  startAt: Date
  endAt: Date
}

export enum JobStatus {
  UPCOMING = 'upcoming',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  UNPUBLISHED = 'unpublished',
}

export const getJobStatusByJob = (job) => {
  const startAt = parseISO(job.firstShiftStartAt)
  const endAt = parseISO(job.lastShiftEndAt)

  const jobStatus = job.cancelledAt
    ? JobStatus.CANCELLED
    : isBefore(new Date(), startAt)
    ? JobStatus.UPCOMING
    : isAfter(new Date(), endAt)
    ? JobStatus.COMPLETED
    : JobStatus.IN_PROGRESS

  return jobStatus
}

const MINS_IN_DAY = 24 * 60

export const getShiftIntervals = (shifts: GetJobQuery['job']['shifts']) => {
  const intervals: ShiftRange[][] = []

  shifts.forEach((shift) => {
    const lastInterval = intervals[intervals.length - 1]

    const startAt = parseISO(shift.startAt)
    const endAt = parseISO(shift.endAt)

    if (!lastInterval || lastInterval.length === 0) {
      return intervals.push([{ startAt, endAt }])
    }

    const lastShift = lastInterval[lastInterval.length - 1]

    if (
      differenceInMinutes(startAt, lastShift.startAt) !== MINS_IN_DAY ||
      differenceInMinutes(endAt, lastShift.endAt) !== MINS_IN_DAY
    ) {
      intervals.push([{ startAt, endAt }])
    } else {
      lastInterval.push({ startAt, endAt })
    }
  })

  return intervals
}

export const getJobValues = (
  job: GetJobQuery['job']
): UpdateJobMutationVariables => {
  return {
    jobId: job.id,
    addressInstructions: job.addressInstructions ?? '',
    contactId: job.contact.id,
    contactInstructions: job.contactInstructions ?? '',
    instructions: job.instructions ?? '',
    uniformId: job.uniform.id,
    uniformInstructions: job.uniformInstructions ?? '',
  }
}

export const getCannonicalTime = (
  startAt: string,
  checkTime: string | null,
  reportedTime: string | null
) =>
  checkTime
    ? reportedTime
      ? closestDateToISO(startAt, checkTime, reportedTime)
      : parseISO(checkTime)
    : reportedTime
    ? parseISO(reportedTime)
    : null
