import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { ListShiftsByWeekQuery } from '@/types/graphql'

import styled from '@/styles'

import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import { formatISO } from '@/util/date'

const DateHeading = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '12px 0',
  width: '100%',

  borderBottom: '1px solid $colors$neutralLighter',
})

const ShiftCard = styled(Link, {
  $$bgColor: '$colors$themeA16',
  $$borderColor: '$colors$themeDarkest',
  $$color: '$colors$themeDarkest',
  $$hoverBgColor: '$colors$themeA24',

  display: 'block',
  padding: '4px 8px',

  backgroundColor: '$$bgColor',
  borderRadius: '$lg',
  borderTop: '2px solid $$borderColor',
  color: '$$color',

  textDecoration: 'none',

  '&:hover': {
    backgroundColor: '$$hoverBgColor',
  },

  variants: {
    status: {
      filled: {},
      unfilled: {
        $$bgColor: '$colors$warningA24',
        $$borderColor: '$colors$warningDarkest',
        $$color: '$colors$textDefault',
        $$hoverBgColor: '$colors$warningA32',
      },
      unpublished: {
        $$bgColor: '$colors$dangerA12',
        $$color: '$colors$dangerDarker',
        $$borderColor: '$colors$dangerDarkest',
        $$hoverBgColor: '$colors$dangerA32',
      },
    },
  },
})

type Props = {
  date: Date
  shifts: ListShiftsByWeekQuery['agency']['shiftsByWeek']
}

const ShiftsColumn = ({ date, shifts }: Props) => {
  return (
    <div style={{ paddingBottom: '8px' }}>
      <Stack vertical gap={4}>
        <DateHeading>
          <Body weight="semibold">{format(date, 'eee M/d')}</Body>
        </DateHeading>
        {shifts.map((shift, index) => (
          <div key={index} style={{ width: '100%', padding: '0 4px' }}>
            <ShiftCard
              key={shift.id}
              status={
                shift.job.hiredWorkersCount < shift.job.quantity
                  ? shift.job.published
                    ? 'unfilled'
                    : 'unpublished'
                  : 'filled'
              }
              to={`orders/${shift.job.order.id}/jobs/${shift.job.id}`}
            >
              <TextStack>
                <Body color="inherit" weight="medium">
                  {shift.job.account.customer.name}
                </Body>
                <Small color="default">{shift.job.skill.name}</Small>
                <Small color="default">
                  {shift.job.address.city}, {shift.job.address.state}{' '}
                  {shift.job.address.zip}
                </Small>
                <Small color="default">
                  {formatISO(shift.startAt, 'p')} &mdash;{' '}
                  {formatISO(shift.endAt, 'p')}
                </Small>
                <Small color="default">
                  {shift.job.hiredWorkersCount} / {shift.job.quantity} hired
                </Small>
              </TextStack>
            </ShiftCard>
          </div>
        ))}
      </Stack>
    </div>
  )
}

export default ShiftsColumn
