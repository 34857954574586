import Card from '@/components/Card'
import Form from '@/form'
import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import { GetWorkerQuery, TaxTypeEnum } from '@/types/graphql'
import { useModal } from 'react-modal-hook'
import ChangeTaxOptionModal from './ChangeTaxOptionModal'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const taxLabelOptions = {
  [TaxTypeEnum.TAX_1099]: '1099',
  [TaxTypeEnum.TAX_W2]: 'W2',
}

const TaxSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeTaxOptionModal worker={worker} hideModal={hideChangeModal} />,
    [worker.taxType]
  )

  return (
    <Card.Section
      title="Tax type"
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this worker's tax",
                label: 'Change',
                onAction: showChangeModal,
              },
            ]
          : undefined
      }
    >
      {taxLabelOptions[worker.taxType]}
    </Card.Section>
  )
}

export default TaxSection
