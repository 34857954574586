import { useParams } from 'react-router-dom'
import { CometChatUI } from '../../../CometChatWorkspace/src'

const Chat = () => {
  const { chatUid } = useParams()
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <CometChatUI selectedChatUid={chatUid ?? null} />
    </div>
  )
}

export default Chat
