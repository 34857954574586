import { useCallback } from 'react'
import { GetWorkerQuery, TaxTypeEnum } from '@/types/graphql'
import { SubmitHelpers } from '@area2k/use-form'

import { useUpdateTaxTypeMutation } from '@/graphql'
import { handleMutationFormError } from '@/util/error'
import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import Form from '@/form'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

type Props = {
  worker: GetWorkerQuery['worker']
  hideModal: () => void
}

const taxOptions = [
  { label: 'W2', value: TaxTypeEnum.TAX_W2 },
  { label: '1099', value: TaxTypeEnum.TAX_1099 },
]

type FormValues = {
  taxType: TaxTypeEnum
}

const ChangeTaxOptionModal = ({ worker, hideModal }: Props) => {
  const initialValues: FormValues = {
    taxType: worker?.taxType,
  }

  const [updateTaxtType, { loading }] = useUpdateTaxTypeMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          taxtType() {},
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await updateTaxtType({
          variables: {
            workerId: worker.id,
            ...values,
          },
        })
        hideModal()
      } catch (error) {
        handleMutationFormError(error, {
          setFormError,
        })
      }
    },
    []
  )

  return (
    <Modal size="xs" title="Change tax type" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <SingleOptionGroupField options={taxOptions} fieldId="taxType" />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={loading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default ChangeTaxOptionModal
