import { useCallback } from 'react'
import { FieldCallback, useField } from '@area2k/use-form'

import Autocomplete, {
  Props as AutocompleteProps,
} from '@/components/Autocomplete'
import FormElement from '@/components/FormElement'

type InputProps<T> = Omit<
  AutocompleteProps<T>,
  'id' | 'selectedItem' | 'onSelectedItemChange'
>

type Props<T> = InputProps<T> & {
  fieldId: string
  label?: string
  callback?: FieldCallback<T | null>
}

const AutocompleteField = <T extends object>({
  fieldId,
  label,
  callback,
  ...props
}: Props<T>) => {
  const { value, setValue } = useField<T | null>(fieldId, callback)

  const handleSelectedItemChange = useCallback(
    ({ selectedItem }) => setValue(selectedItem),
    []
  )

  return (
    <FormElement htmlFor={`${fieldId}-input`} label={label}>
      <Autocomplete
        {...props}
        id={fieldId}
        selectedItem={value}
        onSelectedItemChange={handleSelectedItemChange}
      />
    </FormElement>
  )
}

export default AutocompleteField
