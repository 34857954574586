import { useModal } from 'react-modal-hook'

import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import TagList from '@/components/TagList'

import ChangeSkillsModal from './ChangeSkillsModal'

import { sortBy } from '@/util/array'

type Props = {
  worker: GetWorkerQuery['worker']
  currentAdminIsCustomerAdmin: boolean
}

const SkillsSection = ({ worker, currentAdminIsCustomerAdmin }: Props) => {
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeSkillsModal worker={worker} hideModal={hideChangeModal} />,
    [worker]
  )

  return (
    <Card.Section
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: "Change this worker's skills",
                label: 'Change',
                onAction: showChangeModal,
              },
            ]
          : undefined
      }
      title="Skills"
    >
      <TagList
        tags={sortBy(worker.skills, 'name').map((skill) => skill.name)}
      />
    </Card.Section>
  )
}

export default SkillsSection
