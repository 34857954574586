import Card from '@/components/Card'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Skeleton from '@/components/Skeleton'
import Stack from '@/components/Stack'

const PageSkeleton = () => {
  return (
    <Skeleton.Page>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card sectioned title="Jobs">
            <Skeleton.Body />
          </Card>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <Card sectioned title="Customer">
              <Skeleton.Body />
            </Card>
            <Card sectioned title="Summary">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  )
}

export default PageSkeleton
