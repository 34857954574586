import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import { GetJobQuery } from '@/types/graphql'
import TextAreaField from '@/form/TextAreaField'
import { useUpdateJobMutation } from '@/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'

import { getJobValues } from '../../util'
import { handleMutationFormError } from '@/util/error'
import ContactSelectField from './ContactSelectField'

export type Props = {
  job: GetJobQuery['job']
  hideModal: () => void
}

type FormValues = {
  contact: GetJobQuery['job']['contact']
  contactInstructions: string
}

const UpdateContactModal = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    contact: job.contact,
    contactInstructions: job.contactInstructions ?? ''
  }

  const [updateJob, { loading: isLoading }] = useUpdateJobMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      console.log({
        ...getJobValues(job),
        contactId: values.contact.id,
        contactInstructions: values.contactInstructions
      })
      try {
        await updateJob({
          variables: {
            ...getJobValues(job),
            contactId: values.contact.id,
            contactInstructions: values.contactInstructions
          }
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    [job]
  )

  return (
    <Modal
      size='sm'
      title='Update contact information'
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <ContactSelectField fieldId='contact' label='Contact' job={job} />
          <TextAreaField
            fieldId='contactInstructions'
            label='Contact instructions'
            placeholder='Provide instructions and details to the workers...'
          />
          <Stack justify='end'>
            <Button
              a11yLabel='Submit form'
              isLoading={false}
              label='Update'
              type='submit'
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateContactModal
