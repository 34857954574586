import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import useModal from '@area2k/use-modal'

import { useListCustomerAdminQuery, useListTenantAdminQuery } from '@/graphql'
import { CustomerAdminFilterSetInput } from '@/types/graphql'

import useFilters from '@/hooks/useFilters'

import Table from '@/components/Table'

import Card from '@/components/Card'
import Page from '@/components/Page'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import SuccessfulCreateAlert from '@/components/SuccessfulCreationAlert'
import Tabs, { TabDefinition } from '@/components/Tabs'
import TableHeader from '@/components/TableHeader'

import ContactFilters from './ContactFilters'
import CreateAdminModal from './CreateAdminModal'
import TableBodyCustomer from './Tables/TableBodyCustomer'
import TableBodyTenant from './Tables/TableBodyTenant'

import { currentAgencyVar } from '@/util/apollo/cache'

const initialFilters: CustomerAdminFilterSetInput = {}

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View Tenant Admin', label: 'Tenant Admin' },
  { a11yLabel: 'View Customer Admins', label: 'Customer Admins' },
]

const Contacts = () => {
  const [createdItem, setCreatedItem] = useState({
    created: false,
    data: '',
  })
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [tab, setTab] = useState<number>(1)

  const [showCreateModal, hideCreateModal] = useModal(
    () => (
      <CreateAdminModal
        setCreatedItem={setCreatedItem}
        hideModal={hideCreateModal}
      />
    ),
    [setCreatedItem]
  )

  const [showSuccessfulAlertModal, hideSuccessfulAlertModal] = useModal(
    () => (
      <SuccessfulCreateAlert
        body="The administrator has been invited to access the account."
        item={createdItem!.data}
        hideModal={hideSuccessfulAlertModal}
      />
    ),
    [createdItem]
  )

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<CustomerAdminFilterSetInput>(initialFilters)

  const finalFilters = useMemo<CustomerAdminFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAgency = useReactiveVar(currentAgencyVar)

  const tenantAdminListQuery = useListTenantAdminQuery({
    variables: {
      agencyId: currentAgency!.id,
    },
  })

  const customerAdminListQuery = useListCustomerAdminQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const handlePageChange = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber)
  }, [])

  const handleNumberItemsChange = useCallback((event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }, [])

  const headerFieldsTenant = useMemo(
    () => ['User', 'Phone Number', 'Status'],
    []
  )

  const headerFieldsCustomerAdmin = useMemo(
    () => ['User', 'Customer', 'Phone Number', 'Status'],
    []
  )

  const tenantItems = useMemo(
    () =>
      !tenantAdminListQuery.loading && tenantAdminListQuery.data
        ? tenantAdminListQuery.data.agency.tenant.admins
        : [],
    [tenantAdminListQuery]
  )

  const customerAdminItems = useMemo(
    () =>
      !customerAdminListQuery.loading && customerAdminListQuery.data
        ? customerAdminListQuery.data.agency.customerAdmins.items
        : [],
    [customerAdminListQuery]
  )

  const pageInfo = useMemo(
    () =>
      customerAdminListQuery.data?.agency.customerAdmins.pageInfo.totalItems ||
      0,
    [customerAdminListQuery]
  )

  useEffect(() => {
    if (createdItem.created) {
      showSuccessfulAlertModal()
    }
  }, [createdItem, showSuccessfulAlertModal])

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a Admin',
        label: 'New Admin',
        onAction: showCreateModal,
      }}
      size="md"
      title="Contacts"
    >
      <Card>
        <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
        <Card.Section>
          <ContactFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <Table>
          <TableHeader
            fields={tab === 0 ? headerFieldsTenant : headerFieldsCustomerAdmin}
          />
          {tab === 0 ? (
            <TableBodyTenant
              items={tenantItems}
              headerFields={headerFieldsTenant}
              isLoading={tenantAdminListQuery.loading}
            />
          ) : (
            <TableBodyCustomer
              items={customerAdminItems}
              headerFields={headerFieldsCustomerAdmin}
              isLoading={customerAdminListQuery.loading}
            />
          )}
        </Table>
        {customerAdminItems.length === 0 && (
          <QueryEmptyState
            query={customerAdminListQuery}
            title="No customer administrators available"
            text="If there were any, they would be here."
          />
        )}
      </Card>
      {tab === 1 && customerAdminItems.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={pageInfo}
        />
      )}
    </Page>
  )
}

export default Contacts
