import { differenceInMinutes, format, parseISO } from 'date-fns'

export const formatISO = (dateString: string, formatString: string) =>
  format(parseISO(dateString), formatString)

export const closestDateToISO = (baseDate: string, ...dates: string[]) => {
  const base = parseISO(baseDate)

  return dates
    .map((date) => parseISO(date))
    .sort((a, b) => {
      const distanceA = Math.abs(differenceInMinutes(base, a))
      const distanceB = Math.abs(differenceInMinutes(base, b))

      if (distanceA > distanceB) {
        return 1
      } else if (distanceB > distanceA) {
        return -1
      }

      return 0
    })[0]
}

export const isDateInvalid = (date: Date) => isNaN(date.getTime())
