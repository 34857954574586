import styled from '@/styles'

const TableRow = styled('tr', {
  boxShadow: 'inset 0 -1px 0 0 $colors$neutralA8',

  '&:nth-child(even)': {
    backgroundColor: '$neutralA2',
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: '$neutralA4',
        },
      },
      false: {},
    },
    selected: {
      true: {
        $$selected: '$colors$themeA12',

        backgroundColor: '$$selected',

        '&:nth-child(even)': {
          backgroundColor: '$$selected',
        },

        '&:hover': {
          backgroundColor: '$colors$themeA16',
        },
      },
    },
  },

  defaultVariants: {
    clickable: false,
  },
})

TableRow.displayName = 'stitches(Table.TableRow)'

export default TableRow
