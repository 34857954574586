import styled from '@/styles'

export const GroupBox = styled('fieldset', {
  width: 'auto',
  flex: 1,
  minHeight: 80,
  borderColor: '$neutralLightest',
  variants: {
    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {
        borderRadius: '$lg',
      },
    },
  },

  defaultVariants: {
    rounded: false,
  },
})

export const Title = styled('legend', {
  padding: '0px 5px',
  color: '$textDefault',

  fontSize: '$md',
  fontWeight: '$normal',
  lineHeight: 1.5,
})
