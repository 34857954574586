import Icon from '@/elements/Icon'
import Link from '@/elements/Link'
import { mapUrl } from '@/routes/Agency/Customer/AddressList'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import FormElement from '../FormElement'
import TextStack from '../TextStack'
import { Small } from '../Typography'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { AddressValues } from '@/routes/Agency/Workers/EditCandidateModal'
import { Wrapper } from './styles'
import AddressAutocomplete from '../AddressAutocomplete'

type Props = {
  values: any
  setValues: (args) => void
}

const EditAddress = ({ values, setValues }: Props) => {
  const [initialValues] = useState<AddressValues>({
    ...values,
  })

  const findContent = (attribute, details) => {
    return details.address_components.find((item) =>
      item.types.includes(attribute)
    )
  }

  const handleZipCode = (content) => {
    if (content) {
      if (!/^-?\d+$/.test(content)) {
        return content.long_name || content.short_name
      }
      return content
    }
    return ''
  }

  return (
    <Wrapper>
      <div style={{ marginBottom: '10px' }}>
        <Small color="ligth" style="italic">
          Current:
        </Small>
        <TextStack>
          {initialValues.addressLine1?.length > 0 ? (
            <Link
              as="a"
              href={mapUrl(initialValues)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {initialValues.addressLine1}{' '}
              <Small color="inherit">
                <Icon icon={faExternalLinkAlt} />
              </Small>
            </Link>
          ) : (
            <span> No previous address</span>
          )}
          {initialValues.addressLine2 && (
            <Small>{initialValues.addressLine2}</Small>
          )}
          <Small>
            {initialValues.city}, {initialValues.state} {initialValues.zip}
            {!initialValues.addressLine1 && (
              <Link
                as="a"
                href={mapUrl(initialValues)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon={faExternalLinkAlt} />
              </Link>
            )}
          </Small>
        </TextStack>
      </div>
      <div>
        <Small color="ligth" style="italic">
          New:
        </Small>
        <FormElement htmlFor="">
          <AddressAutocomplete values={values} setValues={setValues} />
        </FormElement>
      </div>
    </Wrapper>
  )
}

export default EditAddress
