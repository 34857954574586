import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { WorkerReference } from '@/types/graphql'

import styled from '@/styles'

import Button from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import { Body, Small } from '@/components/Typography'
import IconicButton from '@/components/IconicButton'
import TextStack from '@/components/TextStack'
import Stack from '../Stack'

const Wrapper = styled('div', {
  $$bgColor: '$colors$neutralLightest',
  $$borderColor: '$colors$neutralLight',

  width: '100%',
  heigth: 'auto',
  padding: '20px 10px',
  marginBottom: '8px',

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',

  backgroundColor: '$$bgColor',
  border: '1px solid $$borderColor',
  borderRadius: '5px',
})

const ContentInfo = styled('div', {
  $$borderColor: '$colors$neutralLight',

  width: '100%',
  heigth: 'auto',
  padding: '15px',
  marginBottom: '8px',

  border: '1px solid $$borderColor',
  borderRadius: '5px',
})

type Props = {
  reference: WorkerReference
  handleRemove: (item) => void
}

const ExperienceItem = ({ reference, handleRemove }: Props) => {
  return (
    <Wrapper>
      <TextStack>
        <Body>{`${reference.employerName} - ${reference.city}`}</Body>
        <Small>{reference.name}</Small>
      </TextStack>
      {/* <IconicButton
        a11yLabel="delete"
        icon={faTimes}
        appearance="clear"
        onClick={() => handleRemove(reference)}
      /> */}
      <Dropdown
        Content={() => (
          <ContentInfo>
            <TextStack>
              <Small>Duration</Small>
              <Body>{reference.duration}</Body>
              <Small>Summary</Small>
              <Body>{reference.summary}</Body>
            </TextStack>
          </ContentInfo>
        )}
        justify="right"
        size="sm"
      >
        <Button
          a11yLabel={''}
          type="button"
          appearance="outline"
          label="More info"
        />
      </Dropdown>
    </Wrapper>
  )
}

export default ExperienceItem
