import { useCallback, useState } from 'react'
import useModal from '@area2k/use-modal'
import { useReactiveVar } from '@apollo/client'
import { currentAdminVar } from '@/util/apollo/cache'

import { useGetCustomersAdminsPaginetedQuery } from '@/graphql'
import {
  GetCustomerQuery,
  GetCustomersAdminsPaginetedQuery,
} from '@/types/graphql'

import ROUTES from '@/routes/routes'

import useMediaQuery from '@/hooks/useMediaQuery'
import Avatar from '@/components/Avatar'
import Badge from '@/components/Badge'
import Card from '@/components/Card'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'
import Stack from '@/components/Stack'
import LoadingState from '@/components/LoadingState'
import Pagination from '@/components/Pagination'

import InviteAdminModal from './InviteAdminModal'

import ResourceList from '@/components/ResourceList'
import EmptyState from '@/components/EmptyState'

type CustomerAdminPaginatedItem = GetCustomersAdminsPaginetedQuery['customer']['admins']['items'][0]

type Props = {
  customer: GetCustomerQuery['customer']
  currentAdminIsCustomerAdmin: boolean
}

const AdminList = ({ customer, currentAdminIsCustomerAdmin }: Props) => {
  const [page, setPage] = useState(1)

  const currentAdmin = useReactiveVar(currentAdminVar)

  const query = useGetCustomersAdminsPaginetedQuery({
    variables: {
      customerId: customer.id,
      page,
      perPage: 10,
    },
  })

  const isPhoneOnly = useMediaQuery('(max-width: 559px)')

  const [showInviteModal, hideInviteModal] = useModal(
    () => <InviteAdminModal customer={customer} hideModal={hideInviteModal} />,
    [customer]
  )

  const renderItem = useCallback(
    (item: CustomerAdminPaginatedItem) => {
      const isCurrentCustomerAdmin =
        currentAdminIsCustomerAdmin && currentAdmin!.id === item.id

      return (
        <ResourceList.Item
          key={item.id}
          media={
            <Avatar
              firstName={item.user.firstName}
              size="sm"
              src={item.avatarUrl}
            />
          }
          to={
            !currentAdminIsCustomerAdmin
              ? `admin/${item.id}`
              : isCurrentCustomerAdmin
              ? `../${ROUTES.myProfile}`
              : `../${ROUTES.myTeam}/${item.id}`
          }
        >
          <Stack wrap={isPhoneOnly}>
            <TextStack>
              <Body>
                {item.user.firstName} {item.user.lastName}
              </Body>
              <Small>{item.user.email}</Small>
            </TextStack>
            <Badge
              label={item.acceptedAt ? 'Verified' : 'Pending'}
              status={item.acceptedAt ? 'theme' : 'warning'}
            />
          </Stack>
        </ResourceList.Item>
      )
    },
    [isPhoneOnly]
  )

  if (!query.data) {
    return (
      <Card title="Orders">
        <LoadingState overlayColor="white" />
      </Card>
    )
  }

  const admins = query.data.customer.admins

  return (
    <Card
      actions={[
        {
          a11yLabel: 'Invite a new administrator',
          label: 'Invite',
          onAction: showInviteModal,
        },
      ]}
      title="Administrators"
    >
      <ResourceList
        emptyState={
          <EmptyState
            title="No customer admins"
            text="If there were any, they would be here"
          />
        }
        items={admins.items}
        renderItem={renderItem}
      />
      {admins.items.length > 0 && admins.pageInfo.totalPages > 1 && (
        <Card.Section>
          <Pagination
            currentPage={page}
            totalPages={admins.pageInfo.totalPages}
            onPageChange={setPage}
          />
        </Card.Section>
      )}
    </Card>
  )
}

export default AdminList
