import { Range } from 'react-date-range'

import { ScheduleType } from '../../context'

export const rangeToDisplayRange = (dateRange: Range, scheduleType: ScheduleType): Range[] => {
  // TODO: different displays for each type
  switch (scheduleType) {
    case ScheduleType.WEEKDAYS_ONLY:
      return [dateRange]
    case ScheduleType.WEEKENDS_ONLY:
      return [dateRange]
    case ScheduleType.ALL_DAYS:
      return [dateRange]
  }
}
