import { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import useModal from '@area2k/use-modal'

import { currentAgencyVar } from '@/util/apollo/cache'

import {
  CandidateFilterSetInput,
  CandidateStatusEnum,
  WorkerFilterSetInput,
} from '@/types/graphql'
import { useListCandidatesQuery, useListWorkersQuery } from '@/graphql'

import CreateWorkerModal from './CreateWorkerModal'
import { getHeaderFields, RenderBody } from './helpers'
import CandidateFilters from './Filters/CandidateFilters'
import WorkerFilters from './Filters/WorkerFilters'

import useFilters from '@/hooks/useFilters'

import Card from '@/components/Card'
import Page from '@/components/Page'
import Paginator from '@/components/Paginator/Paginator'
import Table from '@/components/Table'
import TableHeader from '@/components/TableHeader'
import Tabs, { TabDefinition } from '@/components/Tabs'
import QueryEmptyState from '@/components/QueryEmptyState'

const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View candidates', label: 'Candidates' },
  { a11yLabel: 'View applicant', label: 'Applicants' },
  { a11yLabel: 'View in progress', label: 'Workers in Progress' },
  { a11yLabel: 'View completed', label: 'Workers Complete' },
]

const Workers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tab, setTab] = useState(0)

  const [showCreateModal, hideCreateModal] = useModal(
    () => <CreateWorkerModal hideModal={hideCreateModal} />,
    []
  )

  const candidateFilters = useFilters<CandidateFilterSetInput>({
    active: { value: true },
  })
  const workerFilters = useFilters<WorkerFilterSetInput>({
    active: { value: true },
  })

  const candidateFinalFilters = useMemo<CandidateFilterSetInput>(() => {
    const value = { ...candidateFilters.filters }
    if (candidateFilters.debouncedQuery !== '') {
      value.query = { value: candidateFilters.debouncedQuery }
    }
    return value
  }, [candidateFilters])

  const workerFinalFilters = useMemo<CandidateFilterSetInput>(() => {
    const value = { ...workerFilters.filters }
    if (workerFilters.debouncedQuery !== '') {
      value.query = { value: workerFilters.debouncedQuery }
    }
    return value
  }, [workerFilters])

  const currentAgency = useReactiveVar(currentAgencyVar)
  const candidates = useListCandidatesQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: candidateFinalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const workers = useListWorkersQuery({
    variables: {
      agencyId: currentAgency!.id,
      filters: workerFinalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const workersItems = workers.data?.agency.workers.items || []

  const candidatesItems = candidates.data?.agency.candidates.items || []

  const maxPagesWorker = workers.data?.agency.workers.pageInfo.totalItems || 0

  const maxPagesCandidate =
    candidates.data?.agency.candidates.pageInfo.totalItems || 0

  const getItemsData = () => {
    return tab <= 2 ? candidatesItems : workersItems
  }

  const getItemsLength = () => (tab <= 2 ? maxPagesCandidate : maxPagesWorker)

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  useEffect(() => {
    setCurrentPage(1)
    switch (tab) {
      case 0:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.UNVERIFIED },
          { value: CandidateStatusEnum.VERIFIED },
        ])
        break
      case 1:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.APPLIED },
          { value: CandidateStatusEnum.INTERVIEWING },
        ])
        break
      case 2:
        candidateFilters.setFilter('statusGroup', [
          { value: CandidateStatusEnum.START_HIRE },
        ])
        break
    }
  }, [tab])

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a worker',
        label: 'New Worker',
        onAction: showCreateModal,
      }}
      size="full"
      title="Workers"
    >
      <Card>
        <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
        <Card.Section>
          {tab < 3 ? (
            <CandidateFilters
              tab={tab}
              filters={candidateFilters.filters}
              query={candidateFilters.query}
              onQueryChange={candidateFilters.setQuery}
              onClearFilter={candidateFilters.clearFilter}
              onChangeFilter={candidateFilters.setFilter}
            />
          ) : (
            <WorkerFilters
              filters={workerFilters.filters}
              query={workerFilters.query}
              onQueryChange={workerFilters.setQuery}
              onClearFilter={workerFilters.clearFilter}
              onChangeFilter={workerFilters.setFilter}
              onClearAll={workerFilters.clearAll}
            />
          )}
        </Card.Section>
        <Table>
          <TableHeader fields={getHeaderFields(tab)} />
          <RenderBody items={getItemsData()} tab={tab} />
        </Table>
        {getItemsData().length === 0 && (
          <QueryEmptyState
            query={candidates || workers}
            title={`No ${RELATIONSHIP_TABS[tab].label}`}
            text={`No ${RELATIONSHIP_TABS[tab].label} were found, create one above.`}
          />
        )}
      </Card>
      {getItemsData().length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={getItemsLength()}
        />
      )}
    </Page>
  )
}

export default Workers
