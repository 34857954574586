import { useEffect } from 'react'
import { JobStatusEnum } from '@/types/graphql'

import Stack from '@/components/Stack'
import Button from '@/components/Button'
import Option from '@/components/Option'
import { OrderStatus } from '../../Orders/OrderFilters'

const options = [
  'ALL',
  JobStatusEnum.COMPLETED,
  JobStatusEnum.UPCOMING,
  JobStatusEnum.IN_PROGRESS,
  JobStatusEnum.CANCELLED,
]

const JOB_LABELS = {
  ['ALL']: 'All',
  [JobStatusEnum.COMPLETED]: 'Completed',
  [JobStatusEnum.UPCOMING]: 'Upcoming',
  [JobStatusEnum.IN_PROGRESS]: 'In progress',
  [JobStatusEnum.CANCELLED]: 'Cancelled',
}

const JobStatusFilter = ({
  idPrefix,
  onChangeFilter,
  onClearFilter,
  jobStatusValue,
  setJobStatusValue,
}) => {
  useEffect(() => {
    switch (jobStatusValue) {
      case options[0]:
        onClearFilter('jobStatus')
        break
      case options[1]:
      case options[2]:
      case options[3]:
      case options[4]:
        onChangeFilter('jobStatus', { value: jobStatusValue })
        break

      default:
        break
    }
  }, [jobStatusValue])

  return (
    <Stack vertical gap={16}>
      {options.map((option, index) => (
        <Option
          key={option}
          appearance="bullseye"
          checked={jobStatusValue === option}
          id={`${idPrefix}_orderStatus-${option}`}
          label={JOB_LABELS[option]}
          name={`${idPrefix}_orderStatus`}
          type="radio"
          value={option}
          onChange={() => setJobStatusValue(options[index])}
        />
      ))}
      {jobStatusValue !== OrderStatus.ALL && (
        <Button
          a11yLabel="Clear order status filter"
          appearance="plain"
          label="Clear"
          onClick={() => {
            setJobStatusValue('ALL')
          }}
        />
      )}
    </Stack>
  )
}

export default JobStatusFilter
