import { useState, useMemo, useEffect } from 'react'
import useModal from '@area2k/use-modal'
import { useReactiveVar } from '@apollo/client'
import { CustomerAdminFilterSetInput } from '@/types/graphql'
import { useGetMyTeamQuery } from '@/graphql'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import useFilters from '@/hooks/useFilters'
import { currentAdminVar } from '@/util/apollo/cache'

import ROUTES from '@/routes/routes'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Page from '@/components/Page'
import Stack from '@/components/Stack'
import Table from '@/components/Table'
import TableHeader from '@/components/TableHeader'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'
import IconicButton from '@/components/IconicButton'
import LoadingState from '@/components/LoadingState'
import Badge from '@/components/Badge'
import Paginator from '@/components/Paginator/Paginator'
import SuccessfulCreateAlert from '@/components/SuccessfulCreationAlert'
import QueryEmptyState from '@/components/QueryEmptyState'

import { TableCell, TableRow } from '@/elements/Table'
import Link from '@/elements/Link'

import MyTeamFilters from './MyTeamFilters'
import AddNewMemberModal from './modals/addNewMemberModal'

const initialFilters: CustomerAdminFilterSetInput = {}

const MyTeam = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  const [memberWasInvited, setMemberWasInvited] = useState(false)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    setQuery,
  } = useFilters<CustomerAdminFilterSetInput>(initialFilters)

  const finalFilters = useMemo<CustomerAdminFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const currentAdmin = useReactiveVar(currentAdminVar)
  const query = useGetMyTeamQuery({
    variables: {
      customerId: currentAdmin!.customer!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
    },
  })

  const headerFields = useMemo(
    () => ['User', 'Phone number', 'Department', 'Status'],
    []
  )

  const [showAddNewMemberModal, hideAddNewMemberModal] = useModal(
    () => (
      <AddNewMemberModal
        onHide={hideAddNewMemberModal}
        setMemberWasInvited={setMemberWasInvited}
      />
    ),
    []
  )

  const [showSuccessfulCreateModal, hideSuccessfulCreateModal] = useModal(
    () => (
      <SuccessfulCreateAlert
        hideModal={hideSuccessfulCreateModal}
        body="Your invitation has been successfully sent"
        item="Customer Admin"
      />
    ),
    []
  )

  const handlePageChange = useMemo(
    () => (pageNumber: number) => setCurrentPage(pageNumber),
    []
  )

  const handleNumberItemsChange = useMemo(
    () => (event) => {
      setCurrentPage(1)
      setItemsPerPage(parseInt(event.target.value))
    },
    []
  )

  useEffect(() => {
    if (memberWasInvited) {
      setMemberWasInvited(false)
      return showSuccessfulCreateModal()
    }
  }, [memberWasInvited])

  const admins =
    !query.loading && query.data ? query.data.customer.admins.items : []

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Create a Admin',
        label: 'New Member',
        onAction: showAddNewMemberModal,
      }}
      size="md"
      title="My team"
    >
      <Card>
        <Card.Section>
          <MyTeamFilters query={searchQuery} onQueryChange={setQuery} />
        </Card.Section>

        <Table>
          <TableHeader fields={headerFields} />
          <tbody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>
                  <Stack>
                    <div>
                      <Avatar
                        size="sm"
                        src={admin.avatarUrl}
                        firstName={admin.user.firstName}
                      />
                    </div>
                    <TextStack>
                      <Link
                        to={{
                          pathname:
                            admin.id === currentAdmin!.id
                              ? `../${ROUTES.myProfile}`
                              : `../my-team/${admin.id}`,
                        }}
                      >
                        <Body color="inherit" weight="medium">
                          {admin.user.firstName} {admin.user.lastName}
                        </Body>
                      </Link>

                      <Link
                        as="a"
                        href={`mailto:${admin.user.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Small>{admin.user.email}</Small>
                      </Link>
                    </TextStack>
                  </Stack>
                </TableCell>
                <TableCell>{admin.user.phoneNumber}</TableCell>
                <TableCell>
                  <Body color={admin.department ? 'default' : 'lightest'}>
                    {admin.department ? admin.department : 'Not provided'}
                  </Body>
                </TableCell>
                <TableCell>
                  <Badge
                    label={
                      admin.acceptedAt
                        ? admin.user.active
                          ? 'Enabled'
                          : 'Disabled'
                        : 'Pending'
                    }
                    status={
                      admin.acceptedAt
                        ? admin.user.active
                          ? 'theme'
                          : 'danger'
                        : 'warning'
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
            {query.loading && (
              <TableRow>
                <TableCell colSpan={headerFields.length + 1} align="center">
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {!query.loading && admins.length === 0 && (
          <QueryEmptyState
            query={query}
            title="No customer admins"
            text="No customer admins were found, invite one above."
          />
        )}
      </Card>
      {admins.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={
            !query.loading && query.data
              ? query.data.customer.admins.pageInfo.totalItems
              : 0
          }
        />
      )}
    </Page>
  )
}

export default MyTeam
