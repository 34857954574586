import { useRoutes, Navigate } from 'react-router-dom'
import { PartialRouteObject } from 'react-router'
import Config from '@/config'

import PrivateRoute, { Role } from '@/routes/PrivateRoute'
import ROUTES from '@/routes/routes'

/**
 * Routes
 */
import Root from '@/routes/Root'

// Auth
import Authenticated from '@/routes/Authenticated'

// Agency
import Agency from '@/routes/Agency'
import AgencyDashboard from '@/routes/Agency/Dashboard'

import AgencyOrders from '@/routes/Agency/Orders'
import AgencyCreateOrder from '@/routes/Agency/CreateOrder'
import AgencyOrderDetail from '@/routes/Agency/Order'
import AgencyOrderJobDetail from '@/routes/Agency/Job'

import AgencyCustomers from '@/routes/Agency/Customers'
import AgencyCustomerDetail from '@/routes/Agency/Customer'
import AgencyCustomerAccountDetail from '@/routes/Agency/Account'
import AgencyCustomerAdminDetail from '@/routes/Agency/CustomerAdmin'

import AgencyContacts from '@/routes/Agency/Contacts'

import AgencyWorkers from '@/routes/Agency/Workers'
import AgencyWorkerDetail from '@/routes/Agency/Worker'

import MyTeam from '@/routes/Agency/MyTeam'

import AgencyChat from '@/routes/Agency/Chat'

// Public
import Login from '@/routes/Login'
import Signout from '@/routes/Signout'
import ForgotPassword from '@/routes/ForgotPassword'
import ResetPassword from '@/routes/ResetPassword'

const privateRoutes: PartialRouteObject[] = [
  {
    path: '*',
    element: <Navigate to={ROUTES.basePath} />,
  },
  {
    path: ROUTES.basePath, // "/"
    element: (
      <PrivateRoute
        requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
        element={<AgencyDashboard />}
        redirectTo={ROUTES.orders}
      />
    ),
  },
  {
    path: ROUTES.orders,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
            element={<AgencyOrders />}
          />
        ),
      },
      {
        path: ROUTES.ordersCreate,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
            element={<AgencyCreateOrder />}
          />
        ),
      },
      {
        path: ROUTES.orderDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
            element={<AgencyOrderDetail />}
          />
        ),
      },
      {
        path: ROUTES.orderJobDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
            element={<AgencyOrderJobDetail />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.customers,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyCustomers />}
          />
        ),
      },
      {
        path: ROUTES.customerDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyCustomerDetail />}
          />
        ),
      },
      {
        path: ROUTES.customerAccountDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyCustomerAccountDetail />}
          />
        ),
      },
      {
        path: ROUTES.customerAdminDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyCustomerAdminDetail />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.contacts,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyContacts />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.workers,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyWorkers />}
          />
        ),
      },
      {
        path: ROUTES.workerDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN, Role.CUSTOMER_ADMIN]}
            element={<AgencyWorkerDetail />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myTeam,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            requiredRoles={[Role.CUSTOMER_ADMIN]}
            element={<MyTeam />}
          />
        ),
      },
      {
        path: ROUTES.myTeamAdminDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.CUSTOMER_ADMIN]}
            element={<AgencyCustomerAdminDetail />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myCompany,
    children: [
      {
        path: ROUTES.basePath,
        element: (
          <PrivateRoute
            requiredRoles={[Role.CUSTOMER_ADMIN]}
            element={<AgencyCustomerDetail />}
          />
        ),
      },
      {
        path: ROUTES.myCompanyAccountDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.CUSTOMER_ADMIN]}
            element={<AgencyCustomerAccountDetail />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.chat,
    children: [
      {
        path: ROUTES.basePath, // "/"
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyChat />}
          />
        ),
      },
      {
        path: ROUTES.chatDetail,
        element: (
          <PrivateRoute
            requiredRoles={[Role.TENANT_ADMIN]}
            element={<AgencyChat />}
          />
        ),
      },
    ],
  },
  {
    path: ROUTES.myProfile,
    element: (
      <PrivateRoute
        requiredRoles={[Role.CUSTOMER_ADMIN]}
        element={<AgencyCustomerAdminDetail />}
      />
    ),
  },
]

const publicRoutes: PartialRouteObject[] = [
  { path: ROUTES.login, element: <Login /> },
  { path: ROUTES.signOut, element: <Signout /> },
  { path: ROUTES.forgotPassword, element: <ForgotPassword /> },
  { path: ROUTES.resetPassword, element: <ResetPassword /> },
]

const routes: PartialRouteObject[] = [
  {
    path: Config.BASEPATH,
    children: [
      {
        element: <Root />,
        children: [
          {
            element: <Authenticated />,
            children: [{ element: <Agency />, children: [...privateRoutes] }],
          },
          { children: [...publicRoutes] },
        ],
      },
    ],
  },
]

const AppRouter = () => {
  const routing = useRoutes(routes)

  return routing
}

export default AppRouter
