import { useCallback, useEffect, useState } from 'react'
import { FieldContext } from '@area2k/use-form'
import { useListSkillCategoriesByCustomRatesQuery } from '@/graphql'
import { replaceAtIndex } from '@/util/array'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Maybe } from '@/types'

import Alert from '@/components/Alert'
import LoadingState from '@/components/LoadingState'
import Button from '@/components/Button'
import SingleColumnLayout from '@/components/SingleColumnLayout'
import Stack from '@/components/Stack'
import SkillItem from './SkillItem'
import { Heading } from '@/components/Typography'

import Form from '@/form'
import SingleSelectableField from '@/form/SingleSelectableField'

import BottomBar from '../BottomBar'
import Layout from '../Layout'
import SkillSelectField from './SkillSelectField'

import {
  Skill,
  SkillCategory,
  Step,
  useJobDraftActions,
  useJobDraftState,
} from '../../context'
import { useOrderState } from '../../../context'

type FormValues = {
  skill: Maybe<Skill>
  skillCategory: Maybe<SkillCategory>
}

type Props = {
  setStep: (step: Step) => void
  onClose: () => void
}

const SkillStep = ({ setStep, onClose }: Props) => {
  const { billing } = useOrderState()
  const {
    skillCategory: skillCategoryInContext,
    skill: skillInContext,
    address,
    addressInstructions,
    instructions,
    uniform,
    uniformInstructions,
    contact,
    contactInstructions,
    rate,
  } = useJobDraftState()

  const { updateSkill, updateDetails, updateSchedules } = useJobDraftActions()

  const [options, setOptions] = useState<any[]>([])

  const [formValues, setFormValues] = useState<FormValues>({
    skillCategory: skillCategoryInContext,
    skill: skillInContext,
  })

  const {
    data: categoriesByCustomRates,
    loading: categoriesByCustomRatesLoading,
  } = useListSkillCategoriesByCustomRatesQuery({
    variables: { customerId: billing!.customer.id },
  })

  const renderCategory = useCallback(
    (item: SkillCategory) => <SkillItem item={item} />,
    []
  )

  useEffect(() => {
    const customRatesBySelectedAccount = categoriesByCustomRates
      ? categoriesByCustomRates.customer.addresses.map((address) => {
          if (address.rateQuotes) {
            const filteredRateQuotesByAccount = address.rateQuotes.filter(
              (rateQuote) => rateQuote.account!.id === billing!.account.id
            )

            return { ...address, rateQuotes: filteredRateQuotesByAccount }
          }

          return address
        })
      : []

    const allCustomRatesBySkillCategories = customRatesBySelectedAccount.flatMap(
      (address) => {
        if (address.rateQuotes) {
          return address.rateQuotes.map((rateQuote) => ({
            ...rateQuote.skill!.category,
            skill: rateQuote.skill,
          }))
        }

        return []
      }
    )

    let skillCategories: any[] = []

    allCustomRatesBySkillCategories.forEach((skillCategoryItem) => {
      const skillCategoryExist = skillCategories.find(
        (item) => item.id === skillCategoryItem.id
      )

      if (skillCategoryExist === undefined) {
        const { skill, ...rest } = skillCategoryItem
        skillCategories.push({ ...rest, skills: [skillCategoryItem.skill] })
      }

      const categoryIndex = skillCategories.findIndex(
        (item) => item.id === skillCategoryItem.id
      )

      skillCategories = replaceAtIndex(skillCategories, categoryIndex, 1, {
        ...skillCategories[categoryIndex],
        skills: Array.from(
          new Set([
            ...skillCategories[categoryIndex].skills,
            skillCategoryItem.skill,
          ])
        ),
      })
    })

    const mappedSkillCategories = skillCategories.map((item) => ({
      item: { ...item, skills: item.skills.map((item) => ({ item })) },
    }))

    setOptions(mappedSkillCategories)
  }, [categoriesByCustomRates])

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handleSubmit = useCallback(
    async ({ skillCategory, skill }: FormValues) => {
      if (skillCategoryInContext && skillInContext) {
        if (
          skillCategoryInContext!.id !== skillCategory!.id ||
          skillInContext!.id !== skill!.id
        ) {
          updateDetails({
            address: null,
            addressInstructions,
            instructions,
            uniform,
            uniformInstructions,
            contact,
            contactInstructions,
            rate,
          })
          updateSchedules({ schedules: [] })
        }
      }

      updateSkill({ skillCategory: skillCategory!, skill: skill! })
      return setStep(Step.DETAILS)
    },
    []
  )

  return (
    <Layout>
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <SingleColumnLayout size="md">
          <Stack align="center" justify="center">
            {address && (
              <Alert
                title="WARNING"
                description="if you change your selected skill category or skill, you will have to redo steps 2 and 3 from here"
                icon={faExclamationTriangle}
                status="warning"
              />
            )}
          </Stack>
          <Stack vertical gap={48}>
            {categoriesByCustomRatesLoading ? (
              <Stack justify="center" align="center">
                <LoadingState
                  overlayColor="white"
                  text="Loading skills categories"
                />
              </Stack>
            ) : (
              <>
                <Stack vertical align="center" gap={24}>
                  <Heading>Select job category</Heading>
                  <Stack wrap gap={16} justify="center">
                    <SingleSelectableField<any>
                      required
                      disabled={categoriesByCustomRatesLoading}
                      fieldId="skillCategory"
                      options={options}
                      itemToKey={(item) => item.id}
                      renderItem={renderCategory}
                      callback={(fieldContext) =>
                        handleFormValuesChange(fieldContext, 'skillCategory')
                      }
                    />
                  </Stack>
                </Stack>
                <SkillSelectField
                  fieldId="skill"
                  skillCategoryFieldId="skillCategory"
                  callback={(fieldContext) =>
                    handleFormValuesChange(fieldContext, 'skill')
                  }
                />
              </>
            )}

            <BottomBar>
              <Button
                a11yLabel="Close job editor"
                appearance="outline"
                label="Cancel"
                type="button"
                onClick={onClose}
              />
              <Button
                disabled={!formValues.skillCategory || !formValues.skill}
                a11yLabel="Submit form"
                label="Continue"
                type="submit"
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  )
}

export default SkillStep
