import { useEffect, useRef, useState } from 'react'
import useModal from '@area2k/use-modal'
import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Stack from '@/components/Stack'
import TableHeader from '@/components/TableHeader'
import Tabs, { TabDefinition } from '@/components/Tabs'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'
import Link from '@/elements/Link'
import Table from '@/components/Table'
import { TableCell, TableRow } from '@/elements/Table'
import { GetCustomerQuery, RelationshipKindEnum } from '@/types/graphql'
import AddWorkerToList from './AddWorkerToListModal'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import IconicButton from '@/components/IconicButton'
import { useRemoveCustomerWorkerRelationshipMutation } from '@/graphql'

type Item = GetCustomerQuery['customer']['workerRelationships'][0]

type Props = {
  customer: GetCustomerQuery['customer']
}
const RELATIONSHIP_TABS: TabDefinition[] = [
  { a11yLabel: 'View favorite workers', label: 'Favorite' },
  { a11yLabel: 'View blocked workers', label: 'Blocked' },
]

const kinds: RelationshipKindEnum[] = [
  RelationshipKindEnum.FAVORITED,
  RelationshipKindEnum.BLOCKED,
]

const HEADER_FIELDS = ['Worker', 'Phone', '']

const WorkerList = ({ customer }: Props) => {
  const [tab, setTab] = useState(0)
  const [visibleWorkers, setVisibleWorkers] = useState<Item[]>([])

  const [showAddModal, hideAddModal] = useModal(
    () => (
      <AddWorkerToList
        customer={customer}
        currentTab={tab}
        hideModal={hideAddModal}
      />
    ),
    [tab]
  )

  const [deleteRelationship] = useRemoveCustomerWorkerRelationshipMutation()

  const handleRemove = async (item: Item) => {
    try {
      await deleteRelationship({
        variables: {
          customerId: customer.id,
          workerId: item.worker.id,
        },
        update: (cache) => {
          cache.modify({
            id: `Customer:${customer.id}`,
            fields: {
              workerRelationships(existingRefs = [], { readField }) {
                return existingRefs.filter(
                  (relationshipRef) =>
                    item.id !== readField('id', relationshipRef)
                )
              },
            },
          })
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setVisibleWorkers(
      customer.workerRelationships.filter(
        (worker) => worker.kind === kinds[tab]
      )
    )
  }, [tab, customer.workerRelationships])

  const TableBody = ({ items }) => (
    <tbody>
      {items.map((item) => (
        <TableRow key={item.id}>
          <TableCell>
            <Stack>
              <div>
                <Avatar
                  firstName={item.worker.user.firstName}
                  size="sm"
                  src={item.worker.avatarUrl}
                />
              </div>
              <TextStack spacing="tight">
                <Link to={`../../workers/${item.worker.id}`}>
                  <Body color="inherit" weight="medium">
                    {item.worker.user.firstName} {item.worker.user.lastName}
                  </Body>
                </Link>
                <Small>{item.worker.user.email}</Small>
              </TextStack>
            </Stack>
          </TableCell>
          <TableCell>
            <Body>{item.worker.user.phoneNumber}</Body>
          </TableCell>
          <TableCell>
            <IconicButton
              a11yLabel="Remove this relationship"
              appearance="clear"
              icon={faTrash}
              size="sm"
              status="danger"
              onClick={() => handleRemove(item)}
            />
          </TableCell>
        </TableRow>
      ))}
    </tbody>
  )

  return (
    <Card
      actions={[
        {
          a11yLabel: 'Add worker to current list',
          label: 'Add worker',
          onAction: showAddModal,
        },
      ]}
      title="Workers"
    >
      <Tabs fit selected={tab} tabs={RELATIONSHIP_TABS} onSelect={setTab} />
      {visibleWorkers.length > 0 ? (
        <Table>
          <TableHeader fields={HEADER_FIELDS} />
          <TableBody items={visibleWorkers} />
        </Table>
      ) : (
        <EmptyState
          title="No workers"
          text="If there were any, they would be here"
        />
      )}
    </Card>
  )
}

export default WorkerList
