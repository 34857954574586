import { configureStore } from '@reduxjs/toolkit'
import layoutReducer from './slices/layoutSlice'
import filtersReducer from './slices/filtersSlice'

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    filters: filtersReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
