import { useState } from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Button from '@/components/Button'
import Card from '@/components/Card'

import Modal from './Modal'
import Stack from './Stack'
import { Body } from './Typography'
import Alert from './Alert'

type Props = {
  title: string
  bodyContentText: string
  acceptButtonLabel: string
  denyButtonLabel: string
  modalSize?: string
  acceptAction: () => void
  denyAction: () => void
  hideModal: () => void
  errorDescription?: string
  errorIcon?: IconDefinition
  errorTitle?: string
}

const ConfirmationModal = ({
  title,
  bodyContentText,
  acceptButtonLabel,
  denyButtonLabel,
  modalSize = 'sm',
  acceptAction,
  denyAction,
  hideModal,
  errorDescription = 'Something went wrong, please contact support',
  errorIcon = faExclamationTriangle,
  errorTitle = 'An error has occurred',
}: Props) => {
  const [error, setError] = useState<boolean>(false)

  const handleAccept = async () => {
    try {
      await acceptAction()
      hideModal()
    } catch (error) {
      setError(true)
      console.error(error)
    }
  }

  const handleDeny = async () => {
    try {
      await denyAction()
      hideModal()
    } catch (error) {
      setError(true)
      console.error(error)
    }
  }

  return (
    <Modal
      title={title}
      size={modalSize}
      disableClickout
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={errorDescription}
            icon={errorIcon}
            title={errorTitle}
            status="danger"
          />
        )}
        <Body>{bodyContentText}</Body>
        <Stack justify="end" gap={10} css={{ marginTop: '10px' }}>
          <Button
            a11yLabel="deny"
            isLoading={undefined}
            label={denyButtonLabel}
            type="button"
            onClick={() => handleDeny()}
            status="danger"
            appearance="outline"
          />

          <Button
            a11yLabel="accept"
            isLoading={undefined}
            label={acceptButtonLabel}
            type="button"
            onClick={() => handleAccept()}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ConfirmationModal
