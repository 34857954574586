import Badge from '@/components/Badge'

import { JobStatus } from './util'

const JobBadge = ({ jobStatus }: { jobStatus: JobStatus }) => {
  return {
    [JobStatus.CANCELLED]: <Badge label="Cancelled" status="danger" />,
    [JobStatus.UPCOMING]: <Badge label="Upcoming" status="success" />,
    [JobStatus.COMPLETED]: <Badge label="Completed" status="theme" />,
    [JobStatus.IN_PROGRESS]: <Badge label="In progress" status="warning" />,
    [JobStatus.UNPUBLISHED]: <Badge label="Unpublished" />,
  }[jobStatus]
}

export default JobBadge
