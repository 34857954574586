import styled from '@/styles'

const Combobox = styled('div', {
  focusPseudoElement: {
    element: 'after',
    activator: '&.focused'
  }
})

Combobox.displayName = 'stitches(Autocomplete.Combobox)'

export default Combobox
