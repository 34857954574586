import { TableHead, TableHeading, TableRow } from '@/elements/Table'

type Props = {
  fields: string[]
}

const TableHeader = ({ fields }: Props) => {
  return (
    <TableHead>
      <TableRow>
        {fields.map((field, index) => (
          <TableHeading key={index}>{field}</TableHeading>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
