import { GetOrderQuery, Job } from '@/types/graphql'
import { parseISO, differenceInMinutes } from 'date-fns'

type JobItem = Pick<Job, 'payRate' | 'quantity' | 'shifts'>
type OrderItem = GetOrderQuery['order']

export const costOfJob = (job: JobItem) => {
  const payRate = job.payRate
  const quantity = job.quantity
  const numberOfShifts = job.shifts.length

  const startHour = parseISO(job.shifts[0].startAt)
  const endHour = parseISO(job.shifts[0].endAt)

  const minsPerShift = differenceInMinutes(endHour, startHour)
  const hoursPerShift =
    minsPerShift < 0 ? 24 + minsPerShift / 60 : minsPerShift / 60

  const totalEstimate = Math.ceil(
    payRate * 1.58 * quantity * numberOfShifts * hoursPerShift
  )

  return totalEstimate
}

export const calculateTotalEstimateByJobs = (jobs: OrderItem['jobs']) =>
  jobs.reduce((acc, el) => acc + costOfJob(el), 0)

export const calculateTotalShiftsByJobs = (jobs: OrderItem['jobs']) =>
  jobs.reduce((acc, el) => acc + el.shifts.length, 0)
