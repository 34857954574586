import { useCallback, useState } from 'react'
import { FieldContext, SubmitHelpers } from '@area2k/use-form'

import {
  useChangeStatusUserMutation,
  useUpdateCustomerAdminProfileMutation,
} from '@/graphql'
import { GetAdminCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import OptionField from '@/form/OptionField'
import TextField from '@/form/TextField'

import { handleMutationFormError } from '@/util/error'

type Props = {
  hideModal: () => void
  customerAdmin: GetAdminCustomerQuery['customerAdmin']
}

type FormValues = {
  active: GetAdminCustomerQuery['customerAdmin']['user']['active']
  firstName: GetAdminCustomerQuery['customerAdmin']['user']['firstName']
  lastName: GetAdminCustomerQuery['customerAdmin']['user']['lastName']
  phoneNumber: GetAdminCustomerQuery['customerAdmin']['user']['phoneNumber']
  department: NonNullable<GetAdminCustomerQuery['customerAdmin']['department']>
  title: NonNullable<GetAdminCustomerQuery['customerAdmin']['title']>
}

const EditAdminInfoModal = ({ hideModal, customerAdmin }: Props) => {
  const initialValues: FormValues = {
    active: customerAdmin.user.active,
    firstName: customerAdmin.user.firstName,
    lastName: customerAdmin.user.lastName,
    phoneNumber: customerAdmin.user.phoneNumber,
    department: customerAdmin.department ?? '',
    title: customerAdmin.title ?? '',
  }

  const [formValues, setFormValues] = useState<FormValues>(initialValues)

  const [changeStatusUserMutation] = useChangeStatusUserMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customerAdmin),
        fields: { user() {} },
      })
    },
  })

  const [
    updateCustomerAdminProfile,
    { loading: isLoading },
  ] = useUpdateCustomerAdminProfileMutation()

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }))
  }

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        if (formValues.active !== initialValues.active) {
          await changeStatusUserMutation({
            variables: {
              userId: customerAdmin!.user.id,
              active: formValues.active,
            },
          })
        }

        await updateCustomerAdminProfile({
          variables: {
            ...values,
            customerAdminId: customerAdmin.id,
          },
        })
        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        })
      }
    },
    [formValues]
  )

  return (
    <Modal size="sm" title="Update info" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName')
              }}
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName')
              }}
            />
          </FormColumns>
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'phoneNumber')
            }}
          />
          <TextField
            fieldId="department"
            label="Department"
            placeholder="Department"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'department')
            }}
          />
          <TextField
            fieldId="title"
            label="Title"
            placeholder="Title"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'title')
            }}
          />
          <Stack justify="apart">
            <OptionField
              appearance="switch"
              fieldId="active"
              label="Account Status"
              callback={async (fieldContext) => {
                await handleFormValuesChange(fieldContext, 'active')
              }}
            />
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default EditAdminInfoModal
