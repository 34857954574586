import { useModal } from 'react-modal-hook'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import { useWorkerInterviewQuery } from '@/graphql'
import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import { Body } from '@/components/Typography'

import InterviewAnswersModal from './InterviewAnswersModal'

type Props = {
  worker: GetWorkerQuery['worker']
}

const InterviewCard = ({ worker }: Props) => {
  const [
    showInterviewAnswersModal,
    hideInterviewAnswersModal,
  ] = useModal(
    () => (
      <InterviewAnswersModal
        hideModal={hideInterviewAnswersModal}
        worker={worker}
      />
    ),
    [worker]
  )

  const query = useWorkerInterviewQuery({
    variables: { workerId: worker.id },
  })

  const interview = query.data?.workerInterview
  return (
    <>
      {interview !== null ? (
        <Card
          actions={[
            {
              a11yLabel: 'View interviews',
              label: 'View',
              onAction: showInterviewAnswersModal,
            },
          ]}
          title="Interviews"
        >
          <Card.Section title="">
            <Body>Click the "view" button to see the interview</Body>
          </Card.Section>
        </Card>
      ) : (
        <Card title="Interviews">
          {interview == null && (
            <EmptyState
              icon={faExclamationCircle}
              title="The worker wasn't interviewed yet"
              text= {`You can write down the interview here by clicking "Add"`}
            />
          )}
        </Card>
      )}
    </>
  )
}

export default InterviewCard
