import { GetJobQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body, Small, Subheading } from '@/components/Typography'
import { centsToCurrency } from '@/util/number'
import { costOfJob } from '@/util/job'

type Props = {
  job: GetJobQuery['job']
}

const PaymentCard = ({ job }: Props) => {
  const totalEstimate = costOfJob(job)

  return (
    <Card title="Payment">
      <Card.Section>
        <Stack vertical>
          <Stack justify="apart">
            <Small>Base pay rate</Small>
            <Body>${centsToCurrency(job.payRate)}</Body>
          </Stack>
          <Stack justify="apart">
            <Small>Base bill rate</Small>
            <Body>${centsToCurrency(job.costRate)}</Body>
          </Stack>
          <Stack justify="apart">
            <Body>Total estimate</Body>
            <Subheading>${centsToCurrency(totalEstimate)}</Subheading>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default PaymentCard
