import { StitchesVariants } from '@stitches/react'
import React, { ComponentProps } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { GroupBox, Title } from './styles'

type groupBoxVariants = StitchesVariants<typeof GroupBox>

export type Props = groupBoxVariants & {
  a11yLabel: string
  label: string
  children: React.ReactNode
}

const FieldSet = ({ a11yLabel, label, children, ...rest }: Props) => {
  return (
    <GroupBox>
      <Title>{label}</Title>
      {children}
    </GroupBox>
  )
}

export default FieldSet
