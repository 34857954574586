import { useCallback, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { currentAgencyVar } from '@/util/apollo/cache'

import Autocomplete from '@/components/Autocomplete'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import TagList from '@/components/TagList'
import ExperienceForm from '@/components/ExperienceForm'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextField from '@/form/TextField'
import TextSelectField from '@/form/TextSelectField'
import OptionField from '@/form/OptionField'

import {
  GenderEnum,
  LanguageEnum,
  GetWorkerQuery,
  PointInput,
  WorkerReference,
} from '@/types/graphql'

import {
  useAddWorkerReferenceMutation,
  useCreateWorkerMutation,
  useListAgencySkillsQuery,
} from '@/graphql'

import { sortBy } from '@/util/array'
import useDebouncedValue from '@/hooks/useDebouncedValue'
import AddressAutocomplete from '@/components/AddressAutocomplete'

type Props = {
  hideModal: () => void
}

type SkillItem = GetWorkerQuery['worker']['skills'][0]

type FormValues = {
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  gender: GenderEnum
  ownsCar: boolean
  language: LanguageEnum
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  coords: PointInput
}

const languageOptions = [
  { label: 'English', value: LanguageEnum.ENGLISH },
  { label: 'Spanish', value: LanguageEnum.SPANISH },
]

const genderOptions = [
  { label: 'Male', value: GenderEnum.MALE },
  { label: 'Female', value: GenderEnum.FEMALE },
  { label: 'Non-Binary', value: GenderEnum.NON_BINARY },
  { label: 'Unspecified', value: GenderEnum.UNSPECIFIED },
]

const CreateWorkerModal = ({ hideModal }: Props) => {
  // ------------------- state & vars -------------------

  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    gender: GenderEnum.UNSPECIFIED,
    ownsCar: false,
    language: LanguageEnum.ENGLISH,
    phoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    coords: { latitude: 0, longitude: 0 },
  })

  const [showExperience, setShowExperience] = useState<boolean>(false)
  const [references, setReferences] = useState<WorkerReference[]>([])

  const [skills, setSkills] = useState<SkillItem[]>([])
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })
  const [createWorker, { loading: isLoading }] = useCreateWorkerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })

  const [addReference] = useAddWorkerReferenceMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })

  // ------------------- skills -------------------

  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (
            skills &&
            skills.some((workerSkill) => workerSkill.id === skill.id)
          ) {
            return false
          }

          return skill.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        })

      return sortBy(availableSkills, 'name')
    }

    return []
  }, [data, debouncedQuery])

  const handleRemove = useCallback(
    (index: number) => {
      let currentSkill = sortedWorkerSkills[index]
      let i = skills.findIndex((item) => item.id === currentSkill.id)
      let skillsCopy = [...skills]
      skillsCopy.splice(i, 1)
      setSkills(skillsCopy)
    },
    [skills]
  )

  const handleSelect = useCallback(
    (item) => {
      setSkills([...skills, item.selectedItem])
    },
    [skills]
  )

  const sortedWorkerSkills = useMemo(() => sortBy(skills, 'name'), [skills])

  // ------------------- submit -------------------

  const submitReferences = useCallback(
    async (id) => {
      try {
        references.map(async (reference) => {
          await addReference({
            variables: {
              ...reference,
              workerId: id,
            },
          })
        })
      } catch (error) {
        console.error(error)
      }
    },
    [references]
  )

  const handleSubmit = useCallback(async () => {
    const skillIds = skills.map(({ id }) => id)
    try {
      const { data } = await createWorker({
        variables: {
          ...formValues,
          agencyId: currentAgency!.id,
          skillIds,
        },
      })

      if (references.length > 0)
        await submitReferences(data!.workerCreate.worker.id)
      hideModal()
    } catch (error) {
      console.error(error)
    }
  }, [skills, formValues, references])

  const handleFormValuesChange = ({ value }, id) => {
    setFormValues({ ...formValues, [id]: value })
  }

  return (
    <Modal title="New worker" onRequestClose={hideModal} disableClickout>
      <Card.Section>
        <Form initialValues={formValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              autoFocus
              required
              fieldId="firstName"
              label="First Name"
              placeholder="First Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'firstName')
              }}
            />
            <TextField
              required
              fieldId="lastName"
              label="Last Name"
              placeholder="Last Name"
              callback={(fieldContext) => {
                handleFormValuesChange(fieldContext, 'lastName')
              }}
            />
          </FormColumns>
          <TextField
            required
            fieldId="email"
            label="Email"
            placeholder="Email"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'email')
            }}
          />
          <TextField
            required
            autoComplete="current-password"
            css={{ letterSpacing: '2px' }}
            fieldId="password"
            label="Password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            type="password"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'password')
            }}
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'phoneNumber')
            }}
          />
          <FormElement htmlFor="" label="Address">
            <AddressAutocomplete
              values={formValues}
              setValues={setFormValues}
            />
          </FormElement>
          <FormElement label="Skills">
            {sortedWorkerSkills.length > 0 && (
              <div style={{ marginBottom: 8 }}>
                <TagList
                  tags={sortedWorkerSkills.map((skill) => skill.name)}
                  onRemove={handleRemove}
                />
              </div>
            )}
            <Autocomplete
              id="tag"
              itemToKey={(item) => item.id}
              items={items}
              itemToString={(item) => (item ? item.name : '')}
              placeholder="Search for skills..."
              selectedItem={null}
              onInputValueChange={({ inputValue }) =>
                setQuery(inputValue || '')
              }
              onSelectedItemChange={handleSelect}
              fixedSelectHeight="lg"
            />
          </FormElement>
          <TextSelectField
            fieldId="gender"
            label="Gender"
            options={genderOptions}
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'gender')
            }}
          />
          <TextSelectField
            fieldId="language"
            label="Primary language"
            options={languageOptions}
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'language')
            }}
          />
          <OptionField
            appearance="checkbox"
            fieldId="ownsCar"
            label="Owns a car?"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'ownsCar')
            }}
          />
        </Form>
        {showExperience && (
          <ExperienceForm
            experiences={references}
            handleAddition={setReferences}
          />
        )}
      </Card.Section>
      <Card.Section>
        <Stack justify="apart">
          <Button
            a11yLabel="experience"
            isLoading={undefined}
            label={showExperience ? 'Hide experience' : 'Add experience'}
            type="button"
            appearance="plain"
            onClick={() => {
              if (references.length === 0) setShowExperience(!showExperience)
            }}
          />
          <Button
            a11yLabel="Submit"
            isLoading={isLoading}
            label="Create"
            type="button"
            onClick={() => {
              handleSubmit()
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}
export default CreateWorkerModal
