import { createContext, Reducer, useContext } from 'react'
import { Range } from 'react-date-range'

import { Maybe, RequiredNotNull } from '@/types'
import {
  AutocompleteCustomersQuery,
  SkillCategory as SK,
  ListUniformsQuery,
  ListWorkersQuery,
} from '@/types/graphql'

import { noOp } from '@/util/actions'

export type Address = AutocompleteCustomersQuery['agency']['customers']['items'][0]['addresses'][0]
export type CustomerAdmin = any
export type SkillCategory = SK
export type Skill = SK['skills'][0]
export type Uniform = ListUniformsQuery['agency']['uniforms'][0]
export type Rate = any
export type Worker = ListWorkersQuery['agency']['workers']['items'][0]

export enum Step {
  SKILL = 1,
  DETAILS = 2,
  SCHEDULE = 3,
  PUBLISHING = 4,
  SELECT_WORKERS = 5,
}

export type Time = {
  hour: number
  minute: number
}

export enum ScheduleType {
  ALL_DAYS = 'all_days',
  WEEKDAYS_ONLY = 'weekdays_only',
  WEEKENDS_ONLY = 'weekends_only',
}

export type Schedule = {
  dateRange: Range
  displayRanges: Range[]
  endTime: string
  payRate: number
  payRateInput: string
  quantity: number
  startTime: string
  type: ScheduleType
}

export type PostSetting = Maybe<'everyone' | 'my_selections'>

export enum PublishInEnum {
  IMMEDIATELY = 'IMMEDIATELY',
  SIX_HOURS = 'SIX_HOURS',
  TWELVE_HOURS = 'TWELVE_HOURS',
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
  FORTY_EIGHT_HOURS = 'FORTY_EIGHT_HOURS',
  SEVENTY_TWO_HOURS = 'SEVENTY_TWO_HOURS',
  NEVER = 'NEVER',
}

export type BaseJobDraftState = {
  address: Maybe<Address>
  addressInstructions: string
  contact: Maybe<CustomerAdmin>
  contactInstructions: string
  instructions: string
  postSetting: PostSetting
  publishIn: PublishInEnum
  rate: Maybe<Rate>
  schedules: Schedule[]
  skillCategory: Maybe<SkillCategory>
  skill: Maybe<Skill>
  uniform: Maybe<Uniform>
  uniformInstructions: string
  selectedWorkers: any[]
}

// ===== TYPES =====
export type JobDraftState = { completedStep: Maybe<Step> } & BaseJobDraftState

export type SkillStateUpdate = RequiredNotNull<
  Pick<BaseJobDraftState, 'skill' | 'skillCategory'>
>
export type DetailsStateUpdate = Omit<
  BaseJobDraftState,
  | 'skill'
  | 'skillCategory'
  | 'postSetting'
  | 'publishIn'
  | 'schedules'
  | 'selectedWorkers'
>

export type ScheduleStateUpdate = Pick<BaseJobDraftState, 'schedules'>
export type PublishingStateUpdate = Pick<
  BaseJobDraftState,
  'postSetting' | 'publishIn'
>
export type SelectWorkersStateUpdate = Pick<
  BaseJobDraftState,
  'selectedWorkers'
>

// ===== CONTEXT =====
export const initialState = {
  address: null,
  addressInstructions: '',
  completedStep: null,
  contact: null,
  contactInstructions: '',
  instructions: '',
  rate: null,
  postSetting: null,
  publishIn: PublishInEnum.IMMEDIATELY,
  schedules: [],
  skill: null,
  skillCategory: null,
  uniform: null,
  uniformInstructions: '',
  selectedWorkers: [],
}

export const JobDraftStateContext = createContext<JobDraftState>(initialState)

export type JobDraftActions = {
  resetState: () => void
  updateSkill: (changes: SkillStateUpdate) => void
  updateDetails: (changes: DetailsStateUpdate) => void
  updateSchedules: (changes: ScheduleStateUpdate) => void
  updatePublishing: (changes: PublishingStateUpdate) => void
  updateSelectedWorkers: (changes: SelectWorkersStateUpdate) => void
}

export const JobDraftActionsContext = createContext<JobDraftActions>({
  resetState: noOp,
  updateSkill: noOp,
  updateDetails: noOp,
  updateSchedules: noOp,
  updatePublishing: noOp,
  updateSelectedWorkers: noOp,
})

export const useJobDraftState = () => useContext(JobDraftStateContext)
export const useJobDraftActions = () => useContext(JobDraftActionsContext)

// ===== ACTIONS =====
export enum JobDraftActionType {
  RESET_STATE = 'reset_state',
  UPDATE_DETAILS = 'update_details',
  UPDATE_PUBLISHING = 'update_publishing',
  UPDATE_SCHEDULES = 'update_schedules',
  UPDATE_SKILL = 'update_skill',
  UPDATE_SELECT_WORKERS = 'update_select_workers',
}

type ResetStateAction = { type: JobDraftActionType.RESET_STATE }
type UpdateSkillAction = {
  type: JobDraftActionType.UPDATE_SKILL
  changes: SkillStateUpdate
}
type UpdateDetailsAction = {
  type: JobDraftActionType.UPDATE_DETAILS
  changes: DetailsStateUpdate
}
type UpdateSchedulesAction = {
  type: JobDraftActionType.UPDATE_SCHEDULES
  changes: ScheduleStateUpdate
}
type UpdatePublishingAction = {
  type: JobDraftActionType.UPDATE_PUBLISHING
  changes: PublishingStateUpdate
}
type UpdateSelectWorkersAction = {
  type: JobDraftActionType.UPDATE_SELECT_WORKERS
  changes: SelectWorkersStateUpdate
}

export type JobDraftAction =
  | ResetStateAction
  | UpdateSkillAction
  | UpdateDetailsAction
  | UpdateSchedulesAction
  | UpdatePublishingAction
  | UpdateSelectWorkersAction

const max = (nextStep: Step, currentStep: Maybe<Step>) =>
  currentStep === null
    ? nextStep
    : nextStep > currentStep
    ? nextStep
    : currentStep

export const stateReducer: Reducer<JobDraftState, JobDraftAction> = (
  state,
  action
) => {
  switch (action.type) {
    case JobDraftActionType.RESET_STATE:
      return initialState

    case JobDraftActionType.UPDATE_SKILL:
      return {
        ...state,
        ...action.changes,
        completedStep: max(Step.SKILL, state.completedStep),
      }

    case JobDraftActionType.UPDATE_DETAILS:
      return {
        ...state,
        ...action.changes,
        completedStep: max(Step.DETAILS, state.completedStep),
      }

    case JobDraftActionType.UPDATE_SCHEDULES:
      return { ...state, ...action.changes, completedStep: Step.SCHEDULE }

    case JobDraftActionType.UPDATE_PUBLISHING:
      return { ...state, ...action.changes, completedStep: Step.PUBLISHING }

    case JobDraftActionType.UPDATE_SELECT_WORKERS:
      return {
        ...state,
        ...action.changes,
        completedStep: Step.SELECT_WORKERS,
      }

    default:
      return state
  }
}
