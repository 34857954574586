import { CustomerAdminFilterSetInput } from '@/types/graphql'
import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'

type Props = {
  query: string
  onQueryChange: (query: string) => void
}

const MyTeamFilters = ({ query, onQueryChange }: Props) => {
  return (
    <Stack>
      <div style={{ flex: 1 }}>
        <TextInput
          placeholder="Search for members..."
          value={query}
          onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          minWidth="sm"
        />
      </div>
    </Stack>
  )
}

export default MyTeamFilters
