import { DateRange, DateRangeProps } from 'react-date-range'

import styled from '@/styles'
import { theme } from '@/styles/colors'

const Wrapper = styled('div', {
  '& *': {
    boxSizing: 'content-box',
    position: 'unset'
  },

  '& .rdrNextPrevButton': {
    backgroundColor: 'white'
  }
})

Wrapper.displayName = 'stitches(DateRangePicker.Wrapper)'

type Props = {
  maxDate?: DateRangeProps['maxDate']
  minDate?: DateRangeProps['minDate']
  value: NonNullable<DateRangeProps['ranges']>
  onChange: NonNullable<DateRangeProps['onChange']>
}

const DateRangePicker = ({ value, ...props }: Props) => {
  return (
    <Wrapper>
      <DateRange
        {...props}
        rangeColors={[theme.themeDark]}
        moveRangeOnFirstSelection={false}
        ranges={value}
        showDateDisplay={false}
      />
    </Wrapper>
  )
}

export default DateRangePicker
