import { useState } from 'react'
import { useModal } from 'react-modal-hook'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import { OrderFilterSetInput } from '@/types/graphql'

import ButtonGroup from '@/elements/ButtonGroup'

import Button from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'

import OrderFiltersModal from './OrderFiltersModal'
import OrderStatusFilter from './OrderStatusFilter'
import TagDisplay from './TagDisplay'
import useMediaQuery from '@/hooks/useMediaQuery'
import IconicButton from '@/components/IconicButton'

export enum OrderStatus {
  ALL = 'ALL',
  IN_PROGRESS = 'IN_PROGRESS',
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
}

type Props = {
  filters: OrderFilterSetInput
  query: string
  onQueryChange: (query: string) => void
  onChangeFilter: <K extends keyof OrderFilterSetInput>(
    key: K,
    value: NonNullable<OrderFilterSetInput[K]>
  ) => void
  onClearFilter: (key: keyof OrderFilterSetInput) => void
  onClearAll: () => void
}

const OrderFilters = ({
  filters,
  query,
  onQueryChange,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}: Props) => {
  const [
    selectedOrderStatusValue,
    setSelectedOrderStatusValue,
  ] = useState<OrderStatus>(OrderStatus.IN_PROGRESS)

  const [showFiltersModal, hideFiltersModal] = useModal(
    () => (
      <OrderFiltersModal
        filters={filters}
        hideModal={hideFiltersModal}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
        orderStatusValue={selectedOrderStatusValue}
        setOrderStatusValue={setSelectedOrderStatusValue}
      />
    ),
    [filters]
  )

  const phoneOnly = useMediaQuery('(max-width: 559px)')

  return (
    <Stack vertical gap={16}>
      <Stack gap={16} wrap>
        <div style={{ flex: 1 }}>
          <TextInput
            placeholder="Search for orders..."
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
            minWidth="sm"
          />
        </div>
        <div>
          {!phoneOnly ? (
            <ButtonGroup>
              <Dropdown
                Content={() => (
                  <div style={{ padding: 16 }}>
                    <OrderStatusFilter
                      filters={filters}
                      idPrefix="dropdown"
                      onChangeFilter={onChangeFilter}
                      onClearFilter={onClearFilter}
                      orderStatusValue={selectedOrderStatusValue}
                      setOrderStatusValue={setSelectedOrderStatusValue}
                    />
                  </div>
                )}
                justify="right"
                size="sm"
              >
                <Button
                  hasPopover
                  a11yLabel="View order status filters"
                  appearance="outline"
                  label="Order status"
                />
              </Dropdown>
              <Button
                a11yLabel="View all order filters"
                appearance="outline"
                label="More filters"
                onClick={showFiltersModal}
              />
            </ButtonGroup>
          ) : (
            <IconicButton
              appearance="clear"
              icon={faFilter}
              size="sm"
              onClick={showFiltersModal}
            />
          )}
        </div>
      </Stack>
      <TagDisplay
        filters={filters}
        onChangeFilter={onChangeFilter}
        onClearAll={onClearAll}
        onClearFilter={onClearFilter}
        orderStatusValue={selectedOrderStatusValue}
        setOrderStatusValue={setSelectedOrderStatusValue}
      />
    </Stack>
  )
}

export default OrderFilters
