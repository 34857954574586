import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'
import TextAreaField from '@/form/TextAreaField'

import { getJobValues } from '../../util'
import { handleMutationFormError } from '@/util/error'

import { useUpdateJobMutation } from '@/graphql'

export type Props = {
  job: GetJobQuery['job']
  hideModal: () => void
}

type FormValues = {
  addressInstructions: string
}

const UpdateAddressModal = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    addressInstructions: job.addressInstructions ?? '',
  }

  const [updateJob, { loading: isLoading }] = useUpdateJobMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await updateJob({
          variables: { ...getJobValues(job), ...values },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    [job]
  )

  return (
    <Modal size="sm" title="Change address" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <TextAreaField
            autoFocus
            fieldId="addressInstructions"
            label="Address instructions"
            placeholder="Provide instructions and details to the workers..."
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateAddressModal
