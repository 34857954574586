import { faCheck, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { addDays, format, isAfter, parse, parseISO } from 'date-fns'
import qs from 'query-string'
import { useCallback, useMemo } from 'react'

import { GetJobQuery, Point } from '@/types/graphql'

import Badge from '@/components/Badge'
import Button from '@/components/Button'
import Card from '@/components/Card'
import FormElement from '@/components/FormElement'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body, Small } from '@/components/Typography'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import TextAreaField from '@/form/TextAreaField'
import TextField from '@/form/TextField'

import NextDateAlert from './NextDateAlert'
import RatingField from './RatingField'

import { getCannonicalTime } from '../../util'
import { formatISO, isDateInvalid } from '@/util/date'
import { handleMutationFormError } from '@/util/error'
import { haversine, metersToMiles } from '@/util/geo'
import { SubmitHelpers } from '@area2k/use-form'
import { useApproveTimesheetMutation } from '@/graphql'

type TimesheetItem = GetJobQuery['job']['timesheets'][0]

export type Props = {
  jobCoords: Point
  timesheet: TimesheetItem
  hideModal: () => void
}

type FormValues = {
  approvedBreakMinutes: string
  approvedCheckinAt: string
  approvedCheckoutAt: string
  rating: number | null
  ratingComment: string
  tipAmount: string
}

const ApproveTimesheetModal = ({ jobCoords, timesheet, hideModal }: Props) => {
  const initialValues: FormValues = useMemo(() => {
    const cannonicalCheckinAt = getCannonicalTime(
      timesheet.shift.startAt,
      timesheet.checkinAt,
      timesheet.reportedCheckinAt
    )

    const cannonicalCheckoutAt = getCannonicalTime(
      timesheet.shift.endAt,
      timesheet.checkoutAt,
      timesheet.reportedCheckoutAt
    )

    return {
      approvedBreakMinutes: timesheet.reportedBreakMinutes ?? '',
      approvedCheckinAt: cannonicalCheckinAt
        ? format(cannonicalCheckinAt, 'HH:mm')
        : '',
      approvedCheckoutAt: cannonicalCheckoutAt
        ? format(cannonicalCheckoutAt, 'HH:mm')
        : '',
      rating: null,
      ratingComment: '',
      tipAmount: '',
    }
  }, [timesheet])

  const [
    approveTimesheet,
    { loading: isLoading },
  ] = useApproveTimesheetMutation()

  const handleSubmit = useCallback(
    async (
      {
        approvedBreakMinutes,
        approvedCheckinAt,
        approvedCheckoutAt,
        tipAmount,
        ...values
      }: FormValues,
      { setFormError }: SubmitHelpers
    ) => {
      try {
        const startAt = parse(
          approvedCheckinAt,
          'HH:mm',
          parseISO(timesheet.shift.startAt)
        )
        let endAt = parse(
          approvedCheckoutAt,
          'HH:mm',
          parseISO(timesheet.shift.startAt)
        )

        if (isDateInvalid(startAt) || isDateInvalid(endAt)) {
          setFormError('invalidTimes', {
            title: 'Shift times are invalid',
            message: `Shift ${
              isDateInvalid(startAt) ? 'start time' : 'end time'
            } could not be parsed.`,
          })
          return
        }

        if (isAfter(startAt, endAt)) {
          endAt = addDays(endAt, 1)
        }

        await approveTimesheet({
          variables: {
            ...values,
            approvedBreakMinutes: Number(approvedBreakMinutes),
            approvedCheckinAt: startAt.toISOString(),
            approvedCheckoutAt: endAt.toISOString(),
            tipAmount: Number(tipAmount),
            timesheetId: timesheet.id,
          },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            INVALID_DATES: () => ({
              title: 'Invalid shift times',
              message: 'Error parsing given shift times.',
              status: 'danger',
            }),
            SHIFT_TOO_LONG: () => ({
              title: 'Shift length too long',
              message: 'Shifts cannot exceed 24 hours.',
              status: 'danger',
            }),
            all: () => ({
              title: 'Unknown service error',
              message: '',
            }),
          },
        })
      }
    },
    [timesheet.id]
  )

  return (
    <Modal size="md" title="Approve timesheet" onRequestClose={hideModal}>
      <Card.Section title="Reported information">
        <FormColumns>
          <FormElement label="Check-in time">
            {timesheet.checkinAt ? (
              timesheet.reportedCheckinAt ? (
                <Stack vertical>
                  <Stack>
                    <Body>{formatISO(timesheet.checkinAt, 'PPpp')}</Body>
                  </Stack>
                  <Stack>
                    <Body>
                      {formatISO(timesheet.reportedCheckinAt, 'PPpp')}
                    </Body>
                    <Badge status="warning" label="Self-reported" />
                  </Stack>
                </Stack>
              ) : (
                <Body>{formatISO(timesheet.checkinAt, 'PPpp')}</Body>
              )
            ) : timesheet.reportedCheckinAt ? (
              <Stack>
                <Body>{formatISO(timesheet.reportedCheckinAt, 'PPpp')}</Body>
                <Badge status="warning" label="Self-reported" />
              </Stack>
            ) : (
              <Small>Not reported</Small>
            )}
          </FormElement>
          <FormElement label="Check-out time">
            {timesheet.checkoutAt ? (
              timesheet.reportedCheckoutAt ? (
                <Stack vertical>
                  <Stack>
                    <Body>{formatISO(timesheet.checkoutAt, 'PPpp')}</Body>
                  </Stack>
                  <Stack>
                    <Body>
                      {formatISO(timesheet.reportedCheckoutAt, 'PPpp')}
                    </Body>
                    <Badge status="warning" label="Self-reported" />
                  </Stack>
                </Stack>
              ) : (
                <Body>{formatISO(timesheet.checkoutAt, 'PPpp')}</Body>
              )
            ) : timesheet.reportedCheckoutAt ? (
              <Stack>
                <Body>{formatISO(timesheet.reportedCheckoutAt, 'PPpp')}</Body>
                <Badge status="warning" label="Self-reported" />
              </Stack>
            ) : (
              <Small>Not reported</Small>
            )}
          </FormElement>
        </FormColumns>
        <FormColumns>
          <FormElement label="Check-in GPS">
            {timesheet.checkinCoords ? (
              <Stack>
                <Body>
                  {metersToMiles(
                    haversine(timesheet.checkinCoords, jobCoords)
                  ).toFixed(1)}
                  mi from job site
                </Body>
                <Button
                  as="a"
                  appearance="plain"
                  href={qs.stringifyUrl({
                    url: 'https://www.google.com/maps/search/',
                    query: {
                      api: 1,
                      query: `${timesheet.checkinCoords.latitude},${timesheet.checkinCoords.longitude}`,
                    },
                  })}
                  iconRight={faExternalLinkAlt}
                  label="View"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Stack>
            ) : (
              <Small>Not reported</Small>
            )}
          </FormElement>
          <FormElement label="Check-out GPS">
            {timesheet.checkoutCoords ? (
              <Stack>
                <Body>
                  {metersToMiles(
                    haversine(timesheet.checkoutCoords, jobCoords)
                  ).toFixed(1)}
                  mi from job site
                </Body>
                <Button
                  as="a"
                  appearance="plain"
                  href={qs.stringifyUrl({
                    url: 'https://www.google.com/maps/search/',
                    query: {
                      api: 1,
                      query: `${timesheet.checkoutCoords.latitude},${timesheet.checkoutCoords.longitude}`,
                    },
                  })}
                  iconRight={faExternalLinkAlt}
                  label="View"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Stack>
            ) : (
              <Small>Not reported</Small>
            )}
          </FormElement>
        </FormColumns>
        <FormElement label="Break time">
          <Body>{timesheet.reportedBreakMinutes ?? 0} minutes</Body>
        </FormElement>
      </Card.Section>
      <Card.Section title="Approval">
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <TextField
              fieldId="approvedCheckinAt"
              label="Shift start"
              type="time"
            />
            <TextField
              fieldId="approvedCheckoutAt"
              label="Shift end"
              type="time"
            />
          </FormColumns>
          <NextDateAlert />
          <FormColumns>
            <TextField
              fieldId="approvedBreakMinutes"
              label="Approved break minutes"
              type="number"
            />
            <TextField fieldId="tipAmount" label="Tip amount" />
          </FormColumns>
          <RatingField fieldId="rating" />
          <TextAreaField
            fieldId="ratingComment"
            label="Worker rating comments"
          />
          <Stack justify="end">
            <Button
              a11yLabel="Approve timesheet"
              iconLeft={faCheck}
              isLoading={isLoading}
              label="Approve"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default ApproveTimesheetModal
