import { useParams } from 'react-router-dom'
import useModal from '@area2k/use-modal'
import { currentAdminVar } from '@/util/apollo/cache'
import { useReactiveVar } from '@apollo/client'
import {
  useGetJobQuery,
  useUnPublishJobMutation,
  usePublishJobMutation,
  useCancelJobMutation,
} from '@/graphql'
import { faClock, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

import Alert from '@/components/Alert'
import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Title } from '@/components/Typography'

import DetailsCard from './DetailsCard'
import JobBadge from './JobBadge'
import PaymentCard from './PaymentCard'
import ShiftsCard from './ShiftsCard'
import TimesheetList from './TimesheetList'
import WorkerList from './WorkerList'
import CancelModal from './JobCancelModal'

import { getJobStatusByJob, JobStatus } from './util'
import { formatISO } from '@/util/date'

import PageSkeleton from './PageSkeleton'

const Job = () => {
  const { jobId } = useParams()

  const currentAdmin = useReactiveVar(currentAdminVar)

  const query = useGetJobQuery({ variables: { jobId } })

  const [cancelJob] = useCancelJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: {
          cancelledAt() {},
        },
      })
    },
  })

  const [jobPublish] = usePublishJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: { job() {} },
      })
    },
  })

  const [jobUnpublish] = useUnPublishJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: { job() {} },
      })
    },
  })

  const onCancelJob = async (values) => {
    try {
      await cancelJob({
        variables: {
          ...values,
          jobId: jobId,
          userId: currentAdmin!.user.id,
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const togglePublish = async (value: boolean) => {
    try {
      if (value) {
        return await jobUnpublish({ variables: { jobId } })
      }

      return await jobPublish({ variables: { jobId } })
    } catch (err) {
      console.log(err)
    }
  }

  const [showCancelModal, hideCancelModal] = useModal(
    () => (
      <CancelModal
        hideModal={hideCancelModal}
        onConfirm={(values) => {
          hideCancelModal()
          onCancelJob(values)
        }}
      />
    ),
    []
  )

  if (!query.data) {
    return <PageSkeleton />
  }

  const job = query.data.job
  const jobStatus = getJobStatusByJob(job)
  const jobPublished = job.published

  return (
    <Page
      media={<Avatar src={job.skill.imageUrl} />}
      primaryAction={
        jobStatus === JobStatus.CANCELLED || jobStatus === JobStatus.COMPLETED
          ? undefined
          : {
              a11yLabel: 'Cancel this job',
              label: 'Cancel job',
              dangerous: true,
              onAction: showCancelModal,
            }
      }
      secondaryActions={
        jobStatus === JobStatus.CANCELLED || jobStatus === JobStatus.COMPLETED
          ? undefined
          : [
              {
                a11yLabel: 'Remove this job from the board',
                label: jobPublished ? 'Unpublish Job' : 'Publish job',
                icon: jobPublished ? faEyeSlash : faEye,
                onAction: () => togglePublish(jobPublished),
              },
            ]
      }
      subtitle={`${formatISO(job.firstShiftStartAt, 'PP')} — ${formatISO(
        job.lastShiftEndAt,
        'PP'
      )}`}
      title={
        <Stack gap={16}>
          <Title>{job.skill.name}</Title>
          <JobBadge
            jobStatus={
              jobPublished
                ? jobStatus
                : jobStatus === JobStatus.COMPLETED
                ? jobStatus
                : JobStatus.UNPUBLISHED
            }
          />
        </Stack>
      }
    >
      <Stack vertical gap={24}>
        {job.postedAt && !job.published && (
          <div style={{ width: '100% ' }}>
            <Alert
              icon={faClock}
              status="neutral"
              title="Job scheduled to be posted"
              description={`This job will be published to workers on ${formatISO(
                job.postedAt,
                "EEE, MMM do 'at' h:mmbbb"
              )}`}
            />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <PrimarySecondaryLayout>
            <PrimarySecondaryLayout.Primary>
              <Stack vertical gap={24}>
                <WorkerList job={job} jobStatus={jobStatus} />
                <TimesheetList job={job} jobStatus={jobStatus} />
              </Stack>
            </PrimarySecondaryLayout.Primary>
            <PrimarySecondaryLayout.Secondary>
              <Stack vertical gap={24}>
                <DetailsCard job={job} />
                <ShiftsCard job={job} />
                <PaymentCard job={job} />
              </Stack>
            </PrimarySecondaryLayout.Secondary>
          </PrimarySecondaryLayout>
        </div>
      </Stack>
    </Page>
  )
}

export default Job
