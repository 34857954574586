import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons'

import styled from '@/styles'

import Icon from '@/elements/Icon'
import Text from '@/elements/Text'

import Button from '@/components/Button'
import SingleColumnLayout from '@/components/SingleColumnLayout'
import SelectableBox from '@/components/SelectableBox'
import Stack from '@/components/Stack'
import { Body, Heading, Subheading } from '@/components/Typography'

import Form from '@/form'
import TextSelectField from '@/form/TextSelectField'

import BottomBar from '../BottomBar'
import Layout from '../Layout'

import {
  PostSetting,
  PublishInEnum,
  Step,
  useJobDraftActions,
  useJobDraftState,
} from '../../context'
import { useOrderActions } from '../../../context'

const PostSetttings = styled('div', {
  display: 'grid',
  gap: 24,
  gridAutoRows: '1fr',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',

  '& > *': {
    height: '100%',
  },
  '@phoneOnly': {
    gridTemplateColumns: 'auto',
  },
})

const publisInOptions = [
  { value: PublishInEnum.IMMEDIATELY, label: 'Immediately' },
  { value: PublishInEnum.SIX_HOURS, label: 'in 6 hours' },
  { value: PublishInEnum.TWELVE_HOURS, label: 'in 12 hours' },
  {
    value: PublishInEnum.TWENTY_FOUR_HOURS,
    label: 'in 1 day',
  },
  {
    value: PublishInEnum.FORTY_EIGHT_HOURS,
    label: 'in 2 days',
  },
  {
    value: PublishInEnum.SEVENTY_TWO_HOURS,
    label: 'in 3 days',
  },
  { value: PublishInEnum.NEVER, label: 'Never' },
]

type FormValues = {
  publishIn: PublishInEnum
}

type Props = {
  setStep: (step: Step) => void
}

const PublishingStep = ({ setStep }: Props) => {
  const { addJob } = useOrderActions()

  const jobState = useJobDraftState()
  const { resetState, updatePublishing } = useJobDraftActions()

  const [postSetting, setPostSetting] = useState<PostSetting>(
    jobState.postSetting
  )

  const handleSettingChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setPostSetting(ev.currentTarget.value as PostSetting)
    },
    []
  )

  const initialValues: FormValues = useMemo(
    () => ({ publishIn: jobState.publishIn }),
    []
  )

  const handleSubmit = useCallback(
    async ({ publishIn }: FormValues) => {
      if (postSetting === 'my_selections') {
        setPostSetting('my_selections')
        updatePublishing({ postSetting, publishIn })
        return setStep(Step.SELECT_WORKERS)
      }

      addJob({
        ...jobState,
        publishIn:
          postSetting === 'everyone' ? PublishInEnum.IMMEDIATELY : publishIn,
      })
      resetState()
    },
    [postSetting]
  )

  return (
    <Layout>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <SingleColumnLayout size="sm">
          <Stack vertical align="center" gap={24}>
            <Heading>Who should see your job post first?</Heading>
            <PostSetttings>
              <SelectableBox
                id="postSetting.everyone"
                isSelected={postSetting === 'everyone'}
                name="postSetting"
                type="radio"
                value="everyone"
                onChange={handleSettingChange}
              >
                <div style={{ padding: 24 }}>
                  <Stack vertical gap={16}>
                    <Text color="light" size="xxxl">
                      <Icon icon={faUsers} />
                    </Text>
                    <Stack vertical gap={8}>
                      <Subheading>Everyone</Subheading>
                      <Body>
                        The job will be shown to all qualified employees on the
                        platform in searches.
                      </Body>
                    </Stack>
                  </Stack>
                </div>
              </SelectableBox>
              <SelectableBox
                id="postSetting.my_selections"
                isSelected={postSetting === 'my_selections'}
                name="postSetting"
                type="radio"
                value="my_selections"
                onChange={handleSettingChange}
              >
                <div style={{ padding: 24 }}>
                  <Stack vertical gap={16}>
                    <Text color="light" size="xxxl">
                      <Icon icon={faUserCheck} />
                    </Text>
                    <Stack vertical gap={8}>
                      <Subheading>My selections</Subheading>
                      <Body>
                        Pick the employees you would like to show the job before
                        everyone else.
                      </Body>
                    </Stack>
                  </Stack>
                </div>
              </SelectableBox>
            </PostSetttings>

            {postSetting === 'my_selections' && (
              <div style={{ width: '100%' }}>
                <TextSelectField
                  fieldId="publishIn"
                  label="Automatically publish to the job board"
                  options={publisInOptions}
                />
              </div>
            )}

            <BottomBar>
              <Button
                a11yLabel="Go back to previous step"
                appearance="outline"
                label="Back"
                type="button"
                onClick={() => setStep(Step.SCHEDULE)}
              />

              <Button
                a11yLabel="Submit form"
                disabled={!postSetting}
                label={
                  postSetting === null || postSetting === 'everyone'
                    ? 'Finish job'
                    : 'Select workers'
                }
                type="submit"
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  )
}

export default PublishingStep
