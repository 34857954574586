import { SubmitHelpers } from '@area2k/use-form'
import { useCallback } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import Form from '@/form'

import WorkerAutocompleteField, {
  WorkerItem,
} from '../../WorkerAutocompleteField'

import { handleMutationFormError } from '@/util/error'

import { useHireWorkerMutation } from '@/graphql'

export type Props = {
  jobId: string
  hideModal: () => void
}

type FormValues = {
  worker: WorkerItem | null
}

const AddWorkerModal = ({ jobId, hideModal }: Props) => {
  const initialValues: FormValues = {
    worker: null,
  }

  const [hireWorker, { loading: isLoading }] = useHireWorkerMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await hireWorker({
          variables: { jobId, workerId: values.worker!.id },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            ALREADY_HIRED: () => ({
              title: 'Worker already hired',
              message: 'This worker has already been hired for this job.',
            }),
            JOB_UNAVAILABLE: () => ({
              title: 'Job not available',
              message: 'This job is no longer available to hire workers.',
            }),
          },
        })
      }
    },
    [jobId]
  )

  return (
    <Modal size="sm" title="Add worker" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <WorkerAutocompleteField fieldId="worker" label="Select worker" />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={isLoading}
              label="Hire"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default AddWorkerModal
