import SkillStep from './SkillStep'
import DetailsStep from './DetailsStep'
import ScheduleStep from './ScheduleStep'
import PublishingStep from './PublishingStep'
import SelectWorkersStep from './SelectWorkers'

import { Step } from '../context'

type Props = {
  step: Step
  setStep: (step: Step) => void
  onClose: () => void
}

const Steps = ({ step, setStep, onClose }: Props) => {
  return {
    [Step.SKILL]: <SkillStep setStep={setStep} onClose={onClose} />,
    [Step.DETAILS]: <DetailsStep setStep={setStep} />,
    [Step.SCHEDULE]: <ScheduleStep setStep={setStep} />,
    [Step.PUBLISHING]: <PublishingStep setStep={setStep} />,
    [Step.SELECT_WORKERS]: <SelectWorkersStep setStep={setStep} />,
  }[step]
}

export default Steps
