import { StitchesVariants } from '@stitches/core'
import { useCallback, useState } from 'react'

import Shimmer from '@/elements/Shimmer'
import Icon from '@/elements/Icon'

import styled from '@/styles'
import TextStack from './TextStack'
import { faPen } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled(Shimmer, {
  alignItems: 'center',
  boxSize: '100%',
  display: 'flex',
  fontSize: '100%',
  justifyContent: 'center',

  overflow: 'hidden',

  variants: {
    size: {
      xs: {
        boxSize: 24,
      },
      sm: {
        boxSize: 36,
      },
      md: {
        boxSize: 48,
      },
      lg: {
        boxSize: 64,
      },
      xl: {
        boxSize: 128,
      },
      profilePic: {
        boxSize: '95%',
      },
    },
    rounded: {
      true: {
        borderRadius: '$round',
      },
      false: {
        borderRadius: '$lg',
      },
    },
    loaded: {
      true: {
        animation: 'none',
        backgroundColor: 'transparent',
      },
      false: {
        animation: 'none',
        backgroundColor: '$themeA8',
      },
    },
  },

  defaultVariants: {
    rounded: false,
    size: 'md',
  },
})

const Image = styled('img', {
  height: '100%',
  width: '100%',

  variants: {
    error: {
      true: {
        display: 'none',
      },
      false: {},
    },
    aspect: {
      default: {},
      primary: {
        aspectRatio: 1,
      },
    },
  },
})

const DefaultImg = styled(TextStack, {
  alignItems: 'center',
  aspectRatio: 1,
  display: 'flex',
  justifyContent: 'center',
  padding: 2,

  variants: {
    avatar: {
      xs: {
        fontSize: 18,
        fontWeight: '$medium',
      },
      sm: {
        fontSize: 25,
        fontWeight: '$medium',
      },
      md: {
        fontSize: 38,
        fontWeight: '$medium',
      },
      lg: {
        fontSize: 46,
        fontWeight: '$medium',
      },
      xl: {
        fontSize: 94,
        fontWeight: '$medium',
      },
      profilePic: {
        fontSize: 145,
        fontWeight: '$medium',
      },
    },
    color: {
      true: {
        color: '$neutralGhost',
      },
      false: {
        color: '$textDefault',
      },
    },
  },

  defaultVariants: {
    color: false,
  },
})

const IconicAction = styled(Icon, {
  position: 'absolute',
  right: 10,
  top: 10,
  padding: 10,
  color: '$themeDefault',

  variants: {
    persistent: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',

        [`${Wrapper}:hover &`]: {
          display: 'block',
          borderRadius: '45px 45px',
          backgroundColor: '$whiteA68',
          height: '45px',
          width: '45px',
        },
      },
    },
  },

  defaultVariants: {
    persistent: false,
  },
})

export type Variants = Omit<StitchesVariants<typeof Wrapper>, 'loaded'>

export type Props = Variants & {
  a11yLabel?: string
  color?: boolean
  firstName?: string
  icon?
  initials?: string
  showIconicAction?: boolean
  src?: string | null
}

const Avatar = ({
  a11yLabel,
  color,
  firstName,
  icon = faPen,
  initials,
  showIconicAction = false,
  src,
  ...variants
}: Props) => {
  const [isError, setIsError] = useState(false)

  const firstLetter = firstName?.charAt(0).toUpperCase()

  const handleImgError = useCallback(() => setIsError(true), [])

  return (
    <Wrapper {...variants} loaded={src ? 'true' : 'false'}>
      {src ? (
        <Image
          alt="Avatar"
          aria-label={a11yLabel}
          error={isError}
          src={src}
          onError={handleImgError}
          aspect="primary"
        />
      ) : (
        <DefaultImg avatar={variants.size} color={color}>
          {firstLetter}
        </DefaultImg>
      )}
      {showIconicAction && <IconicAction icon={icon} />}
    </Wrapper>
  )
}

Avatar.Wrapper = Wrapper
Wrapper.displayName = 'stitches(Avatar.Wrapper)'

Avatar.Image = Image
Image.displayName = 'stitches(Avatar.Image)'

export default Avatar
