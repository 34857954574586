import { useState } from 'react'
import { useModal } from 'react-modal-hook'
import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

import { useWorkerInterviewQuery } from '@/graphql'
import { GetWorkerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Icon from '@/elements/Icon'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Body, Small, Subheading } from '@/components/Typography'

import styled from '@/styles'

import ChangeStatusModal from './ChangeStatusModal'
import Comments from '../CommentsCard/Coments'
import ExperiencesList from './ExperiencesList'
import PageSkeleton from '../PageSkeleton'
import ProfileCard from '../ProfileCard'

type Props = {
  worker: GetWorkerQuery['worker']
}

const Separator = styled(Stack, {
  padding: '20px 20px 0',
})

const DropDown = styled('button', {
  all: 'unset',
  alignItems: 'center',
  display: 'flex',
  height: 24,
  justifyContent: 'space-between',
  padding: '10px 0px 10px',
  alignContent: 'center',
  backgroundColor: 'transparent',

  cursor: 'pointer',
  outline: 'none',

  textDecoration: 'none',

  '@phoneOnly': {
    gap: 10,
  },

  '&:hover': {
    backgroundColor: '$whiteA8',
  },
})

const WorkerInProgress = ({ worker }: Props) => {
  const [dropDown, setDropDown] = useState<boolean>(false)
  const [showChangeModal, hideChangeModal] = useModal(
    () => <ChangeStatusModal hideModal={hideChangeModal} worker={worker} />,
    [worker]
  )
  const query = useWorkerInterviewQuery({
    variables: { workerId: worker.id },
  })

  if (!query.data) {
    return <PageSkeleton />
  }

  const interview = query.data.workerInterview

  return (
    <PrimarySecondaryLayout>
      <PrimarySecondaryLayout.Primary>
        <Stack vertical gap={24}>
          <Card
            title={
              interview == null ? (
                'Interview'
              ) : (
                <DropDown onClick={() => setDropDown(!dropDown)}>
                  <Stack gap={5}>
                    Interview
                    <Icon
                      fixedWidth
                      icon={dropDown ? faChevronDown : faChevronUp}
                    />
                  </Stack>
                </DropDown>
              )
            }
            actions={[
              {
                a11yLabel: 'View interview',
                label: 'Approve',
                onAction: showChangeModal,
              },
            ]}
          >
            {interview == null && (
              <EmptyState
                icon={faExclamationCircle}
                title="The worker does not have an interview"
                text="you can pass the interview and approve the worker"
              />
            )}
            {!dropDown && interview && <Separator />}
            {dropDown && interview && (
              <Card.Section>
                <Subheading>Answers & Questions</Subheading>

                <Card.Section>
                  <Stack wrap justify="apart" gap={20}>
                    {interview.workerInterviewAnswers.map((answer, index) => (
                      <Stack
                        key={index}
                        vertical
                        verticalGap={8}
                        style={{ width: '45%' }}
                      >
                        <Body weight="medium">
                          {`${index + 1}.- ${
                            answer.interviewQuestion.question
                          }`}
                        </Body>
                        <Small size="md" weight="semibold" color="theme">
                          {answer.answer}
                        </Small>
                      </Stack>
                    ))}
                  </Stack>
                </Card.Section>
              </Card.Section>
            )}
          </Card>
          <Comments worker={worker} />
          <ExperiencesList worker={worker} />
        </Stack>
      </PrimarySecondaryLayout.Primary>
      <PrimarySecondaryLayout.Secondary>
        <Stack vertical gap={24}>
          <ProfileCard worker={worker} />
        </Stack>
      </PrimarySecondaryLayout.Secondary>
    </PrimarySecondaryLayout>
  )
}

export default WorkerInProgress
