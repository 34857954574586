import TableElement from '@/elements/Table'
import styled from '@/styles'

const Wrapper = styled('div', {
  overflowX: 'auto',
})

const Table = ({ children }) => {
  return (
    <Wrapper>
      <TableElement>{children}</TableElement>
    </Wrapper>
  )
}

export default Table
