import { default as Av } from '@/components/Avatar'
import Stack from '@/components/Stack'
import { JobDraftState } from '../JobEditor/context'
import styled from '@/styles'

const Avatar = styled(Av, {
  border: '2px solid white',
  marginLeft: -12,
})

type Props = {
  items: JobDraftState['selectedWorkers']
}

const WorkerAvatarGroup = ({ items }: Props) => (
  <Stack css={{ flexDirection: 'row-reverse' }} gap={0}>
    {items.length > 3 && <span>+{items.length - 3}</span>}

    {items.map(
      (item, index) =>
        index < 3 && (
          <Avatar key={index} size="sm" rounded src={item.avatarUrl} />
        )
    )}
  </Stack>
)

export default WorkerAvatarGroup
