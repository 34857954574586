import styled from '@/styles'
import Icon from '@/elements/Icon'

export const IconProgressCustomerProfile = styled(Icon, {
  variants: {
    completed: {
      true: {
        color: '$themeDefault',
      },
      false: {
        color: '$textLightest',
      },
    },
  },
})
