import { useParams } from 'react-router-dom'

import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'

import PageSkeleton from './PageSkeleton'
import OrdersList from './OrdersList'
import ProfileCard from './ContactSection'

import { useGetAccountQuery } from '@/graphql'

const Account = () => {
  const { accountId } = useParams()

  const query = useGetAccountQuery({
    variables: { accountId },
  })

  if (!query.data) {
    return <PageSkeleton />
  }

  const { account } = query.data

  return (
    <Page title={account.name} subtitle={account.customer.name}>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical>
            <OrdersList account={account} />
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical>
            <ProfileCard account={account} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Account
