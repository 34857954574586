import styled from '@/styles'

import Shimmer from '@/elements/Shimmer'

import Stack from '@/components/Stack'

import { nTimes } from '@/util/array'

const repeat: number = 5

const Comment = styled(Shimmer, {
    height: 22,
    width: 100
})

export type Props = {
    count?: number
    gap?: number
}

const CommentsList = ({ count = repeat, gap }: Props) => {
    return (
        <Stack wrap gap={gap}>
            {nTimes(count, (index) => (
                <Comment key={index} />
            ))}
        </Stack>
    )
}

Comment.displayName = 'stitches(Skeleton.CommentList.Comment)'

export default CommentsList
