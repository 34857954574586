import Stack from '@/components/Stack'
import TextInput from '@/components/TextInput'

type Props = {
  tab: number
  query: string
  onQueryChange: (query: string) => void
}

const CandidateFilters = ({ tab, query, onQueryChange }: Props) => {
  const worker =
    tab === 1 ? 'applicant' : tab === 2 ? 'worker' : 'canditate'

  return (
    <Stack vertical gap={16}>
      <Stack gap={16}>
        <div style={{ flex: 1 }}>
          <TextInput
            placeholder={`Search for ${worker}...`}
            value={query}
            onChange={(ev) => onQueryChange(ev.currentTarget.value)}
          />
        </div>
      </Stack>
    </Stack>
  )
}

export default CandidateFilters
