import { useCallback, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { SubmitHelpers } from '@area2k/use-form'

import { isEqual } from 'lodash'

import { DurationEnum, Maybe, WorkerReference } from '@/types/graphql'
import { useListAgencySkillsQuery } from '@/graphql'

import Button from '@/components/Button'
import FormElement from '@/components/FormElement'
import Stack from '@/components/Stack'

import Form from '@/form'
import AutocompleteField from '@/form/AutocompleteField'
import FormColumns from '@/form/FormColumns'
import MaskedInputField from '@/form/MaskedInputField'
import TextAreaField from '@/form/TextAreaField'
import TextField from '@/form/TextField'
import TextSelectField from '@/form/TextSelectField'

import { CandidateItem } from '@/routes/Agency/Workers/helpers'
import { currentAgencyVar } from '@/util/apollo/cache'

import ExperienceItem from './ExperienceItem'

type Props = {
  experiences: WorkerReference[]
  deleteExperiences?: string[]
  handleAddition: (item) => void
  handleDeletion?: (item) => void
}
type FormValues = {
  name: string
  city: string
  duration: DurationEnum
  employerName: string
  phoneNumber: Maybe<string>
  summary: Maybe<string>
  email: Maybe<string>
  title: string
}

const durationOptions = [
  { label: 'From 0 to 6 months', value: DurationEnum.FROM_0_TO_6_MONTHS },
  { label: 'From 6 to 12 months', value: DurationEnum.FROM_6_TO_12_MONTHS },
  { label: 'From 1 to 3 years', value: DurationEnum.FROM_1_TO_3_YEARS },
  { label: 'More than 3 years', value: DurationEnum.MORE_THAN_3_YEARS },
]

const ExperienceForm = ({
  experiences,
  handleAddition,
  handleDeletion,
  deleteExperiences,
}: Props) => {
  const [query, setQuery] = useState('')
  const initialValues: FormValues = {
    name: '',
    city: '',
    duration: DurationEnum.FROM_0_TO_6_MONTHS,
    employerName: '',
    phoneNumber: '',
    summary: '',
    email: 'no@mail.com',
    title: '',
  }

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const getItems = useCallback(() => {
    if (data) {
      const items = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (query !== '') return skill.name.toLowerCase().includes(query)
          return true
        })

      return items
    }
    return []
  }, [query])

  const handleSubmit = async (values: FormValues, { clearForm }: SubmitHelpers) => {
    const experience: WorkerReference = { ...values, name: values.name.name }
    handleAddition([...experiences, experience])
    clearForm()
  }

  const handleRemove = (item: WorkerReference) => {
    item.id && handleDeletion([...deleteExperiences, item.id])
    const index = experiences.findIndex((experience) =>
      isEqual(experience, item)
    )
    var copyArray = [...experiences]
    copyArray.splice(index, 1)
    handleAddition([...copyArray])
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <FormColumns>
        <FormElement label="Experience Form">
          <AutocompleteField<CandidateItem['skills'][0]>
            required
            fieldId="name"
            items={getItems()}
            itemToKey={(item) => item.id}
            itemToString={(item) => (item ? item.name : '')}
            placeholder="Select a skill..."
            label="Job"
            onInputValueChange={({ inputValue }) => setQuery(inputValue ?? '')}
            fixedSelectHeight="lg"
          />
          <TextField required fieldId="city" label="City" placeholder="City" />
          <TextSelectField
            fieldId="durationExperience"
            label="Duration"
            options={durationOptions}
          />
          <TextField
            required
            fieldId="employerName"
            label="Employer Name"
            placeholder="Employer Name"
          />
          <MaskedInputField
            fieldId="phoneNumber"
            incompletemessage="Must be a valid phone number"
            label="Phone Number"
            mask="(000) 000-0000"
            placeholder="(555) 555-5555"
            type="tel"
          />
          <TextAreaField
            fieldId="summary"
            label="Summary"
            placeholder="Summary..."
          />
          <Stack justify="end">
            <Button
              a11yLabel="addExperience"
              isLoading={undefined}
              label="Add experience"
              appearance="secondary"
              type="submit"
            />
          </Stack>
        </FormElement>
        <FormElement label="Experience list">
          {experiences.length > 0
            ? experiences.map((item: WorkerReference, index: number) => (
                <ExperienceItem
                  key={index}
                  reference={item}
                  handleRemove={handleRemove}
                />
              ))
            : null}
        </FormElement>
      </FormColumns>
    </Form>
  )
}

export default ExperienceForm
