import { useEffect, useMemo } from 'react'
import { useReactiveVar } from '@apollo/client'
import {
  addDays,
  formatISO as formatISO8601,
  parseISO,
  startOfDay,
} from 'date-fns'

import { currentAdminVar, currentAgencyVar } from '@/util/apollo/cache'
import useFilters from '@/hooks/useFilters'
import { Role } from '@/routes/PrivateRoute'
import { JobFilterSetInput, ListShiftsByWeekQuery } from '@/types/graphql'

import styled from '@/styles'

import LoadingState from '@/components/LoadingState'

import ShiftsColumn from './ShiftsColumn'

import { nTimes } from '@/util/array'

import { useListShiftsByWeekQuery } from '@/graphql'
import EmptyState from '@/components/EmptyState'

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  width: '100%',
})

type Shift = ListShiftsByWeekQuery['agency']['shiftsByWeek'][0]
type Props = {
  startDate: Date
}

const initialFilters: JobFilterSetInput = {}

const ShiftGrid = ({ startDate }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)
  const currentAdmin = useReactiveVar(currentAdminVar)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<JobFilterSetInput>(initialFilters)

  const finalFilters = useMemo<JobFilterSetInput>(() => {
    const value = { ...filters }

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const startDateISO = useMemo(
    () => formatISO8601(startDate, { representation: 'date' }),
    [startDate]
  )

  const { data, loading } = useListShiftsByWeekQuery({
    variables: {
      agencyId: currentAgency!.id,
      startDate: startDateISO,
      filters: finalFilters,
    },
  })

  const shiftsByDate = useMemo(
    () => (data ? getShiftsByDate(data.agency.shiftsByWeek) : []),
    [data]
  )

  useEffect(() => {
    if (
      currentAdmin!.__typename === 'CustomerAdmin' &&
      currentAdmin!.role === Role.CUSTOMER_ADMIN
    ) {
      setFilter('customers', { value: currentAdmin!.customer!.id })
    }
  }, [currentAdmin])

  if (loading) {
    return <LoadingState />
  }

  return (
    <Wrapper>
      {nTimes(7, (index) => {
        const date = addDays(startDate, index)
        return (
          <ShiftsColumn
            key={index}
            date={date}
            shifts={shiftsByDate[date.getDate()] ?? []}
          />
        )
      })}
      {Object.keys(shiftsByDate).length === 0 && (
        <div style={{ gridColumn: 'span 7' }}>
          <EmptyState
            title="No jobs"
            text="There are no scheduled jobs for this week."
          />
        </div>
      )}
    </Wrapper>
  )
}

const getShiftsByDate = (shifts: Shift[]) => {
  const byDate: Record<number, Shift[]> = {}

  shifts.forEach((shift) => {
    const date = startOfDay(parseISO(shift.startAt))

    if (typeof byDate[date.getDate()] === 'object') {
      byDate[date.getDate()].push(shift)
    } else {
      byDate[date.getDate()] = [shift]
    }
  })

  return byDate
}

export default ShiftGrid
