import { useCallback, useState } from 'react'
import { FieldContext, useField } from '@area2k/use-form'
import { useReactiveVar } from '@apollo/client'

import { useListUniformsQuery } from '@/graphql'

import LoadingState from '@/components/LoadingState'
import Button from '@/components/Button'
import FormElement from '@/components/FormElement'
import Stack from '@/components/Stack'
import TextArea from '@/components/TextArea'
import UniformItem from './UniformItem'

import SingleSelectableField from '@/form/SingleSelectableField'

import { currentAgencyVar } from '@/util/apollo/cache'

import { FormValues } from '..'
import { Uniform } from '../../../context'

type Props = {
  handleUniformChange: <T extends any>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => void
}

const UniformSection = ({
  handleUniformChange,
}: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data, loading } = useListUniformsQuery({
    variables: { agencyId: currentAgency!.id },
  })

  const renderItem = useCallback(
    (item: Uniform) => <UniformItem item={item} />,
    []
  )

  const { value, setValue } = useField<string>(
    'uniformInstructions',
    (fieldContext) => {
      handleUniformChange(fieldContext, 'uniformInstructions')
    }
  )

  return (
    <Stack vertical gap={0}>
      {!data ? (
        <Stack justify="center" align="center">
          <LoadingState overlayColor="white" text="Loading uniforms" />
        </Stack>
      ) : (
        <>
          <FormElement label="Choose the dress code*">
            <Stack wrap align="center" justify="center" gap={23.5}>
              <SingleSelectableField<Uniform>
                required
                disabled={loading}
                fieldId="uniform"
                options={
                  data ? data.agency.uniforms.map((item) => ({ item })) : []
                }
                itemToKey={(item) => item.id}
                renderItem={renderItem}
                callback={(fieldContext) => {
                  handleUniformChange(fieldContext, 'uniform')
                  setValue(fieldContext.value?.defaultInstructions!)
                }}
              />
            </Stack>
          </FormElement>
            <div style={{ width: '100%' }}>
              <FormElement
                htmlFor="uniformInstructions"
                label="Dress code instructions"
              >
                <TextArea
                  id="uniformInstructions"
                  name="uniformInstructions"
                  placeholder="More information makes a better employee selection..."
                  value={value}
                  onChange={(ev) => setValue(ev.currentTarget.value)}
                />
              </FormElement>
            </div>
        </>
      )}
    </Stack>
  )
}

export default UniformSection
