import { useCallback, useState, useMemo, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'
import FormElement from '@/components/FormElement'
import { Small } from '@/components/Typography'
import Autocomplete from '@/components/Autocomplete'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import TagList from '@/components/TagList'
import TextStack from '@/components/TextStack'

import { useListAgencySkillsQuery } from '@/graphql'
import { currentAgencyVar } from '@/util/apollo/cache'
import { useAppDispatch, useAppSelector } from '@/hooks/store'
import useDebouncedValue from '@/hooks/useDebouncedValue'
import { sortBy } from '@/util/array'
import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import Form from '@/form'
import {
  CompareOperatorEnum,
  GetWorkerQuery,
  WorkerFilterSetInput,
} from '@/types/graphql'

import {
  selectWorkerCompleteFilters,
  setWorkerCompleteTableFilters,
  TaxTypeEnumVoid,
} from '@/store/slices/filtersSlice'

import Icon from '@/elements/Icon'
import Link from '@/elements/Link'
import { mapUrl } from '../../Customer/AddressList'

type SkillItem = GetWorkerQuery['worker']['skills'][0]

type Props = {
  hideModal: () => void
  onChangeFilter: <K extends keyof WorkerFilterSetInput>(
    key: K,
    value: NonNullable<WorkerFilterSetInput[K]>
  ) => void
  onClearFilter: (key: keyof WorkerFilterSetInput) => void
  onClearAll: () => void
}

const taxTypeOptions = [
  { label: 'Both', value: TaxTypeEnumVoid.BOTH },
  { label: 'W2', value: TaxTypeEnumVoid.TAX_W2 },
  { label: '1099M', value: TaxTypeEnumVoid.TAX_1099 },
]

const WorkerFiltersModal = ({
  hideModal,
  filters,
  onChangeFilter,
  onClearAll,
  onClearFilter,
}) => {
  const initialAddressValues = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    name: '',
    state: null,
    zip: null,
    coords: { latitude: 0, longitude: 0 },
  }
  const { address, skillArray, taxType } = useAppSelector((state) =>
    selectWorkerCompleteFilters(state)
  )
  const [addressValues, setAddressValues] = useState(
    address ? { ...address } : { ...initialAddressValues }
  )

  const [taxOption, setTaxOption] = useState<TaxTypeEnumVoid>(taxType)
  const [skills, setSkills] = useState<SkillItem[]>(
    skillArray ? skillArray : []
  )
  const sortedWorkerSkills = useMemo(() => {
    return sortBy(skills, 'name')
  }, [skills])

  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const { data } = useListAgencySkillsQuery({
    variables: { agencyId: currentAgency!.id },
  })
  const dispatch = useAppDispatch()

  const handleRemove = (index: number) => {
    let trueIndex = skills.findIndex(
      (skill) => skill.id === sortedWorkerSkills[index].id
    )
    let skillsCopy = [...skills]
    skillsCopy.splice(trueIndex, 1)
    setSkills([...skillsCopy])
  }

  const handleSelect = useCallback(
    (item) => {
      setSkills([...skills, item.selectedItem])
    },
    [skills]
  )

  const items: SkillItem[] = useMemo(() => {
    if (data) {
      const availableSkills = data.agency.skillCategories
        .flatMap((category) => category.skills)
        .filter((skill) => {
          if (skills.some((workerSkill) => workerSkill.id === skill.id)) {
            return false
          }

          return skill.name.toLowerCase().includes(debouncedQuery.toLowerCase())
        })

      return sortBy(availableSkills, 'name')
    }

    return []
  }, [skills, data, debouncedQuery])

  const handleSubmit = useCallback(() => {
    try {
      let filterQueryObject = {}
      switch (taxOption) {
        case TaxTypeEnumVoid.BOTH:
          onClearFilter('taxType')
          break
        case TaxTypeEnumVoid.TAX_W2:
        case TaxTypeEnumVoid.TAX_1099:
          onChangeFilter('taxType', { value: taxOption })
          break
        default:
          break
      }
      if (
        addressValues.coords.latitude !== 0 &&
        addressValues.coords.longitude !== 0
      )
        onChangeFilter('coords', addressValues.coords)
      else onClearFilter('coords')

      if (skills.length > 0) {
        let skillsParsed = skills.map((skill) => {
          return { value: parseInt(skill.id), op: CompareOperatorEnum.EQ }
        })
        onChangeFilter('workerSkills', skillsParsed)
      } else {
        onClearFilter('workerSkills')
      }

      dispatch(
        setWorkerCompleteTableFilters({
          address: addressValues,
          taxType: taxOption,
          skillArray: skills,
        })
      )

      hideModal()
    } catch (error) {
      console.error(error)
    }
  }, [addressValues, taxOption, skills, filters])

  return (
    <Modal
      size="sm"
      title="Worker Filters"
      onRequestClose={hideModal}
      disableClickout
    >
      <Card.Section title="">
        <Form initialValues={{ taxType: taxType }}>
          <SingleOptionGroupField
            options={taxTypeOptions}
            fieldId="taxType"
            label="Tax options"
            callback={(fieldContext) => {
              setTaxOption(fieldContext.value)
            }}
          />
        </Form>
        <FormElement htmlFor="" label="Close to...">
          {addressValues.addressLine1 ? (
            <Stack justify="apart">
              <TextStack>
                <Link
                  as="a"
                  href={mapUrl(addressValues)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {addressValues.addressLine1}{' '}
                  <Small color="inherit">
                    <Icon icon={faExternalLinkAlt} />
                  </Small>
                </Link>
                <Small>
                  {addressValues.city}, {addressValues.state}{' '}
                  {addressValues.zip}
                </Small>
              </TextStack>
              <Button
                a11yLabel="Clear order status filter"
                appearance="plain"
                label="Clear"
                onClick={() => {
                  setAddressValues({ ...initialAddressValues })
                }}
              />
            </Stack>
          ) : (
            <AddressAutocomplete
              values={addressValues}
              setValues={setAddressValues}
            />
          )}
        </FormElement>
        <FormElement label="Request Skills">
          {sortedWorkerSkills.length > 0 && (
            <div style={{ marginBottom: 8 }}>
              <TagList
                tags={sortedWorkerSkills.map((skill) => skill.name)}
                onRemove={handleRemove}
              />
            </div>
          )}
          <Autocomplete
            id="skillFather"
            itemToKey={(item) => item.id}
            items={items}
            itemToString={(item) => (item ? item.name : '')}
            placeholder="Search for skills..."
            selectedItem={null}
            onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
            onSelectedItemChange={handleSelect}
            fixedSelectHeight="lg"
          />
        </FormElement>
      </Card.Section>
      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            isLoading={false}
            label="Save"
            type="button"
            onClick={() => {
              handleSubmit()
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default WorkerFiltersModal
