import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useField } from '@area2k/use-form'
import { addDays, differenceInMinutes, isAfter, parse } from 'date-fns'

import Alert from '@/components/Alert'
import FormElement from '@/components/FormElement'

import { isDateInvalid } from '@/util/date'

const NextDateAlert = () => {
  const { value: startTime } = useField<string>('approvedCheckinAt')
  const { value: endTime } = useField<string>('approvedCheckoutAt')

  const startDate = parse(startTime, 'HH:mm', new Date())
  const endDate = parse(endTime, 'HH:mm', new Date())

  if (isDateInvalid(startDate) || isDateInvalid(endDate)) {
    return null
  }

  if (isAfter(endDate, startDate)) {
    return null
  }

  const lengthOfShift = differenceInMinutes(addDays(endDate, 1), startDate)
  const hours = Math.floor(lengthOfShift / 60)
  const minutes = lengthOfShift % 60

  return (
    <FormElement>
      <Alert
        icon={faInfoCircle}
        status="neutral"
        title="Shift crosses midnight"
        description={`Total length of shift: ${hours} hours${
          minutes > 0 ? ` and ${minutes} minutes.` : '.'
        }`}
      />
    </FormElement>
  )
}

export default NextDateAlert
