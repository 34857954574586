import { faTimes } from '@fortawesome/free-solid-svg-icons'
import useMediaQuery from '@/hooks/useMediaQuery'

import styled from '@/styles'

import IconicButton from '@/components/IconicButton'
import Stack from '@/components/Stack'

import Progress, { Props as ProgressProps } from './Progress'
import TextStack from '@/components/TextStack'

const Wrapper = styled('nav', {
  display: 'grid',
  flexWrap: 'wrap',
  gridTemplateColumns: '1fr auto 1fr',
  gridTemplateRows: '64px',

  padding: '0 32px',

  position: 'fixed',
  right: 0,
  left: 0,
  top: 0,

  backgroundColor: 'white',
  boxShadow: '$2',

  zIndex: 1000,

  '@touchable': {
    gridTemplateColumns: 'auto',
    padding: '0 0 0.75rem 0',
    gridTemplateRows: '44px',
  },
})

type Props = ProgressProps & {
  onClose: () => void
}

const TopBar = ({ completedStep, currentStep, onClose }: Props) => {
  const isTouchableBreakpoint = useMediaQuery('(max-width: 900px)')

  const CloseButton = () => (
    <Stack justify="end">
      <IconicButton
        a11yLabel="Close job editor"
        appearance="clear"
        icon={faTimes}
        onClick={onClose}
      />
    </Stack>
  )

  const StepperProgress = () => (
    <Progress completedStep={completedStep} currentStep={currentStep} />
  )

  return (
    <Wrapper>
      <Stack>
        <TextStack css={{ marginLeft: '0.75rem' }}>Adding a new job</TextStack>
        {isTouchableBreakpoint && <CloseButton />}
      </Stack>

      {isTouchableBreakpoint ? (
        <StepperProgress />
      ) : (
        <>
          <StepperProgress />
          <CloseButton />
        </>
      )}
    </Wrapper>
  )
}

export default TopBar
