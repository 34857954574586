import { FieldCallback, useField } from '@area2k/use-form'
import { ComponentProps } from 'react'
import styled from '@/styles'

import FormElement from '@/components/FormElement'
import Option from '@/components/Option'

import Text from '@/elements/Text'

const Wrapper = styled('div', {
  display: 'flex',
  marginLeft: '35px',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
  },
})

type OptionProps = Omit<
  ComponentProps<typeof Option>,
  'checked' | 'id' | 'name' | 'value' | 'onChange'
>
type OptionGroupOption = { disabled?: boolean; label: string; value: string }

type Props = OptionProps & {
  fieldId: string
  label?: string
  horizontal?: boolean
  options: OptionGroupOption[]
  callback?: FieldCallback<string>
}

const SingleOptionGroupField = ({
  appearance = 'bullseye',
  disabled,
  fieldId,
  label,
  options,
  horizontal = false,
  callback = () => {},
  ...props
}: Props) => {
  const { value, setValue } = useField<string>(fieldId,callback)

  return (
    <FormElement>
      {label && (
        <div style={{ margin: '0 6px 12px' }}>
          <Text color={disabled ? 'lightest' : 'default'}>{label}</Text>
        </div>
      )}
      <Wrapper direction={horizontal ? 'row' : 'column'}>
        {options.map((option, index) => (
          <div
            key={`${fieldId}${index}`}
            style={{ marginBottom: 12, marginRight: 20 }}
          >
            <Option
              {...props}
              appearance={appearance}
              checked={value === option.value}
              disabled={disabled || option.disabled}
              id={`${fieldId}${index}`}
              label={option.label}
              name={fieldId}
              type="radio"
              value={option.value}
              onChange={(ev) => setValue(ev.currentTarget.value)}
            />
          </div>
        ))}
      </Wrapper>
    </FormElement>
  )
}

export default SingleOptionGroupField
