import { useEffect, useState } from 'react'
import { FieldCallback } from '@area2k/use-form'

import { useReactiveVar } from '@apollo/client'

import { useAutocompleteCustomersLazyQuery } from '@/graphql'
import { AutocompleteCustomersQuery } from '@/types/graphql'

import AutocompleteField from '@/form/AutocompleteField'

import useDebouncedValue from '@/hooks/useDebouncedValue'

import { currentAgencyVar } from '@/util/apollo/cache'

export type CustomerItem = AutocompleteCustomersQuery['agency']['customers']['items'][0]

export type Props = {
  fieldId: string
  callback?: FieldCallback<CustomerItem | null>
}

const CustomerAutocompleteField = ({ fieldId, callback }: Props) => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebouncedValue(query, 300)

  const currentAgency = useReactiveVar(currentAgencyVar)
  const [fetch, { data }] = useAutocompleteCustomersLazyQuery()

  useEffect(() => {
    fetch({ variables: { agencyId: currentAgency!.id, query: debouncedQuery } })
  }, [debouncedQuery])

  return (
    <AutocompleteField<CustomerItem>
      required
      fieldId={fieldId}
      items={data ? data.agency.customers.items : []}
      itemToKey={(item) => item.id}
      itemToString={(item) => (item ? item.name : '')}
      label="Customer*"
      placeholder="Search for customers..."
      onInputValueChange={({ inputValue }) => setQuery(inputValue || '')}
      fixedSelectHeight="lg"
      callback={callback}
    />
  )
}

export default CustomerAutocompleteField
