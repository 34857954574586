import { useCallback } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import { GetJobQuery } from '@/types/graphql'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'

import UniformSelectField, { UniformItem } from './UniformSelectField'

import Form from '@/form'
import TextAreaField from '@/form/TextAreaField'

import { getJobValues } from '../../util'
import { handleMutationFormError } from '@/util/error'

import { useUpdateJobMutation } from '@/graphql'

export type Props = {
  job: GetJobQuery['job']
  hideModal: () => void
}

type FormValues = {
  uniform: UniformItem
  uniformInstructions: string
}

const UpdateUniformModal = ({ job, hideModal }: Props) => {
  const initialValues: FormValues = {
    uniform: job.uniform,
    uniformInstructions: job.uniformInstructions ?? '',
  }

  const [updateJob, { loading: isLoading }] = useUpdateJobMutation()

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      console.log({
        ...getJobValues(job),
        uniformId: values.uniform.id,
        uniformInstructions: values.uniformInstructions,
      })
      try {
        await updateJob({
          variables: {
            ...getJobValues(job),
            uniformId: values.uniform.id,
            uniformInstructions: values.uniformInstructions,
          },
        })

        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          // errorMap: {
          //   all: () => ({
          //     title: 'Unknown service error',
          //     message: ''
          //   })
          // }
        })
      }
    },
    [job]
  )

  return (
    <Modal size="sm" title="Update required uniform" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <UniformSelectField fieldId="uniform" label="Uniform" />
          <TextAreaField
            fieldId="uniformInstructions"
            label="Uniform instructions"
            placeholder="Provide instructions and details to the workers..."
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              isLoading={false}
              label="Update"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default UpdateUniformModal
