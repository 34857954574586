import { FieldContext } from '@area2k/use-form'
import { Maybe } from '@/types'
import WorkerAutocompleteField, {
  WorkerItem,
} from '@/routes/Agency/Job/WorkerAutocompleteField'
import styled from '@/styles'

const Wrapper = styled('div', {
  width: '100%',
})

type Props = {
  fieldId: string
  label: string
  callback?: (fieldContext: FieldContext<Maybe<WorkerItem>>) => void
}

const WorkerFinder = ({ fieldId, label, callback }: Props) => {
  return (
    <Wrapper>
      <WorkerAutocompleteField
        fieldId={fieldId}
        label={label}
        callback={callback}
        openMenuOnInputFocus={false}
      />
    </Wrapper>
  )
}

export default WorkerFinder
