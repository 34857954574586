import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  hideModal: () => void
  onConfirm: () => void
}

const ExitJobEditorModal = ({ hideModal, onConfirm }: Props) => {
  return (
    <Modal size="xs" title="Exit job editor" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Stack vertical>
            <Body>Are you sure you want to exit?</Body>
            <Body color="danger" weight="bold">
              Your job creation changes will not be saved
            </Body>
          </Stack>
          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="primary"
              label="Keep"
              onClick={hideModal}
            />
            <Button
              a11yLabel="Exit job editor"
              appearance="outline"
              label="Exit"
              onClick={onConfirm}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default ExitJobEditorModal
