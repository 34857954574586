import { useCallback, useState } from 'react'
import { SubmitHelpers } from '@area2k/use-form'

import { AddressItemFragmentDoc, useCreateAddressMutation } from '@/graphql'
import { Address, GetCustomerQuery, PointInput } from '@/types/graphql'

import Form from '@/form'
import AddressAutocomplete from '@/components/AddressAutocomplete'
import FormElement from '@/components/FormElement'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'

import { handleMutationFormError } from '@/util/error'

export type Props = {
  customer: GetCustomerQuery['customer']
  hideModal: () => void
}

type FormValues = {
  addressLine1: Address['addressLine1']
  addressLine2: Address['addressLine2']
  city: Address['city']
  name: Address['name']
  state: Address['state']
  zip: Address['zip']
  coords: PointInput
}

const CreateAddressModal = ({ customer, hideModal }: Props) => {
  const initialValues: FormValues = {
    addressLine1: null,
    addressLine2: null,
    city: null,
    name: '',
    state: null,
    zip: null,
    coords: { latitude: 0, longitude: 0 },
  }
  const [addressPayload, setAddressPayload] = useState<FormValues>(
    initialValues
  )

  const [createAddress, { loading }] = useCreateAddressMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          addresses(existingRefs = [], { readField }) {
            if (!data) return existingRefs
            const { address } = data.addressCreate

            if (
              existingRefs.some((ref) => readField('id', ref) === address.id)
            ) {
              return existingRefs
            }

            const newAddressRef = cache.writeFragment({
              fragment: AddressItemFragmentDoc,
              data: address,
            })

            return [...existingRefs, newAddressRef]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await createAddress({
          variables: {
            customerId: customer.id,
            addressLine1: addressPayload.addressLine1!,
            addressLine2: addressPayload.addressLine2!,
            city: addressPayload.city!,
            state: addressPayload.state!,
            zip: addressPayload.zip!,
            name: addressPayload.name!,
            coords: addressPayload.coords,
          },
        })
        setAddressPayload(initialValues)
        hideModal()
      } catch (error) {
        handleMutationFormError(error, {
          setFormError,
        })
      }
    },
    [addressPayload]
  )

  const disabledSubmit =
    !addressPayload.addressLine1 &&
    !addressPayload.addressLine2 &&
    !addressPayload.city &&
    !addressPayload.name &&
    !addressPayload.state &&
    !addressPayload.zip &&
    !addressPayload.coords.latitude &&
    !addressPayload.coords.longitude

  return (
    <Modal size="sm" title="New address" onRequestClose={hideModal}>
      <Card.Section>
        <Form initialValues={addressPayload} onSubmit={handleSubmit}>
          <FormElement>
            <AddressAutocomplete
              values={addressPayload}
              setValues={setAddressPayload}
            />
          </FormElement>

          <Stack justify="end">
            <Button
              disabled={disabledSubmit}
              a11yLabel="Submit form"
              isLoading={loading}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default CreateAddressModal
