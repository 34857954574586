import { useModal } from 'react-modal-hook'

import { GetCustomerQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

import PaymentSection from './PaymentSection'
import RenameCustomerModal from './RenameCustomerModal'

import useChangeLogoModal from '../useChangeLogoModal'
import ProgressCustomerSection from './ProgressCustomerSection'

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { IconProgressCustomerProfile } from './styles'

type Props = {
  customer: GetCustomerQuery['customer']
  currentAdminIsCustomerAdmin: boolean
}

const SettingsCard = ({ customer, currentAdminIsCustomerAdmin }: Props) => {
  const showLogoModal = useChangeLogoModal(customer)

  const [showRenameModal, hideRenameModal] = useModal(
    () => (
      <RenameCustomerModal customer={customer} hideModal={hideRenameModal} />
    ),
    [customer]
  )

  const { admins, accounts, addresses } = customer
  const rateQuotes = addresses.flatMap((address) => address.rateQuotes)
  const showProgressCustomerSection =
    admins.items.length === 0 ||
    accounts.length === 0 ||
    addresses.length === 0 ||
    rateQuotes.length === 0

  return (
    <Card
      actions={
        !currentAdminIsCustomerAdmin
          ? [
              {
                a11yLabel: 'Rename this customer',
                label: 'Rename',
                onAction: showRenameModal,
              },
            ]
          : undefined
      }
      title={currentAdminIsCustomerAdmin ? 'Company' : 'Settings'}
    >
      <Card.Section>
        <Stack gap={20}>
          <div style={{ cursor: 'pointer' }} onClick={showLogoModal}>
            <Avatar
              a11yLabel={`Image of ${customer.name}`}
              firstName={customer.name}
              size="md"
              src={customer.logoUrl}
            />
          </div>
          <Body>{customer.name}</Body>
        </Stack>
      </Card.Section>

      <PaymentSection customer={customer} />

      {showProgressCustomerSection ? (
        <ProgressCustomerSection customer={customer} />
      ) : (
        <Card.Section>
          <Stack>
            <IconProgressCustomerProfile icon={faCheckCircle} completed />
            <Body color="theme">Your profile is complete!</Body>
          </Stack>
        </Card.Section>
      )}
    </Card>
  )
}

export default SettingsCard
