import Card from '@/components/Card'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Skeleton from '@/components/Skeleton'
import Stack from '@/components/Stack'

type Props = {
  currentAdminIsCustomerAdmin: boolean
}

const PageSkeleton = ({ currentAdminIsCustomerAdmin }: Props) => {
  return (
    <Skeleton.Page media primaryAction secondaryActions={1}>
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            <Card sectioned title="Information">
              <Skeleton.Body />
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <Card title={currentAdminIsCustomerAdmin ? 'Company' : 'Customer'}>
              <Card.Section>
                <Skeleton.Body />
              </Card.Section>
            </Card>
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Skeleton.Page>
  )
}

export default PageSkeleton
