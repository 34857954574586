import { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { addYears, format } from 'date-fns'
import {
  faSortNumericDown,
  faSortNumericUpAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useListOrdersQuery } from '@/graphql'
import {
  CompareOperatorEnum,
  OrderFilterSetInput,
  OrderSortKeyEnum,
} from '@/types/graphql'

import { currentAdminVar, currentAgencyVar } from '@/util/apollo/cache'
import useFilters from '@/hooks/useFilters'
import { Role } from '@/routes/PrivateRoute'
import ROUTES from '@/routes/routes'

import Link from '@/elements/Link'
import Table from '@/components/Table'
import { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table'

import Avatar from '@/components/Avatar'
import Page from '@/components/Page'
import Card from '@/components/Card'
import LoadingState from '@/components/LoadingState'
import Paginator from '@/components/Paginator/Paginator'
import QueryEmptyState from '@/components/QueryEmptyState'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'

import OrderFilters from './OrderFilters'

import Icon from '@/elements/Icon'

export const oneHundredYearsAgo = addYears(new Date(), -100).toISOString()

type SortByFilter = {
  sort: OrderSortKeyEnum
  reverse: boolean
}

const initialFilters: OrderFilterSetInput = {
  startAt: {
    op: CompareOperatorEnum.LT,
    value: new Date().toISOString(),
  },
  endAt: {
    op: CompareOperatorEnum.GT,
    value: new Date().toISOString(),
  },
}

const initialSortBy: SortByFilter = {
  sort: OrderSortKeyEnum.START_AT,
  reverse: false,
}

const jobFilled = (jobs: any[]) => {
  let countJobfilled = 0
  jobs.map((item) => {
    if (item.filled == true) {
      countJobfilled++
    }
  })
  return countJobfilled
}

const jobStatus = (jobs: any[]) => {
  const formatJobsStatus = jobs.flatMap((job: any) =>
    job.timesheets.length < 1
      ? 'N/A'
      : job.timesheets.map((timesheet) => timesheet.status)
  )

  const deleteDuplicated = [...new Set(formatJobsStatus)]

  if (deleteDuplicated.length == 1) {
    return {
      ['N/A']: 'N/A',
      ['PENDING']: 'Needs approval',
      ['APPROVED']: 'All Approved',
      ['IN_PROGRESS']: 'On working',
    }[deleteDuplicated[0]]
  }
  if (deleteDuplicated.includes('PENDING')) {
    return 'Needs approval'
  }
  if (deleteDuplicated.includes('IN_PROGRESS')) {
    return 'On working'
  }
  if (deleteDuplicated.includes('APPROVED')) {
    return 'All Approved'
  }
  return 'N/A'
}

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState<SortByFilter>(initialSortBy)

  const {
    debouncedQuery,
    filters,
    query: searchQuery,
    clearAll,
    clearFilter,
    setFilter,
    setQuery,
  } = useFilters<OrderFilterSetInput>(initialFilters)

  const finalFilters = useMemo<OrderFilterSetInput>(() => {
    const value = { ...filters }
    setCurrentPage(1)

    if (debouncedQuery !== '') {
      value.query = { value: debouncedQuery }
    }

    return value
  }, [debouncedQuery, filters])

  const sorting = (value: SortByFilter['sort']) => {
    setSortBy((prevValue) => {
      return {
        sort: value,
        reverse: prevValue.sort == value ? !prevValue.reverse : true,
      }
    })
  }

  const currentAgency = useReactiveVar(currentAgencyVar)
  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN

  const query = useListOrdersQuery({
    nextFetchPolicy: 'cache-and-network',
    variables: {
      agencyId: currentAgency!.id,
      filters: finalFilters,
      page: currentPage,
      perPage: itemsPerPage,
      sortKey: sortBy.sort,
      reverse: sortBy.reverse,
    },
  })

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)

  const handleNumberItemsChange = (event) => {
    setCurrentPage(1)
    setItemsPerPage(parseInt(event.target.value))
  }

  const headerFields = useMemo(
    () => ['ID', 'Customer', 'Timeframe', 'Approval status', 'Filled jobs'],
    []
  )

  const items =
    !query.loading && query.data ? query.data.agency.orders.items : []
  const pageInfo =
    !query.loading && query.data ? query.data.agency.orders.pageInfo : {}

  useEffect(() => {
    if (
      currentAdmin!.__typename === 'CustomerAdmin' &&
      currentAdmin!.role === Role.CUSTOMER_ADMIN
    ) {
      setFilter('customer', { value: currentAdmin!.customer!.id })
    }
  }, [currentAdmin])

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Place a new order',
        label: 'Place new order',
        to: ROUTES.ordersCreate,
      }}
      size="md"
      title="Orders"
    >
      <Card>
        <Card.Section>
          <OrderFilters
            filters={filters}
            query={searchQuery}
            onChangeFilter={setFilter}
            onClearAll={clearAll}
            onClearFilter={clearFilter}
            onQueryChange={setQuery}
          />
        </Card.Section>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading
                touchable="true"
                onClick={() => sorting(OrderSortKeyEnum.ID)}
              >
                ID
                <Icon
                  fixedWidth
                  icon={
                    sortBy.reverse && sortBy.sort == OrderSortKeyEnum.ID
                      ? faSortNumericUpAlt
                      : faSortNumericDown
                  }
                />
              </TableHeading>
              <TableHeading>Customer</TableHeading>
              <TableHeading
                touchable="true"
                onClick={() => sorting(OrderSortKeyEnum.START_AT)}
              >
                Timeframe
                <Icon
                  fixedWidth
                  icon={
                    sortBy.reverse && sortBy.sort == OrderSortKeyEnum.START_AT
                      ? faSortNumericUpAlt
                      : faSortNumericDown
                  }
                />
              </TableHeading>
              <TableHeading> Approval status</TableHeading>
              <TableHeading>Filled jobs</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link to={item.id}>#{item.id}</Link>
                </TableCell>
                <TableCell>
                  <Stack gap={20}>
                    <div>
                      <Avatar
                        firstName={item.account.customer.name}
                        size="sm"
                        src={item.account.customer.logoUrl}
                      />
                    </div>
                    <TextStack spacing="tight">
                      <Link
                        to={
                          currentAdminIsCustomerAdmin
                            ? `../${ROUTES.myCompany}`
                            : `../${ROUTES.customers}/${item.account.customer.id}`
                        }
                      >
                        <Body color="inherit" weight="medium">
                          {item.account.customer.name}
                        </Body>
                      </Link>

                      <Link
                        appearance="subtle"
                        to={
                          currentAdminIsCustomerAdmin
                            ? `../${ROUTES.myCompany}/accounts/${item.account.id}`
                            : `../${ROUTES.customers}/${item.account.customer.id}/accounts/${item.account.id}`
                        }
                      >
                        <Small>{item.account.name}</Small>
                      </Link>
                    </TextStack>
                  </Stack>
                </TableCell>

                <TableCell>
                  {format(new Date(item.startAt), 'PP')} &mdash;{' '}
                  {format(new Date(item.endAt), 'PP')}
                </TableCell>
                <TableCell>
                  <Body
                    color={
                      {
                        ['N/A']: 'lighter',
                        ['Needs approval']: 'danger',
                        ['All Approved']: 'theme',
                        ['On working']: 'success',
                      }[jobStatus(item.jobs)]
                    }
                    weight="medium"
                  >
                    {jobStatus(item.jobs)}
                  </Body>
                </TableCell>
                <TableCell>
                  {jobFilled(item.jobs)}/{item.jobsCount}{' '}
                  {item.jobsCount === 1 ? 'job' : 'jobs'}
                </TableCell>
              </TableRow>
            ))}
            {query.loading && (
              <TableRow>
                <TableCell colSpan={headerFields.length} align="center">
                  <LoadingState overlayColor="white" />
                </TableCell>
              </TableRow>
            )}
          </tbody>
        </Table>
        {!query.loading && items.length === 0 && (
          <QueryEmptyState
            query={query}
            title="No orders"
            text="No orders were found, create one above."
          />
        )}
      </Card>
      {items.length > 0 && (
        <Paginator
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          handlePageChange={handlePageChange}
          handleNumberItemsChange={handleNumberItemsChange}
          itemsLength={pageInfo.totalItems}
        />
      )}
    </Page>
  )
}

export default Orders
