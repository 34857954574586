import styled from '@/styles'
import { Maybe } from '@/types'

const Container = styled('div', {
  width: '100%',
  backgroundColor: '#e0e0de',
  borderRadius: 50,
  margin: 7,

  variants: {
    size: {
      xs: {
        height: 8,
      },
      sm: {
        height: 10,
      },
      md: {
        height: 12,
      },
    },
  },

  defaultVariants: {
      size: 'sm'
  }
})

const FillerStyles = styled('div', {
  height: '100%',
  borderRadius: 'inherit',
  textAlign: 'right',
  transition: 'width 1s ease-in-out',

  variants: {
    inProcess: {
      0: {
        width: '0%',
        backgroundColor: 'inherit',
      },
      1: {
        width: '25%',
        backgroundColor: '$themeLight',
      },
      2: {
        width: '50%',
        backgroundColor:'$themeLight'
      },
      3: {
        width: '75%',
        backgroundColor: '$themeLight',
      },
      4: {
        width: '100%',
        backgroundColor: '$themeLight',
      },
    },
  },
})

type Props = {
    step
    size?
}

const ProgressBar = ({ size, step }: Props) => {
  return (
    <Container size={size}>
      <FillerStyles inProcess={step} />
    </Container>
  )
}

export default ProgressBar
