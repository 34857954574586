import { Address, Maybe, Skill } from '@/types/graphql'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export enum TaxTypeEnumVoid {
  BOTH = 'BOTH',
  TAX_1099 = 'TAX_1099',
  TAX_W2 = 'TAX_W2',
}
interface FiltersState {
  workerCompleteTableFilters: {
    address: Maybe<Address>
    skillArray: Array<Skill>
    taxType: TaxTypeEnumVoid
  }
}

const initialState: FiltersState = {
  workerCompleteTableFilters: {
    address: null,
    skillArray: [],
    taxType: TaxTypeEnumVoid.BOTH,
  },
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setWorkerCompleteTableFilters: (state, action: PayloadAction<object>) => {
      state.workerCompleteTableFilters = action.payload
    },
  },
})

export const selectWorkerCompleteFilters = (state: RootState) =>
  state.filters.workerCompleteTableFilters

export const { setWorkerCompleteTableFilters } = filtersSlice.actions

export default filtersSlice.reducer
