import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { format, min, max } from 'date-fns'

import { GetOrderQuery, TimesheetStatusEnum } from '@/types/graphql'
import { useCancelOrderMutation, useGetOrderQuery } from '@/graphql'

import useModal from '@area2k/use-modal'
import Link from '@/elements/Link'
import Table from '@/components/Table'
import { TableRow, TableCell } from '@/elements/Table'

import Card from '@/components/Card'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import QueryEmptyState from '@/components/QueryEmptyState'

import CustomerCard from './CustomerCard'
import SummaryCard from './SummaryCard'
import PageSkeleton from './PageSkeleton'
import { formatISO } from '@/util/date'
import Avatar from '@/components/Avatar'
import TableHeader from '@/components/TableHeader'
import JobBadge from '../Job/JobBadge'
import { getJobStatusByJob, JobStatus } from '../Job/util'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import DropDownBadge from '@/components/DropDownBadge'
import TextStack from '@/components/TextStack'
import { Body, Subheading } from '@/components/Typography'
import CancelOrderModal from './CancelOrderModal'

type JobItem = GetOrderQuery['order']['jobs'][0]
type Props = {
  jobs: GetOrderQuery['order']['jobs']
}

const getOrderDetailSubtitle = (order: GetOrderQuery['order']) => {
  const onlyFirstShiftsStartAtByJob = order.jobs.flatMap(
    (job) => new Date(job.firstShiftStartAt)
  )
  const onlyLastShiftsEndAtByJob = order.jobs.flatMap(
    (job) => new Date(job.lastShiftEndAt)
  )

  const orderFromDate = min(onlyFirstShiftsStartAtByJob).toISOString()
  const orderToDate = max(onlyLastShiftsEndAtByJob).toISOString()

  const jobsCount = order.jobsCount
  const formatOrderJobsCount = jobsCount !== 1 ? 'jobs' : 'job'
  const formatOrderStartAt = formatISO(orderFromDate, 'PP')
  const formatOrderEndAt = formatISO(orderToDate, 'PP')
  const timeframe = `${formatOrderStartAt} — ${formatOrderEndAt}`
  const subtitle = `${jobsCount} ${formatOrderJobsCount} from ${timeframe}`

  return subtitle
}

const countJobsShiftPending = (timeShiftJob: JobItem['timesheets']) => {
  const penddingShift = timeShiftJob.filter(
    (item) => item.status == TimesheetStatusEnum.PENDING
  )
  return penddingShift.length
}

const TableBody = ({ jobs }: Props) => {
  return (
    <tbody>
      {jobs.map((job: JobItem) => {
        const [dropDown, setDropDown] = useState<boolean>(false)
        const jobStatus = getJobStatusByJob(job)
        const jobPublish = job.published
        return (
          <>
            <TableRow key={job.id}>
              <TableCell>
                <Stack gap={12}>
                  <Avatar size="xs" src={job.skill.imageUrl} />
                  <Link to={`jobs/${job.id}`}>{job.skill.name}</Link>
                </Stack>
              </TableCell>
              <TableCell>
                {format(new Date(job.firstShiftStartAt), 'PP')} -{' '}
                {format(new Date(job.lastShiftEndAt), 'PP')}
              </TableCell>
              <TableCell>({countJobsShiftPending(job.timesheets)})</TableCell>
              <TableCell>
                {`${job.hiredWorkersCount < 0 ? 0 : job.hiredWorkersCount} / ${
                  job.quantity
                }`}{' '}
                hired
              </TableCell>
              <TableCell>
                {jobStatus == JobStatus.CANCELLED && job.reason != null ? (
                  <DropDownBadge
                    a11yLabel="actions"
                    icon={faChevronDown}
                    size="sm"
                    label={'Cancelled'}
                    status="danger"
                    onClick={() => setDropDown(!dropDown)}
                  />
                ) : (
                  <JobBadge
                    jobStatus={
                      jobPublish
                        ? jobStatus
                        : jobStatus === JobStatus.COMPLETED
                        ? jobStatus
                        : JobStatus.UNPUBLISHED
                    }
                  />
                )}
              </TableCell>
            </TableRow>
            {dropDown && jobStatus == JobStatus.CANCELLED && (
              <TableRow>
                <TableCell colSpan={5}>
                  <Stack align="center" gap={40}>
                    <Stack align="start" vertical inline>
                      <Subheading>Cancelled by</Subheading>
                      <Stack gap={24}>
                        <div>
                          <Avatar size="md" src={job.contact.avatarUrl} />
                        </div>
                        <TextStack>
                          <Link
                            to={`../../customers/${job.contact.customer.id}/admin/${job.contact.id}`}
                          >
                            <Body weight="medium">
                              {`${job.contact.user.firstName} ${job.contact.user.lastName}`}
                            </Body>
                          </Link>

                          <Body>
                            {format(new Date(job.cancelledAt), 'MM/dd/yyyy')}
                          </Body>
                        </TextStack>
                      </Stack>
                    </Stack>
                    <Stack align="start" vertical>
                      <Subheading>Notes</Subheading>
                      <TextStack>
                        <Body>{job.reason}</Body>
                      </TextStack>
                    </Stack>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
          </>
        )
      })}
    </tbody>
  )
}

const Order = () => {
  const { orderId } = useParams()

  const query = useGetOrderQuery({
    nextFetchPolicy: 'cache-and-network',
    variables: { orderId },
  })

  const [
    cancelOrder,
    { loading: isCancelOrderLoading, data: cancelOrderData },
  ] = useCancelOrderMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(order),
        fields: {
          cancelledAt() {},
        },
      })
    },
  })

  const onCancelOrder = async () => {
    try {
      await cancelOrder({ variables: { orderId } })
    } catch (err) {
      console.log(err)
    }
  }

  const [showModal, hideModal] = useModal(
    () => (
      <CancelOrderModal
        isLoading={isCancelOrderLoading}
        data={cancelOrderData}
        hideModal={hideModal}
        onConfirm={onCancelOrder}
      />
    ),
    [isCancelOrderLoading, cancelOrderData]
  )

  const headerFields = useMemo(
    () => ['Skill', 'Timeframe', 'To Approved', 'Workers', 'Status'],
    []
  )

  if (!query.data) {
    return <PageSkeleton />
  }

  const order = query.data.order
  const subtitle = getOrderDetailSubtitle(order)

  return (
    <Page
      title={`Order #${order.id}`}
      subtitle={subtitle}
      primaryAction={
        order.cancelledAt === null
          ? {
              a11yLabel: 'Cancel this order',
              label: 'Cancel order',
              dangerous: true,
              onAction: () => showModal(),
            }
          : undefined
      }
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Card title="Jobs">
            {order.jobs.length ? (
              <Table>
                <TableHeader fields={headerFields} />
                <TableBody jobs={order.jobs} />
              </Table>
            ) : (
              <QueryEmptyState
                query={query}
                title="No jobs available"
                text="If there were any, they would be here."
              />
            )}
          </Card>
        </PrimarySecondaryLayout.Primary>
        <PrimarySecondaryLayout.Secondary>
          <Stack vertical gap={24}>
            <CustomerCard order={order} />
            <SummaryCard order={order} />
          </Stack>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Order
