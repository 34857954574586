import { GetJobQuery } from '@/types/graphql'
import { useReactiveVar } from '@apollo/client'

import { Role } from '@/routes/PrivateRoute'
import { currentAdminVar } from '@/util/apollo/cache'

import Card from '@/components/Card'

import AddressSection from './AddressSection'
import ContactSection from './ContactSection'
import OrderSection from './OrderSection'
import UniformSection from './UniformSection'

type Props = {
  job: GetJobQuery['job']
}

const DetailsCard = ({ job }: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar)
  const currentAdminIsCustomerAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN

  return (
    <Card title="Job details">
      <OrderSection
        job={job}
        currentAdminIsCustomerAdmin={currentAdminIsCustomerAdmin}
      />
      <AddressSection job={job} />
      <ContactSection job={job} />
      <UniformSection job={job} />
    </Card>
  )
}

export default DetailsCard
