import styled from '@/styles'

import Shimmer from '@/elements/Shimmer'

const Action = styled(Shimmer, {
  height: 36,
  width: 128
})

Action.displayName = 'stitches(Skeleton.Action)'

export default Action
