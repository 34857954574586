import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react'

import { CompareOperatorEnum, OrderFilterSetInput } from '@/types/graphql'

import { FilterProps } from '@/filters'

import Button from '@/components/Button'
import Option from '@/components/Option'
import Stack from '@/components/Stack'
import { oneHundredYearsAgo } from '../index'
import { OrderStatus } from './index'

type Props = Omit<FilterProps<OrderFilterSetInput>, 'filterKey'> & {
  idPrefix: string
  orderStatusValue: OrderStatus
  setOrderStatusValue: Dispatch<SetStateAction<OrderStatus>>
}

const dateISO = new Date().toISOString()

const OrderStatusFilter = ({
  idPrefix,
  onChangeFilter,
  onClearFilter,
  orderStatusValue,
  setOrderStatusValue,
}: Props) => {
  const ORDER_LABELS = {
    [OrderStatus.ALL]: 'All',
    [OrderStatus.IN_PROGRESS]: 'In progress',
    [OrderStatus.PAST]: 'Past',
    [OrderStatus.UPCOMING]: 'Upcoming',
  }

  const options = [
    OrderStatus.ALL,
    OrderStatus.UPCOMING,
    OrderStatus.IN_PROGRESS,
    OrderStatus.PAST,
  ]

  const payloadStartAtAll = {
    op: CompareOperatorEnum.GT,
    value: oneHundredYearsAgo,
  }

  const payloadWithOpGreaterThan = {
    op: CompareOperatorEnum.GT,
    value: dateISO,
  }

  const payloadWithOpLessThan = {
    op: CompareOperatorEnum.LT,
    value: dateISO,
  }

  const onAllOptionSelected = () => {
    onClearFilter('endAt')
    onChangeFilter('startAt', payloadStartAtAll)
    return OrderStatus.ALL
  }

  const onUpcomingOptionSelected = () => {
    onClearFilter('endAt')
    onChangeFilter('startAt', payloadWithOpGreaterThan)
    return OrderStatus.UPCOMING
  }

  const onInProgressOptionSelected = () => {
    onChangeFilter('startAt', payloadWithOpLessThan)
    onChangeFilter('endAt', payloadWithOpGreaterThan)
    return OrderStatus.IN_PROGRESS
  }

  const onPastOptionSelected = () => {
    onClearFilter('startAt')
    onChangeFilter('endAt', payloadWithOpLessThan)
    return OrderStatus.PAST
  }

  const handleChangeOption = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const status = {
        [OrderStatus.ALL]: onAllOptionSelected,
        [OrderStatus.UPCOMING]: onUpcomingOptionSelected,
        [OrderStatus.IN_PROGRESS]: onInProgressOptionSelected,
        [OrderStatus.PAST]: onPastOptionSelected,
      }[ev.currentTarget.value]

      return setOrderStatusValue(status || OrderStatus.ALL)
    },
    []
  )

  const clearFilter = useCallback(
    () => setOrderStatusValue(onAllOptionSelected),
    []
  )

  return (
    <Stack vertical gap={16}>
      {options.map((option) => (
        <Option
          key={option}
          appearance="bullseye"
          checked={orderStatusValue === option}
          id={`${idPrefix}_orderStatus-${option}`}
          label={ORDER_LABELS[option]}
          name={`${idPrefix}_orderStatus`}
          type="radio"
          value={option}
          onChange={handleChangeOption}
        />
      ))}
      {orderStatusValue !== OrderStatus.ALL && (
        <Button
          a11yLabel="Clear order status filter"
          appearance="plain"
          label="Clear"
          onClick={clearFilter}
        />
      )}
    </Stack>
  )
}

export default OrderStatusFilter
