import { useCallback, useEffect, useState } from 'react'
import { isEqual } from 'lodash'

import {
  useCreateInterviewMutation,
  useGetInterviewQuestionsQuery,
  useGetWorkerInterviewQuery,
  useUpdateInterviewMutation,
} from '@/graphql'
import { InterviewQuestion, WorkerInterviewStatusEnum } from '@/types/graphql'

import Form from '@/form'
import FormColumns from '@/form/FormColumns'

import { CandidateItem } from '../../helpers'
import Badge from '@/components/Badge'
import Stack from '@/components/Stack'
import FormElement from '@/components/FormElement'
import TextInput from '@/components/TextInput'
import Input from '@/elements/Input'

const interviewStatusLabels = {
  [WorkerInterviewStatusEnum.PASSED]: 'Passed',
  [WorkerInterviewStatusEnum.NOT_PASSED]: 'Not Passed',
}

type Props = {
  applicant: CandidateItem
  triggerSubmit: boolean
  setTrigger: (value: boolean) => void
  hideModal: () => void
  setError: (error: any) => void
}

type QuestionParsedValues = Omit<InterviewQuestion, 'updatedAt' | 'createdAt'>

type QuestionComponentType = {
  question: QuestionParsedValues
  onChange: (element) => void
  values: {}
}

const QuestionComponent = ({
  question,
  onChange,
  values,
}: QuestionComponentType) => (
  <FormElement htmlFor={`${question.id}`} label={`${question.question}`}>
    <TextInput
      id={`${question.id}`}
      name={`${question.id}`}
      value={values[question.id] ? values[question.id] : ''}
      onChange={(event) => {
        onChange({ ...values, [question.id]: event.currentTarget.value })
      }}
      placeholder={`${question.placeholder}`}
    />
  </FormElement>
)

const InterviewForm = ({
  applicant,
  triggerSubmit,
  setTrigger,
  hideModal,
  setError,
}: Props) => {
  const [formValues, setFormValues] = useState({})
  const [copyFormValues, setCopyFormValues] = useState({})
  const [hasPreviousAnswers, setHasPreviousAnswers] = useState<boolean>(false)
  const [date, setDate] = useState<string>('')

  const interviewQuestionsQuery = useGetInterviewQuestionsQuery()
  const workerInterviewQuery = useGetWorkerInterviewQuery({
    variables: { workerId: applicant.id },
  })
  const [createInterview] = useCreateInterviewMutation()
  const [updateInterview] = useUpdateInterviewMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `WorkerInterview:${data?.workerInterviewUpdate.workerInterview.id}`,
        fields: {
          workerInterviewAnswers() {},
        },
      })
    },
  })

  const interviewQuestions = interviewQuestionsQuery.data
    ? interviewQuestionsQuery.data.interviewQuestions
    : []
  const workerInterview = workerInterviewQuery.data

  const handleChange = (event) => {
    setDate(event.target.value)
  }

  const handleSubmit = useCallback(async () => {
    const answers = Object.keys(formValues).map((key) => ({
      answer: formValues[key],
      interviewQuestionId: key,
    }))
    try {
      if (date) {
        if (hasPreviousAnswers) {
          if (!isEqual(formValues, copyFormValues)) {
            await updateInterview({
              variables: {
                answers,
                date,
                workerInterviewId: workerInterview?.workerInterview?.id,
              },
            })
          }
        } else {
          await createInterview({
            variables: {
              answers,
              date,
              workerId: applicant.id,
            },
          })
          workerInterviewQuery.refetch()
        }
        hideModal()
      }
    } catch (error) {
      setError({ active: true, obj: error })
    }
  }, [formValues, copyFormValues, hasPreviousAnswers])

  useEffect(() => {
    if (workerInterview)
      if (workerInterview.workerInterview?.workerInterviewAnswers.length > 0)
        setHasPreviousAnswers(true)
      else setHasPreviousAnswers(false)
  }, [workerInterview])

  useEffect(() => {
    if (hasPreviousAnswers) {
      workerInterview?.workerInterview?.workerInterviewAnswers.map((answer) => {
        setFormValues((prevState) => {
          return { ...prevState, [answer.interviewQuestion.id]: answer.answer }
        })
        setCopyFormValues((prevState) => {
          return { ...prevState, [answer.interviewQuestion.id]: answer.answer }
        })
      })
      setDate(workerInterview?.workerInterview?.date)
    } else
      interviewQuestions.map((question) => {
        setFormValues((prevState) => {
          return { ...prevState, [question.id]: '' }
        })
      })
  }, [interviewQuestions, workerInterview, hasPreviousAnswers])

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit()
      setTrigger(false)
    }
  }, [triggerSubmit])


  if (!workerInterviewQuery.data) return null

  return (
    <Form initialValues={formValues} onSubmit={async () => {}}>
      <FormElement label="Status">
        <Stack>
          <Badge
            label={
              hasPreviousAnswers
                ? interviewStatusLabels[
                    workerInterview!.workerInterview!.status
                  ]
                : interviewStatusLabels.NOT_PASSED
            }
            size="md"
            status="warning"
          />
        </Stack>
      </FormElement>
      <FormColumns>
        <FormElement label="Date">
          <Input type="date" size="lg" onChange={handleChange} />
        </FormElement>
        {hasPreviousAnswers
          ? workerInterview?.workerInterview?.workerInterviewAnswers?.map(
              (answer, index) => (
                <QuestionComponent
                  key={index}
                  question={answer.interviewQuestion}
                  values={formValues}
                  onChange={setFormValues}
                />
              )
            )
          : interviewQuestions.map((question, index) => (
              <QuestionComponent
                key={index}
                question={question}
                values={formValues}
                onChange={setFormValues}
              />
            ))}
      </FormColumns>
    </Form>
  )
}

export default InterviewForm
