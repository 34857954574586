import React from 'react'

import styled from '@/styles'

import Text from '@/elements/Text'

import Version from '@/components/Version'

const Wrapper = styled('footer', {
  alignItems: 'center',
  display: 'flex',
  gridArea: 'footer',
  justifyContent: 'space-between',
  marginTop: '64px',
  padding: '32px',

  backgroundColor: '$neutralA4',
})

Wrapper.displayName = 'stitches(AppFooter.Wrapper)'

const Footer = () => {
  return (
    <Wrapper>
      <Version size="sm" />
      <Text color="lighter" size="sm">
        &copy; {new Date().getFullYear()} Gravy Workforce Technologies
      </Text>
    </Wrapper>
  )
}

export default Footer
