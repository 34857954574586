import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'

type Props = {
  hideModal: () => void
  onConfirm: () => void
}

const RemoveJobFromOrderModal = ({ hideModal, onConfirm }: Props) => {
  return (
    <Modal size="xs" title="Remove job" onRequestClose={hideModal}>
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>Are you sure you want to remove the job from the order?</Body>
          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
            <Button
              a11yLabel="Cancel job"
              appearance="primary"
              label="Remove job"
              onClick={onConfirm}
            />
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default RemoveJobFromOrderModal
