import Button from '@/components/Button'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import { Body } from '@/components/Typography'
import Link from '@/elements/Link'
import { Maybe } from '@/types'
import { useLocation } from 'react-router'
import { CustomerProfileSteps } from '../../'
import { Billing as BillingType } from '../../../context'

type Props = {
  hideModal: () => void
  onConfirm: () => void
  currentStep: Maybe<CustomerProfileSteps>
  totalSteps: number
  customer: Maybe<BillingType['customer']>
}

const CustomerProfileStepsModal = ({
  hideModal,
  onConfirm,
  currentStep,
  totalSteps,
  customer,
}: Props) => {
  const { pathname: currentPathname } = useLocation()

  return (
    <Modal
      size="xs"
      title={`Step ${currentStep}/${totalSteps}`}
      onRequestClose={hideModal}
    >
      <Card.Section>
        <Stack vertical gap={24}>
          <Body>
            Please complete your customer profile to create a new order.
          </Body>

          <Stack justify="end">
            <Button
              a11yLabel="Close"
              appearance="outline"
              label="Close"
              onClick={hideModal}
            />
            <Link to={`${currentPathname}/../../customers/${customer!.id}`}>
              <Button
                a11yLabel="Go to customer detail"
                appearance="primary"
                label="Complete profile"
                onClick={onConfirm}
              />
            </Link>
          </Stack>
        </Stack>
      </Card.Section>
    </Modal>
  )
}

export default CustomerProfileStepsModal
