import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons'

import styled from '@/styles'

import { GetCustomerQuery } from '@/types/graphql'

import Card from '@/components/Card'
import Stack from '@/components/Stack'

import Icon from '@/elements/Icon'
import Text from '@/elements/Text'

import ProgressBar from './ProgressBar'

type Item = GetCustomerQuery['customer']

type Props = {
  customer: GetCustomerQuery['customer']
}

const Wrapper = styled('ol', {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '188px',
  listStyle: 'none',
  padding: 0,
})

Wrapper.displayName = 'stitches(ProgressCustomerSection.Wrapper)'

const Step = styled('li', {
  borderRadius: '$sm',
  boxShadow: '$1',
  display: 'flex',
  flexDirection: 'row',
  padding: '10px 0px 10px',
})

Step.displayName = 'stitches(ProgressCustomerSection.Step)'

const IconStep = styled(Icon, {
  marginRight: 10,

  variants: {
    completed: {
      true: {
        color: '$themeDefault',
      },
      false: {
        color: '$textLightest',
      },
    },
  },
})
const TextStep = styled(Text, {
  marginLeft: 20,
})

const notEmptyArr = function (
  arr: Array<any>,
  trueValue: any = 1,
  falseValue: any = 0
): any {
  return !!arr.length ? trueValue : falseValue
}

const ProgressCustomerSection = ({ customer }: Props) => {
  const rateQuotes = customer.addresses.flatMap((address) => address.rateQuotes)

  const progress =
    notEmptyArr(customer.admins.items) +
    notEmptyArr(customer.accounts) +
    notEmptyArr(customer.addresses) +
    notEmptyArr(rateQuotes)

  return (
    <Card.Section title="Profile">
      <Stack justify="start">
        <Text
          color={
            customer.admins.items.length > 0 &&
            customer.accounts.length > 0 &&
            customer.addresses.length > 0 &&
            rateQuotes.length > 0
              ? 'theme'
              : 'inherit'
          }
          size="md"
        >
          {`Complete your profile (${progress}/4)`}
        </Text>
      </Stack>
      <Stack
        justify="center"
        style={{ display: 'flex,', flexDirection: 'column' }}
      >
        <ProgressBar size="xs" step={progress} />
      </Stack>
      <Wrapper>
        <Step>
          <TextStep
            color={customer.admins.items.length > 0 ? 'theme' : 'lightest'}
          >
            <IconStep
              icon={customer.admins.items.length > 0 ? faCheckCircle : faCircle}
              completed={customer.admins.items.length > 0 ? 'true' : 'false'}
            />
            Create customer admin
          </TextStep>
        </Step>
        <Step>
          <TextStep color={customer.accounts.length > 0 ? 'theme' : 'lightest'}>
            <IconStep
              icon={customer.accounts.length > 0 ? faCheckCircle : faCircle}
              completed={customer.accounts.length > 0 ? 'true' : 'false'}
            />
            Create billing account
          </TextStep>
        </Step>
        <Step>
          <TextStep
            color={customer.addresses.length > 0 ? 'theme' : 'lightest'}
          >
            <IconStep
              icon={customer.addresses.length > 0 ? faCheckCircle : faCircle}
              completed={customer.addresses.length > 0 ? 'true' : 'false'}
            />
            Add address
          </TextStep>
        </Step>
        <Step>
          <TextStep color={rateQuotes.length > 0 ? 'theme' : 'lightest'}>
            <IconStep
              icon={rateQuotes.length > 0 ? faCheckCircle : faCircle}
              completed={rateQuotes.length > 0 ? 'true' : 'false'}
            />
            Add custom rate
          </TextStep>
        </Step>
      </Wrapper>
    </Card.Section>
  )
}

export default ProgressCustomerSection
