import { useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { faPen, faBan } from '@fortawesome/free-solid-svg-icons'
import { format } from 'date-fns'
import { useModal } from 'react-modal-hook'

import { sortBy } from '@/util/array'
import { pauseEvent } from '@/util/events'

import { ListCandidatesQuery, ListWorkersQuery, WorkerReference } from '@/types/graphql'
import { useBlockWorkerMutation } from '@/graphql'

import Link from '@/elements/Link'
import { TableRow, TableCell } from '@/elements/Table'

import Avatar from '@/components/Avatar'
import Badge from '@/components/Badge'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small } from '@/components/Typography'
import IconicButton from '@/components/IconicButton'
import TagList from '@/components/TagList'
import ConfirmationModal from '@/components/ConfirmationModal'

import EditCandidateModal from './EditCandidateModal'
import EditApplicantModal from './EditApplicantModal'
import { currentAgencyVar } from '@/util/apollo/cache'

export type CandidateItem = ListCandidatesQuery['agency']['candidates']['items'][0]
type WorkerItem = ListWorkersQuery['agency']['workers']['items'][0]

const TABS_VALUE = ['CANDIDATES', 'APPLIED', 'IN_PROGRESS', 'COMPLETED']

const headerFields = {
  CANDIDATES: [
    'City',
    'State',
    'Last Name',
    'First Name',
    'Email',
    'Status',
    'Created at',
    'Updated at',
    'Actions',
  ],
  APPLIED: [
    'City',
    'State',
    'Last Name',
    'First Name',
    'Email',
    'Status',
    'Created at',
    'Updated at',
    'Actions',
  ],
  IN_PROGRESS: [
    'Name',
    'Contact',
    'Address',
    'Background Status',
    'Onboarding Status',
  ],
  COMPLETED: ['Name', 'Contact', 'Address', 'Skills'],
}

export const getHeaderFields = (tab: number) => {
  return headerFields[TABS_VALUE[tab]]
}

export const RenderBody = ({ items, tab }) => {
  const [selectedUser, setSelectedUser] = useState<CandidateItem>()
  const experiencesUser: WorkerReference[] = selectedUser?.references

  const [showEditModal, hideEditModal] = useModal(
    () => (
      <EditCandidateModal candidate={selectedUser} experiences={experiencesUser} hideModal={hideEditModal} />
    ),
    [selectedUser]
  )

  const [showEditApplicantModal, hideApplicantModal] = useModal(
    () => (
      <EditApplicantModal
        applicant={selectedUser}
        hideModal={hideApplicantModal}
      />
    ),
    [selectedUser]
  )

  const currentAgency = useReactiveVar(currentAgencyVar)
  const [blockWorker, { loading: isLoading }] = useBlockWorkerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: { candidates() {} },
      })
    },
  })

  const [showConfirmationModal, hideConfirmationModal] = useModal(
    () => (
      <ConfirmationModal
        title={`Are you sure you want to block ${selectedUser?.user.firstName}`}
        bodyContentText="He wont be able to get any job in the future"
        acceptButtonLabel="Cancel"
        denyButtonLabel="Confirm"
        acceptAction={() => {}}
        denyAction={() => {
          blockWorkerAction(selectedUser)
        }}
        hideModal={hideConfirmationModal}
      />
    ),
    [selectedUser]
  )

  const blockWorkerAction = async (selectedUser) => {
    try {
      await blockWorker({
        variables: {
          workerId: selectedUser.id,
          active: false,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCandidateEdit = (item) => {
    setSelectedUser(item)
    if (tab === 0) {
      showEditModal()
    } else if (tab === 1) {
      showEditApplicantModal()
    }
  }

  const handleBlockWorker = (item: CandidateItem) => {
    setSelectedUser(item)
    showConfirmationModal()
  }

  switch (tab) {
    case 0:
    case 1:
      return (
        <tbody>
          {items.map((item: CandidateItem) => (
            <TableRow key={item.id}>
              <TableCell>
                {item.city ? `${item.city}` : 'Not provided'}
              </TableCell>
              <TableCell>
                {item.state ? `${item.state}` : 'Not provided'}
              </TableCell>
              <TableCell>{item.user.lastName}</TableCell>
              <TableCell>{item.user.firstName}</TableCell>
              <TableCell>
                <Body>{item.user.email}</Body>
              </TableCell>
              <TableCell>
                <Badge label={item.candidateStatus} />
              </TableCell>
              <TableCell>{format(new Date(item.createdAt), 'PP')}</TableCell>
              <TableCell>{format(new Date(item.updatedAt), 'PP')}</TableCell>
              <TableCell>
                <Stack>
                  <IconicButton
                    a11yLabel="edit"
                    appearance="clear"
                    icon={faPen}
                    size="sm"
                    onClick={pauseEvent(() => handleCandidateEdit(item))}
                  />
                  <IconicButton
                    a11yLabel="delete"
                    appearance="clear"
                    icon={faBan}
                    size="sm"
                    onClick={() => {
                      handleBlockWorker(item)
                    }}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      )

    case 2:
      return (
        <tbody>
          {items.map((item: CandidateItem) => (
            <TableRow key={item.id}>
              <TableCell>
                <Stack gap={20}>
                  <TextStack spacing="tight">
                    <Link to={`${item.id}`}>
                      <Body color="inherit" weight="medium">
                        {item.user.firstName} {item.user.lastName}
                      </Body>
                    </Link>
                  </TextStack>
                </Stack>
              </TableCell>
              <TableCell>
                <TextStack spacing="tight">
                  <Body>{item.user.phoneNumber}</Body>
                  <Small>{item.user.email}</Small>
                </TextStack>
              </TableCell>
              <TableCell>{`${item.city}, ${item.state}`}</TableCell>
              <TableCell>
                <Badge
                  label={
                    item.backgroundStatus ? item.backgroundStatus : 'Pending'
                  }
                />
              </TableCell>
              <TableCell>
                <Badge
                  label={
                    item.onboardingStatus ? item.onboardingStatus : 'Pending'
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      )
    case 3:
      return (
        <tbody>
          {items.map((item: WorkerItem) => (
            <TableRow key={item.id}>
              <TableCell>
                <Stack gap={20}>
                  <div>
                    <Avatar
                      firstName={item.user.firstName}
                      size="sm"
                      src={item.avatarUrl}
                    />
                  </div>
                  <TextStack spacing="tight">
                    <Link to={`${item.id}`}>
                      <Body color="inherit" weight="medium">
                        {item.user.firstName} {item.user.lastName}
                      </Body>
                    </Link>
                    <Small>rating</Small>
                  </TextStack>
                </Stack>
              </TableCell>
              <TableCell>
                <TextStack spacing="tight">
                  <Body>{item.user.phoneNumber}</Body>
                  <Small>{item.user.email}</Small>
                </TextStack>
              </TableCell>
              <TableCell>
                <TextStack spacing="tight">
                  <Body>{item.addressLine1}</Body>
                  <Small>{`${item.city}, ${item.state}`}</Small>
                </TextStack>
              </TableCell>
              <TableCell width="sm">
                <TagList
                  tags={sortBy(item.skills, 'name').map((skill) => skill.name)}
                />
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      )
    default:
      return <></>
  }
}
