import SingleOptionGroupField from '@/form/SingleOptionGroupField'
import {
  BackgroundStatusEnum,
  GetWorkerQuery,
  OnboardingStatusEnum,
} from '@/types/graphql'
import { useUpdateCheckStatusMutation } from '@/graphql'
import { handleMutationFormError } from '@/util/error'
import { SubmitHelpers } from '@area2k/use-form'
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import Stack from '@/components/Stack'
import Button from '@/components/Button'
import Form from '@/form'
import FormColumns from '@/form/FormColumns'
import { useCallback } from 'react'

const backgroundStatusOptions = [
  { label: 'In Progress', value: BackgroundStatusEnum.IN_PROGRESS },
  { label: 'Needs Review', value: BackgroundStatusEnum.NEEDS_REVIEW },
  { label: 'Approved', value: BackgroundStatusEnum.APPROVED },
  { label: 'Declined', value: BackgroundStatusEnum.DECLINED },
  { label: 'N/A', value: BackgroundStatusEnum.NA },
]

const onboardingStatusOptions = [
  {
    label: 'Interview Requested',
    value: OnboardingStatusEnum.INTERVIEW_REQUESTED,
  },
  {
    label: 'Interview Scheduled',
    value: OnboardingStatusEnum.INTERVIEW_SCHEDULED,
  },
  {
    label: 'Interview Declined',
    value: OnboardingStatusEnum.INTERVIEW_DECLINED,
  },
  { label: 'Interview Passed', value: OnboardingStatusEnum.INTERVIEW_PASSED },
  {
    label: 'Onboarding in Progress',
    value: OnboardingStatusEnum.ONBOARDING_IN_PROGRESS,
  },
  {
    label: 'Onboarding Complete',
    value: OnboardingStatusEnum.ONBOARDING_COMPLETED,
  },
]

type Props = {
  hideModal: () => void
  worker: GetWorkerQuery['worker']
}

type FormValues = {
  backgroundStatus: BackgroundStatusEnum
  onboardingStatus: OnboardingStatusEnum
}

const ChangeStatusModal = ({ hideModal, worker }: Props) => {
  const [UpdateCheck] = useUpdateCheckStatusMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(worker),
        fields: {
          status() {},
        },
      })
    },
  })
  const initialValues: FormValues = {
    backgroundStatus: worker.backgroundStatus
      ? worker.backgroundStatus.toUpperCase()
      : BackgroundStatusEnum.IN_PROGRESS,
    onboardingStatus: worker.onboardingStatus
      ? worker.onboardingStatus.toUpperCase()
      : OnboardingStatusEnum.INTERVIEW_REQUESTED,
  }

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        await UpdateCheck({
          variables: {
            ...values,
            workerId: worker.id,
          },
        })
        hideModal()
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        })
      }
    },
    [worker]
  )

  return (
    <Modal title="Change Statuses" onRequestClose={hideModal} disableClickout>
      <Card.Section>
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <FormColumns>
            <SingleOptionGroupField
              options={backgroundStatusOptions}
              fieldId="backgroundStatus"
              label="Background Status"
            />
            <SingleOptionGroupField
              options={onboardingStatusOptions}
              fieldId="onboardingStatus"
              label="Onboarding Status"
            />
          </FormColumns>
          <Stack justify="end">
            <Button
              a11yLabel="Submit"
              isLoading={undefined}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  )
}

export default ChangeStatusModal
