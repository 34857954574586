import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '@/styles'

export const Icon = styled(FontAwesomeIcon, {
  color: 'inherit',

  fontSize: 'inherit',
  lineHeight: 'inherit',

  variants: {
    size: {
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      lg: {
        fontSize: '$lg',
      },
      '1x': {
        fontSize: '$xl',
      },
      '2x': {
        fontSize: '$xxl',
      },
      '3x': {
        fontSize: '$xxxl',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
})

Icon.displayName = 'stitches(Icon)'

export default Icon
