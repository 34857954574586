import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'

type Props = {
  values: any
  setValues: (args) => void
}

const findContent = (attribute, details, short = false) => {
  let data = details.address_components.find((item) =>
    item.types.includes(attribute)
  )
  return data ? (short ? data.short_name : data.long_name) : 'Not provided'
}

const validateTwoChars = (word) => {
  return word.length !== 2 ? false : word
}

const AddressAutocomplete = ({ values, setValues }: Props) => {
  const getCity = (value, res) => {
    let withAddressComponents = findContent(
      'administrative_area_level_2',
      res[0]
    )
    let withTerms = value.value.terms[1].value

    return withAddressComponents || withTerms || 'Not provided'
  }

  const getState = (value, res) => {
    let withAddressComponents = findContent(
      'administrative_area_level_1',
      res[0],
      true
    )
    let withTerms = value.value.terms[2].value

    return (
      validateTwoChars(withAddressComponents) ||
      validateTwoChars(withTerms) ||
      'NA'
    )
  }

  const getAddress = (value, res) => {
    let withValues = value.value.terms[0].value
    let streetNumber = findContent('street_number', res[0])
    let withComponents = `${
      streetNumber === 'Not provided' ? '' : streetNumber
    } ${findContent('route', res[0])}`

    return withComponents || withValues || 'Not provided'
  }

  const handleNewAddressValue = (value) => {
    let coords = { latitude: 0, longitude: 0 }
    geocodeByPlaceId(value.value.place_id).then((res) => {
      coords.latitude = res[0].geometry.location.lat()
      coords.longitude = res[0].geometry.location.lng()

      let addressLine1 = getAddress(value, res)
      let city = getCity(value, res)
      let state = getState(value, res)
      let zip = findContent('postal_code', res[0])

      setValues({
        ...values,
        coords,
        city,
        state,
        zip,
        addressLine1,
      })
    })
  }

  return (
    <div style={{ zIndex: '1000' }}>
      <PlacesAutocomplete
        autocompletionRequest={{
          componentRestrictions: { country: 'us' },
          types: ['address'],
        }}
        minLengthAutocomplete={2}
        selectProps={{
          placeholder: 'Type address...',
          onChange: (newValue) => {
            handleNewAddressValue(newValue)
          },
        }}
      />
    </div>
  )
}

export default AddressAutocomplete
