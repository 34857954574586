import { useCallback } from 'react'

import { GetJobQuery } from '@/types/graphql'

import Avatar from '@/components/Avatar'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import ResourceList from '@/components/ResourceList'
import Stack from '@/components/Stack'
import TextStack from '@/components/TextStack'
import { Body, Small, Subheading } from '@/components/Typography'

import { JobStatus } from '../util'
import Badge from '@/components/Badge'
import { Wrapper } from '@/components/Slider/elements'

type JobOfferItem = GetJobQuery['job']['jobOffers'][0]

type Props = {
  job: GetJobQuery['job']
  jobStatus: JobStatus
}

const JobOffersList = ({ job, jobStatus }: Props) => {
  const jobOffers = job.jobOffers ? job.jobOffers : []
  const isJobActive =
    jobStatus === JobStatus.UPCOMING || jobStatus === JobStatus.IN_PROGRESS

  const workersInvite = jobOffers.filter((item) => item.acceptedAt == null)

  const renderItem = useCallback(
    ({ acceptedAt, id, rejectedAt, worker }: JobOfferItem) => (
      <ResourceList.Item
        key={id}
        media={
          <Avatar
            firstName={worker.user.firstName}
            size="sm"
            src={worker.avatarUrl}
          />
        }
        to={`../../../../workers/${worker.id}`}
      >
        <Stack>
          <TextStack spacing="tight">
            <Body weight="medium">
              {worker.user.firstName} {worker.user.lastName}
            </Body>
            <Small>{worker.user.email}</Small>
          </TextStack>
          {rejectedAt ? (
            <Badge label="Rejected" status="danger" />
          ) : (
            <Badge label="Pending" />
          )}
        </Stack>
      </ResourceList.Item>
    ),
    [isJobActive]
  )

  return (
    <Wrapper>
      <Card.Header>
        <Subheading size="md">Direct invites</Subheading>
      </Card.Header>

      <ResourceList
        emptyState={
          <EmptyState
            title="No job offers"
            text="If there were any, they would be here"
          />
        }
        items={workersInvite}
        renderItem={renderItem}
      />
    </Wrapper>
  )
}
export default JobOffersList
