import { useState, useCallback, useEffect } from 'react'
import useModal from '@area2k/use-modal'
import { useReactiveVar } from '@apollo/client'
import {
  useCreateGroupJobOfferMutation,
  useCreateOrderMutation,
} from '@/graphql'

import { currentAgencyVar } from '@/util/apollo/cache'

import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Page from '@/components/Page'
import PrimarySecondaryLayout from '@/components/PrimarySecondaryLayout'
import Stack from '@/components/Stack'
import { Body, Small, Subheading } from '@/components/Typography'

import WorkerAvatarGroup from './WorkerAvatarGroup'

import PostSubmitModal from './modals/PostSubmitModal'
import RemoveJobFromOrderModal from './modals/RemoveJobModal'

import { JobDraftState } from '../JobEditor/context'
import ScheduleSummary from '../JobEditor/Steps/ScheduleStep/ScheduleSummary'

import debug from '@/util/debug'
import { centsToCurrency, zeroPad } from '@/util/number'

import {
  costOfSchedule,
  jobStateToJobInput,
  scheduleDates,
  useOrderActions,
  useOrderState,
} from '../context'

const calculateTotalShiftsByJobs = (jobs: JobDraftState[]) =>
  jobs.reduce(
    (acc, jobElement) =>
      acc +
      jobElement.schedules.reduce(
        (sacc, sel) => sacc + scheduleDates(sel).length,
        0
      ),
    0
  )

const calculateTotalEstimateByJobs = (jobs: JobDraftState[]) =>
  jobs.reduce(
    (acc, jobElement) =>
      acc +
      jobElement.schedules.reduce((sacc, sel) => sacc + costOfSchedule(sel), 0),
    0
  )

type Props = {
  onOpenEditor: () => void
}

const Review = ({ onOpenEditor }: Props) => {
  const currentAgency = useReactiveVar(currentAgencyVar)

  const [indexFromSelectedJob, setIndexFromSelectedJob] = useState<
    number | null
  >(null)

  const { billing, jobs } = useOrderState()
  const { clearJobs, removeJob, setBilling } = useOrderActions()

  const [createOrder, { data, loading }] = useCreateOrderMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(currentAgency!),
        fields: {
          orders() {},
        },
      })
    },
  })
  const [createGroupJobOffer] = useCreateGroupJobOfferMutation()

  const [showPostModal, hidePostModal] = useModal(
    () => (
      <PostSubmitModal
        order={data!.orderCreate.order}
        hideModal={() => {
          hidePostModal()
          clearJobs()
          setBilling(null)
        }}
      />
    ),
    [data]
  )

  const [removeJobFromOrderModal, hideRemoveJobFormOrderModal] = useModal(
    () => (
      <RemoveJobFromOrderModal
        onConfirm={() => {
          removeJob(indexFromSelectedJob!)
          setIndexFromSelectedJob(null)
          hideRemoveJobFormOrderModal()
        }}
        hideModal={hideRemoveJobFormOrderModal}
      />
    ),
    []
  )

  const handleSubmit = useCallback(async () => {
    try {
      const result = await createOrder({
        variables: {
          accountId: billing!.account.id,
          jobs: jobs.flatMap((job) => jobStateToJobInput(billing!, job)),
        },
      })

      debug.log('[CreateOrder]', result)
    } catch (error) {
      console.log(error)
    }
  }, [billing, jobs])

  const totalShifts = calculateTotalShiftsByJobs(jobs)
  const totalEstimate = calculateTotalEstimateByJobs(jobs)

  useEffect(() => {
    if (data) {
      const { jobs: createdJobs } = data.orderCreate.order
      const onlySelectedWorkersByJobs = jobs.map((job) => job.selectedWorkers)
      const onlyWorkerIds = onlySelectedWorkersByJobs.map((selectedWorker) =>
        selectedWorker.map((worker) => worker.id)
      )

      onlyWorkerIds.map(async (workerIds, index) => {
        if (workerIds.length !== 0) {
          await createGroupJobOffer({
            variables: { jobId: createdJobs[index].id, workerIds },
          })
        }
      })

      showPostModal()
    }
  }, [data, showPostModal])

  return (
    <Page
      primaryAction={{
        a11yLabel: 'Submit order',
        disabled: jobs.length === 0,
        isLoading: loading,
        label: 'Submit order',
        onAction: handleSubmit,
      }}
      secondaryActions={[
        {
          a11yLabel: 'Add job to order',
          label: 'Add job',
          onAction: onOpenEditor,
        },
      ]}
      size="md"
      subtitle={`Billed to ${billing!.account.name}`}
      title={`Order for ${billing!.customer.name}`}
    >
      <PrimarySecondaryLayout>
        <PrimarySecondaryLayout.Primary>
          <Stack vertical gap={24}>
            {jobs.map((job, jobIndex) => (
              <Card
                key={jobIndex}
                actions={[
                  {
                    a11yLabel: 'Remove job from order',
                    dangerous: true,
                    label: 'Remove',
                    onAction: () => {
                      setIndexFromSelectedJob(jobIndex)
                      removeJobFromOrderModal()
                    },
                  },
                ]}
                title={job.skill!.name}
              >
                {job.schedules.map((schedule, scheduleIndex) => (
                  <ScheduleSummary key={scheduleIndex} schedule={schedule} />
                ))}

                {job.selectedWorkers.length !== 0 && (
                  <Card.Footer>
                    <Stack justify="apart">
                      <Small>
                        {job.selectedWorkers.length === 1
                          ? 'Worker'
                          : 'Workers'}
                      </Small>
                      <Body>
                        <WorkerAvatarGroup items={job.selectedWorkers} />
                      </Body>
                    </Stack>
                  </Card.Footer>
                )}
              </Card>
            ))}
          </Stack>
        </PrimarySecondaryLayout.Primary>

        <PrimarySecondaryLayout.Secondary>
          <Card title="Summary">
            {jobs.length > 0 ? (
              <>
                <Card.Section>
                  <Stack vertical gap={12}>
                    <Stack justify="apart">
                      <Small>Total jobs</Small>
                      <Body>{zeroPad(jobs.length, 2)}</Body>
                    </Stack>
                    <Stack justify="apart">
                      <Small>Total schedule shifts</Small>
                      <Body>{zeroPad(totalShifts, 2)}</Body>
                    </Stack>
                  </Stack>
                </Card.Section>
                <Card.Section>
                  <Stack justify="apart">
                    <Body>Total estimate</Body>
                    <Subheading color="theme">
                      ${centsToCurrency(totalEstimate)}
                    </Subheading>
                  </Stack>
                </Card.Section>
              </>
            ) : (
              <EmptyState
                title="No jobs in order"
                text='Click "Add job" above to get started.'
              />
            )}
          </Card>
        </PrimarySecondaryLayout.Secondary>
      </PrimarySecondaryLayout>
    </Page>
  )
}

export default Review
