import styled from '@/styles'

import Shimmer from '@/elements/Shimmer'

const Display = styled(Shimmer, {
  variants: {
    size: {
      sm: {
        maxWidth: 128
      },
      md: {
        maxWidth: 256
      },
      lg: {
        maxWidth: 512
      },
      full: {
        maxWidth: 'initial'
      }
    },
    type: {
      small: {
        height: '$fontSizes$sm',
        margin: '3px 0'
      },
      body: {
        height: '$fontSizes$md',
        margin: '3.5px 0'
      },
      subheading: {
        height: '$fontSizes$lg',
        margin: '4px 0'
      },
      heading: {
        height: '$fontSizes$xl',
        margin: '5px 0'
      },
      title: {
        height: '$fontSizes$xxl',
        margin: '6px 0'
      },
      display: {
        height: '$fontSizes$xxxl',
        margin: '8px 0'
      }
    }
  },

  defaultVariants: {
    size: 'md',
    type: 'subheading'
  }
})

Display.displayName = 'stitches(Skeleton.Display)'

export default Display
