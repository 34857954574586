import styled from '@/styles'

import Popover from '@/elements/Popover'

const Menu = styled(Popover, {
  listStyle: 'none',
  padding: '4px 0',

  backgroundColor: 'white',
  borderRadius: '$lg',
  boxShadow: '$3',
  outline: 'none'
})

Menu.displayName = 'stitches(Autocomplete.Menu)'

export default Menu
